import React, { useEffect, useState } from "react";
import { ReactComponent as DownloadIcon } from "../../../assets/img/icons/menuIcons/download-icon.svg";
import { Chip } from "@material-ui/core";

const EXTENSION_ID = "epokpfbnfodeidpliggaehgnafpnjddo";

const InstallChromeExtensionLink = () => {
  const [isInstalled, setIsInstalled] = useState(false);

  useEffect(() => {
    try {
      (window.chrome ?? window.browser).runtime
        .sendMessage(EXTENSION_ID, {
          type: "isInstalled",
        })
        .then((response) => {
          // response is an object of { installed: boolean, version: string }
          // when installed is true, it will also return the version of the extension
          // it's not necessary but maybe we can use it in the future.
          // if installed is false, it means the extension is not installed handle it accordingly.
          if (response?.installed) setIsInstalled(true);
        });
    } catch (error) {
      setIsInstalled(false);
      // extension is not installed handle it accordingly
    }
  }, []);

  if (isInstalled) return null;

  return (
    <a
      href="https://pastorsline.com/chrome-extension/"
      className="admin-user-menus-wrapper"
      target="__blank"
    >
      <DownloadIcon width={19} height={19} className="mr-1 mb-1" />
      Install Chrome Extension{" "}
      <Chip
        label="NEW"
        color="succes"
        size="small"
        classes={{
          root: "sidebar-menu-new-chip",
          label: "sidebar-menu-new-chip-label",
        }}
      />
    </a>
  );
};

export default InstallChromeExtensionLink;
