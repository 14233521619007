import React, { useEffect, useState, useCallback } from "react";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { ReactComponent as VisibilityOff } from "../../assets/img/icons-new/visibility-off/visibility-off-grey.svg";
import { ReactComponent as Visibility } from "../../assets/img/icons-new/visibility/visibility-grey.svg";
import { ReactComponent as ShowInfo } from "../../assets/img/icons-new/number-status/show-info/show-info-orange.svg";
import useUsersStore from "../../utils/hooks/ReduxHooks/userStore";
import useMainStore from "../../utils/hooks/ReduxHooks/mainStore";
import useBreakpoint from "../../utils/hooks/useBreakpoints";
import { isMdOrBelowBreakpoint } from "../../utils/breakpoints";
import OnboardingLayout from "./OnboardingLayout";
import pastorslineLogo from "../../assets/img/pastorsline-logo.png";
import ButtonWithSpinner from "../../components/hub/HelperComponents/ButtonWithSpinner";
import { DEV_LOGIN_SERVICE_TOKEN } from "../../utils/constants";
import AxiosConfig from "../../AxiosConfig";
import { getPrimaryUrl, isEmptyObject } from "../../helpers";

const Login = () => {
  // Breakpoint
  const breakpoint = useBreakpoint();

  // Router
  const history = useHistory();

  // Redux store
  const { users, v3LoginMutation, clearV3LoginUserStatusAndError } =
    useUsersStore();
  const { startLegacySession } = useUsersStore();
  const { addInfoSnackBar } = useMainStore();
  const {
    v3LoginStatus,
    v3RedirectsTo,
    v3LoginData,
    v3LoginError,
    v3LoginErrorMessage,
  } = users;
  // Local state
  const [formFields, setFormFields] = useState({
    email: "",
    password: "",
  });
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorTextEmail, setErrorTextEmail] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [simpleError, setSimpleError] = useState("");
  const [loadLegacySession, setLoadLegacySession] = useState(false);

  useEffect(() => {
    if (AxiosConfig.getAuthToken()) {
      history.push("/dashboard");
    }
  }, [history]);

  const toggleSnackBar = useCallback(
    (msg, error = false) => {
      const info = { msg };
      error && (info.err = true);
      addInfoSnackBar(info);
    },
    [addInfoSnackBar]
  );

  useEffect(() => {
    if (!AxiosConfig.getAuthToken()) {
      if (v3LoginStatus === "success") {
        if (v3RedirectsTo) {
          if (v3LoginData?.access_token) {
            startLegacySession(true);
            setLoadLegacySession(true);
          } else {
            window.location.href = v3RedirectsTo;
          }
        } else if (
          v3LoginData &&
          !isEmptyObject(v3LoginData) &&
          v3LoginData?.access_token &&
          v3LoginData?.refresh_token
        ) {
          toggleSnackBar("Log-in successful.");
          window.location.href = `/dashboard?accessToken=${v3LoginData?.access_token}&refreshToken=${v3LoginData?.refresh_token}`;
          startLegacySession(true);
        }
      } else if (
        v3LoginStatus === "error" &&
        (v3LoginError || v3LoginErrorMessage)
      ) {
        if (v3LoginError) {
          setErrorEmail(true);
          setErrorTextEmail(<div>{v3LoginError}</div>);
        } else if (v3LoginErrorMessage) {
          setSimpleError(v3LoginErrorMessage);
        }
        clearV3LoginUserStatusAndError();
      }
    }
  }, [
    clearV3LoginUserStatusAndError,
    startLegacySession,
    toggleSnackBar,
    v3LoginData,
    v3LoginError,
    v3LoginStatus,
    v3RedirectsTo,
    v3LoginErrorMessage,
  ]);

  const setField = useCallback(
    (field) => (event) => {
      let value = event ? event?.target?.value : null;
      setFormFields((prev) => ({ ...prev, [field]: value }));
      if (errorEmail) {
        setErrorEmail(false);
        setErrorTextEmail();
      }
    },
    [errorEmail]
  );

  const onEmailChange = useCallback(
    ({ target: { value } }) => {
      setFormFields((prev) => ({ ...prev, email: value }));
      if (errorEmail) {
        setErrorEmail(false);
        setErrorTextEmail();
      }
    },
    [errorEmail]
  );

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onLoadLegacySession = useCallback(() => {
    setLoadLegacySession(false);
    window.location.href = v3RedirectsTo;
    clearV3LoginUserStatusAndError();
  }, [clearV3LoginUserStatusAndError, v3RedirectsTo]);

  const onSubmit = useCallback(() => {
    const service_token =
      process.env.NODE_ENV === "development" && DEV_LOGIN_SERVICE_TOKEN;
    setSimpleError("");
    v3LoginMutation({
      username: formFields.email,
      password: formFields.password,
      ...(service_token && { service_token }),
    });
  }, [formFields, v3LoginMutation]);

  if (AxiosConfig.getAuthToken()) {
    return null;
  }

  return (
    <>
      <OnboardingLayout>
        <div>
          <div className="d-flex justify-content-center logo">
            <img src={pastorslineLogo} alt="pastorsline-logo" />
          </div>
          <form onSubmit={onSubmit} className="form-container">
            <h1 className="header-text">Login</h1>
            {simpleError && (
              <label className="text-danger">{simpleError}</label>
            )}
            <TextField
              label="Username Or Email"
              fullWidth
              className={clsx({
                "mb-3": isMdOrBelowBreakpoint(breakpoint),
                "mb-4": !isMdOrBelowBreakpoint(breakpoint),
              })}
              key="email"
              error={errorEmail}
              helperText={errorTextEmail}
              required
              value={formFields.email}
              onChange={onEmailChange}
              InputProps={{
                endAdornment: errorEmail && <ShowInfo />,
              }}
            />

            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              fullWidth
              className={clsx({
                "mb-3": isMdOrBelowBreakpoint(breakpoint),
                "mb-4": !isMdOrBelowBreakpoint(breakpoint),
              })}
              key="password"
              required
              value={formFields.password}
              onChange={setField("password")}
              error={errorEmail}
              helperText={errorTextEmail}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff width={25} height={25} />
                      ) : (
                        <Visibility width={25} height={25} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <p className="forgot-password-text">
              Forgot your
              <button
                className="font-weight-bold onboarding-links password-text"
                onClick={() => history.push("forgot-password")}
                type="button"
              >
                Password?
              </button>
            </p>
            <ButtonWithSpinner
              className="btn-block mb-3 form-container-button"
              disabled={
                !formFields.password ||
                !formFields.email ||
                errorEmail ||
                users.v3LoginStatus === "loading" ||
                loadLegacySession
              }
              onClick={onSubmit}
              loading={users.v3LoginStatus === "loading" || loadLegacySession}
              type={"submit"}
            >
              Login
            </ButtonWithSpinner>
            <p className="already-text">
              Not registered?
              <button
                className="font-weight-bold onboarding-links"
                onClick={() => history.push("register")}
                type="button"
              >
                Sign up now!
              </button>
            </p>
          </form>
        </div>
      </OnboardingLayout>
      {v3RedirectsTo && loadLegacySession && v3LoginData?.access_token && (
        <iframe
          src={getPrimaryUrl(
            `users/startSession?token=${v3LoginData?.access_token}}`
          )}
          title="start session"
          width={0}
          height={0}
          onLoad={onLoadLegacySession}
        ></iframe>
      )}
    </>
  );
};

export default Login;
