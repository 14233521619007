import axios from "axios";
import AxiosConfig from "../AxiosConfig";
import { ThreadItemsTypeEnums } from "../utils/enums/threadEnums";
import { refreshCounts } from "../helpers";
import { postRequest } from "../utils/requests";
import { logAPIRequest } from "../utils/loggerHelpers";

const CancelToken = axios.CancelToken;

// We stores requestIds to not overwrite response from earlier request, from previous selected company
let fetchThreadsRequestIds = [];

export function resetFetchThreadsRequests() {
  fetchThreadsRequestIds = [];
}

function removeFetchThreadRequestId(requestId) {
  const index = fetchThreadsRequestIds.indexOf(requestId);
  if (index >= 0) {
    fetchThreadsRequestIds.splice(index, 1);
  }
}

export function fetchThreads(companyId, numbersFilter, page, type) {
  return function (dispatch) {
    const requestId = `${new Date().getTime()}-${String(Math.random()).substr(
      2,
      3
    )}`;
    fetchThreadsRequestIds.push(requestId);
    dispatch({ type: "FETCH_THREADS", page });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId,
        page,
        numbersFilter,
        type,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + "threads.json", config)
      .then((response) => {
        if (fetchThreadsRequestIds.indexOf(requestId) >= 0) {
          removeFetchThreadRequestId(requestId);
          dispatch({
            type: "FETCH_THREADS_SUCCESS",
            payload: response.data,
            companyId: companyId,
            page: page,
          });
        }
      })
      .catch(function (error) {
        if (fetchThreadsRequestIds.indexOf(requestId) >= 0) {
          removeFetchThreadRequestId(requestId);
          dispatch({
            type: "FETCH_THREADS_ERROR",
            payload: error,
          });
        }
      });
  };
}

export function clearSelection(selection) {
  return function (dispatch) {
    dispatch({ type: "THREADS/CLEAR_SELECTION", selection });
  };
}

export function toggleSideForm(selection, memberId) {
  return function (dispatch) {
    dispatch({ type: "THREADS/TOGGLE_CONTACTFORM", selection, memberId });
  };
}

export function toggleEditGroupForm(selection, groupId) {
  return function (dispatch) {
    dispatch({ type: "THREADS/TOGGLE_EDITGROUP", selection, groupId });
  };
}

export function addSideFormSubContact(memberId) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/ADD_SIDEFORM_SUBCONTACT",
      memberId,
    });
  };
}

export function selectingItems(number) {
  return function (dispatch) {
    dispatch({ type: "THREADS/SELECTING_ITEMS", number });
  };
}

export function setThreadsAsOutdated() {
  return function (dispatch) {
    dispatch({ type: "THREADS/FETCH.OUTDATED" });
  };
}

export function fetchFilteredThreads(
  companyId,
  query,
  phoneCountryId,
  phoneCountryCode,
  numbersFilter,
  page,
  filter,
  type,
  searchIn
) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/FILTERED",
      companyId,
      phoneCountryId,
      phoneCountryCode,
      query,
      page,
      filter,
      searchIn,
    });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId,
        phoneCountryId,
        phoneCountryCode,
        query,
        numbersFilter,
        page,
        filter,
        type,
        searchIn,
      },
    });

    const paramsForArchivedInterlocutors = {
      onlyArchivedInterlocutors: 1,
      interlocutorType: "both",
    };
    const configForArchivedInterlocutors = Object.assign({}, config, {
      params: {
        ...config.params,
        ...paramsForArchivedInterlocutors,
      },
    });

    return axios
      .get(AxiosConfig.getEndpointAddress() + "threads.json", config)
      .then((response) => {
        if (response.data.has_threads_for_archived_interlocutors) {
          fetchArchivedInterlocutors(
            configForArchivedInterlocutors,
            type,
            dispatch,
            response,
            companyId,
            query,
            page,
            filter
          );
        } else {
          dispatch({
            type: "THREADS/FILTERED.SUCCESS",
            payload: response.data,
            companyId,
            query,
            page,
            filter,
            messageType: type,
          });
        }
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/FILTERED.ERROR",
          payload: error,
        });
      });
  };
}

export function fetchArchivedInterlocutors(
  config,
  type,
  dispatch,
  response,
  companyId,
  query,
  page,
  filter
) {
  axios
    .get(AxiosConfig.getEndpointAddress() + "threads.json", config)
    .then((archivedInterlocutors) => {
      dispatch({
        type: "THREADS/FETCH_ARCHIVED_INTERLOCUTORS",
        payload: archivedInterlocutors.data,
        messageType: type,
      });
      dispatch({
        type: "THREADS/FILTERED.SUCCESS",
        payload: response.data,
        companyId,
        query,
        page,
        filter,
        messageType: type,
      });
    })
    .catch(function (error) {
      dispatch({
        type: "THREADS/FETCH_ARCHIVED_INTERLOCUTORS.ERROR",
        payload: error,
      });
    });
}

export function fetchClosedThreads(
  companyId,
  numbersFilter,
  page,
  filter,
  type
) {
  return function (dispatch) {
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId,
        numbersFilter,
        page,
        filter,
        type,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + "threads.json", config)
      .then((response) => {
        dispatch({
          type: "THREADS/FETCH_CLOSED_THREADS.SUCCESS",
          payload: response.data,
          companyId,
          page,
          filter,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/FETCH_CLOSED_THREADS.ERROR",
          payload: error,
        });
      });
  };
}
export function fetchSpecialThreads(
  companyId,
  numbersFilter,
  filter,
  page,
  contactId = [],
  groupId = [],
  type
) {
  return function (dispatch) {
    dispatch({ type: "THREADS/SPECIAL", page });
    const params = {
      companyId: companyId,
      page: page,
      numbersFilter: numbersFilter,
      filter: filter,
      type,
    };

    if (filter === "drafts") {
      params.contactId = contactId;
      params.groupId = groupId;
    }

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params,
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + "threads.json", config)
      .then((response) => {
        dispatch({
          type: "THREADS/SPECIAL.SUCCESS",
          payload: response.data,
          companyId: companyId,
          page: page,
          filter,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/SPECIAL.ERROR",
          payload: error,
        });
      });
  };
}

export function fetchIncomingThread(companyId, numbersFilter, logId) {
  return function (dispatch) {
    dispatch({ type: "FETCH_INCOMING_THREAD" });

    const url = "threads/single.json";
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        numbersFilter: numbersFilter,
        logId: logId,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "FETCH_INCOMING_THREAD_SUCCESS",
          payload: response.data,
          companyId: companyId,
          logId: logId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "FETCH_INCOMING_THREAD_ERROR",
          payload: error,
        });
      });
  };
}

export function fetchThread(companyId, numbersFilter, contactId, groupId) {
  return function (dispatch) {
    dispatch({ type: "THREADS/SINGLE" });

    const url = "threads/single.json";
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        numbersFilter: numbersFilter,
        contactId: contactId,
        groupId: groupId,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "THREADS/SINGLE.SUCCESS",
          payload: response.data,
          companyId: companyId,
          numbersFilter: numbersFilter,
          contactId: contactId,
          groupId: groupId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/SINGLE.ERROR",
          payload: error,
          companyId: companyId,
          numbersFilter: numbersFilter,
          contactId: contactId,
          groupId: groupId,
        });
      });
  };
}

export function fetchTempThread(companyId, numbersFilter, contactId, groupId) {
  return function (dispatch) {
    dispatch({ type: "THREADS/TEMP" });

    const url = "threads/single.json";
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        numbersFilter: numbersFilter,
        contactId: contactId,
        groupId: groupId,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "THREADS/TEMP.SUCCESS",
          payload: response.data,
          companyId: companyId,
          numbersFilter: numbersFilter,
          contactId: contactId,
          groupId: groupId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/TEMP.ERROR",
          payload: error,
          companyId: companyId,
          numbersFilter: numbersFilter,
          contactId: contactId,
          groupId: groupId,
        });
      });
  };
}

export function fetchVoiceThreads(companyId, numbersFilter, page) {
  return function (dispatch) {
    const requestId = `${new Date().getTime()}-${String(Math.random()).substr(
      2,
      3
    )}`;
    fetchThreadsRequestIds.push(requestId);
    dispatch({ type: "THREADS/VOICE_THREADS", page: page });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        page: page,
        numbersFilter: numbersFilter,
        type: "all_voice",
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + "threads.json", config)
      .then((response) => {
        if (fetchThreadsRequestIds.indexOf(requestId) >= 0) {
          removeFetchThreadRequestId(requestId);
          dispatch({
            type: "THREADS/VOICE_THREADS.SUCCESS",
            payload: response.data,
            page: page,
          });
        }
      })
      .catch(function (error) {
        if (fetchThreadsRequestIds.indexOf(requestId) >= 0) {
          removeFetchThreadRequestId(requestId);
          dispatch({
            type: "THREADS/VOICE_THREADS.ERROR",
            payload: error,
          });
        }
      });
  };
}

export function setVoiceThreadsAsOutdated() {
  return function (dispatch) {
    dispatch({ type: "THREADS/VOICE_THREADS.OUTDATED" });
  };
}

export function fetchTempVoiceThread(
  companyId,
  numbersFilter,
  contactId,
  groupId
) {
  return function (dispatch) {
    dispatch({ type: "THREADS/TEMP_VOICE" });

    const url = "threads/single.json";
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        numbersFilter: numbersFilter,
        contactId: contactId,
        groupId: groupId,
        type: "all_voice",
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "THREADS/TEMP_VOICE.SUCCESS",
          payload: response.data,
          companyId: companyId,
          numbersFilter: numbersFilter,
          contactId: contactId,
          groupId: groupId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/TEMP_VOICE.ERROR",
          payload: error,
          companyId: companyId,
          numbersFilter: numbersFilter,
          contactId: contactId,
          groupId: groupId,
        });
      });
  };
}

export function fetchVoiceThread(companyId, numbersFilter, contactId, groupId) {
  return function (dispatch) {
    dispatch({ type: "THREADS/SINGLE_VOICE" });

    const url = "threads/single.json";
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        numbersFilter: numbersFilter,
        contactId: contactId,
        groupId: groupId,
        type: "all_voice",
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "THREADS/SINGLE_VOICE.SUCCESS",
          payload: response.data,
          companyId: companyId,
          numbersFilter: numbersFilter,
          contactId: contactId,
          groupId: groupId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/SINGLE_VOICE.ERROR",
          payload: error,
          companyId: companyId,
          numbersFilter: numbersFilter,
          contactId: contactId,
          groupId: groupId,
        });
      });
  };
}

export function fetchFilteredVoiceThreads(
  companyId,
  query,
  phoneCountryId,
  phoneCountryCode,
  numbersFilter,
  filter,
  page
) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/VOICE_FILTERED",
      companyId,
      phoneCountryId,
      phoneCountryCode,
      query,
      page,
    });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId,
        phoneCountryId,
        phoneCountryCode,
        query,
        numbersFilter,
        type: "all_voice",
        filter,
        page,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + "threads.json", config)
      .then((response) => {
        dispatch({
          type: "THREADS/VOICE_FILTERED.SUCCESS",
          payload: response.data,
          companyId,
          phoneCountryId,
          phoneCountryCode,
          query,
          page,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/VOICE_FILTERED.ERROR",
          payload: error,
        });
      });
  };
}

let fetchThreadsCancel;

export function fetchThreadItems(
  companyId,
  threadType,
  interlocutorId,
  fromExcId,
  toExcId,
  numbersFilter,
  threadItemKey,
  type,
  itemsType = ThreadItemsTypeEnums.All
) {
  const params = {
    companyId,
    numbersFilter,
    threadType,
    interlocutorId,
    fromExcId,
    toExcId,
    withEvents: 1,
    type,
    itemsType,
  };
  if (!interlocutorId && typeof interlocutorId !== "number") {
    logAPIRequest("fetchThreadItems()", "threads/items.json", params);
    return function () {
      return Promise.reject().catch(() => {});
    };
  }

  return function (dispatch) {
    fetchThreadsCancel && fetchThreadsCancel("FETCH_THREAD_ITEMS_CANCELED");
    dispatch({
      type: "FETCH_THREAD_ITEMS",
      companyId,
      threadType,
      interlocutorId,
      fromExcId,
      toExcId,
      threadItemKey,
    });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId,
        numbersFilter,
        threadType,
        interlocutorId,
        fromExcId,
        toExcId,
        withEvents: 1,
        type,
        itemsType,
      },
      cancelToken: new CancelToken((c) => {
        fetchThreadsCancel = c;
      }),
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + "threads/items.json", config)
      .then((response) => {
        dispatch({
          type: "FETCH_THREAD_ITEMS_SUCCESS",
          payload: response.data,
          companyId,
          threadType,
          interlocutorId,
          fromExcId,
          toExcId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "FETCH_THREAD_ITEMS_ERROR",
          payload: error,
          companyId,
          threadType,
          interlocutorId,
          fromExcId,
          toExcId,
        });
      });
  };
}

export function clearFetchThreadItems() {
  return function (dispatch) {
    dispatch({
      type: "FETCH_THREAD_ITEMS.CLEAR",
    });
  };
}

export function clearThreadItemsStatus() {
  return function (dispatch) {
    dispatch({
      type: "CLEAR_THREAD_ITEMS_STATUS",
    });
  };
}

export function fetchVoiceThreadItems(
  companyId,
  threadType,
  interlocutorId,
  fromExcId,
  toExcId,
  numbersFilter,
  threadItemKey
) {
  const params = {
    companyId: companyId,
    numbersFilter: numbersFilter,
    threadType: threadType,
    interlocutorId: interlocutorId,
    fromExcId,
    toExcId,
    type: "all_voice",
    withEvents: 1,
  };
  if (!interlocutorId && typeof interlocutorId !== "number") {
    logAPIRequest("fetchVoiceThreadItems()", "threads/items.json", params);
    return function () {
      return Promise.reject().catch(() => {});
    };
  }
  return function (dispatch) {
    dispatch({
      type: "THREAD/VOICE_ITEMS",
      companyId: companyId,
      threadType: threadType,
      interlocutorId: interlocutorId,
      fromExcId,
      toExcId,
      threadItemKey: threadItemKey,
    });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: params,
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + "threads/items.json", config)
      .then((response) => {
        dispatch({
          type: "THREAD/VOICE_ITEMS.SUCCESS",
          payload: response.data,
          companyId: companyId,
          threadType: threadType,
          interlocutorId: interlocutorId,
          fromExcId,
          toExcId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREAD/VOICE_ITEMS.ERROR",
          payload: error,
          companyId: companyId,
          threadType: threadType,
          interlocutorId: interlocutorId,
          fromExcId,
          toExcId,
        });
      });
  };
}

export function resetNewItemsCount() {
  return function (dispatch) {
    dispatch({
      type: "THREADS_RESET_NEW_ITEMS_COUNT",
    });
  };
}

export function searchReceivers(
  companyId,
  query,
  phoneCountryId,
  onlyContacts = 0,
  noLogs = 0,
  noGroupDuplicates = 0,
  currentGroupId,
  withArchived,
  withBlocked = 0
) {
  return function (dispatch) {
    dispatch({
      type: "THREADS_SEARCH_RECEIVERS",
      companyId,
      query,
      phoneCountryId,
    });

    const url = "threads/search-receivers.json";
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId,
        phoneCountryId,
        query,
        onlyContacts,
        noLogs,
        noGroupDuplicates,
        ...(currentGroupId && { markAlreadyMemberGroupId: currentGroupId }),
        withArchived,
        withBlocked,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "THREADS_SEARCH_RECEIVERS_SUCCESS",
          payload: response.data,
          companyId,
          query,
          phoneCountryId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS_SEARCH_RECEIVERS_ERROR",
          payload: error,
          companyId,
          query,
          phoneCountryId,
        });
      });
  };
}

export function clearSearchReceivers() {
  return function (dispatch) {
    dispatch({ type: "THREADS/SEARCH_RECEIVERS.CLEAR" });
  };
}

export function clearSearchReceiversStatus() {
  return function (dispatch) {
    dispatch({ type: "THREADS/SEARCH_RECEIVERS.CLEAR_STATUS" });
  };
}

export function fetchUnread(
  companyId,
  numbersFilter,
  limit,
  page,
  type = "all"
) {
  limit = typeof limit === "undefined" ? 5 : limit;
  return function (dispatch) {
    dispatch({ type: "THREADS/UNREAD", page, unreadType: type });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId,
        numbersFilter,
        limit,
        page,
        type,
      },
    });

    return axios
      .get(AxiosConfig.getEndpointAddress() + "threads/unread.json", config)
      .then((response) => {
        dispatch({
          type: "THREADS/UNREAD.SUCCESS",
          companyId,
          payload: response.data,
          unreadType: type,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/UNREAD.ERROR",
          payload: error,
          unreadType: type,
        });
      });
  };
}

export function fetchUndelivered(companyId, numbersFilter, limit) {
  limit = typeof limit === "undefined" ? 5 : limit;
  return function (dispatch) {
    dispatch({ type: "THREADS/UNDELIVERED" });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        numbersFilter: numbersFilter,
        limit: limit,
      },
    });
    return axios
      .get(
        AxiosConfig.getEndpointAddress() + "threads/undelivered.json",
        config
      )
      .then((response) => {
        dispatch({
          type: "THREADS/UNDELIVERED.SUCCESS",
          payload: response.data,
          companyId: companyId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/UNDELIVERED.ERROR",
          payload: error,
        });
      });
  };
}

let fetchCountsCancel;

export function cancelFetchCounts() {
  fetchCountsCancel && fetchCountsCancel("THREADS/COUNTS/CANCELED");
}

export function fetchCounts(companyId, numbersFilterValue, fields = []) {
  return function (dispatch) {
    dispatch({ type: "THREADS/COUNTS" });

    //REQUEST START
    window.loadingDebug?.logs?.push({
      text: "FETCH_COUNTS_REQUEST",
      companyId: companyId,
      numbersFilter: numbersFilterValue,
      fields: fields,
    });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        numbersFilter: numbersFilterValue,
        ...(Array.isArray(fields) && { fields }),
        all: fields?.length === 0 ? 1 : 0,
      },
      cancelToken: new CancelToken((c) => {
        fetchCountsCancel = c;
      }),
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + "threads/counts.json", config)
      .then((response) => {
        // RESPONSE
        window.loadingDebug?.logs?.push({
          text: "FETCH_COUNTS_SUCCESS",
          response: response.data,
        });
        dispatch({
          type: "THREADS/COUNTS.SUCCESS",
          payload: response.data,
          companyId: companyId,
        });
      })
      .catch(function (error) {
        // ERROR
        window.loadingDebug?.logs?.push({
          text: "FETCH_COUNTS_ERROR",
          response: error,
        });
        dispatch({
          type: "THREADS/COUNTS.ERROR",
          payload: error,
        });
      });
  };
}

export function markAsRead(
  companyId,
  contactId,
  toLogId,
  logId,
  numbersFilter,
  itemKey,
  threadType = "all"
) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/MARK_AS_READ",
      logId: logId,
    });

    const data = {
      companyId: companyId,
      contactId: contactId,
      toLogId: toLogId,
      logId: logId,
      numbersFilter: numbersFilter,
      type: threadType,
    };
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "threads/mark-as-read.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "THREADS/MARK_AS_READ.SUCCESS",
          payload: response.data,
          companyId: companyId,
          contactId: contactId,
          toLogId: toLogId,
          logId: logId,
          itemKey,
        });
      })
      .then(() => {
        dispatch(refreshCounts(companyId));
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/MARK_AS_READ.ERROR",
          payload: error,
        });
      });
  };
}

export function markAllAsRead(companyId, numbersFilter, filter) {
  return function (dispatch) {
    dispatch({ type: "THREADS/MARK_ALL_AS_READ" });

    const data = {
      companyId: companyId,
      numbersFilter: numbersFilter,
      filter,
    };
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "threads/mark-all-as-read.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "THREADS/MARK_ALL_AS_READ.SUCCESS",
          payload: response.data,
          companyId: companyId,
        });
      })
      .then(() => {
        dispatch(refreshCounts(companyId));
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/MARK_ALL_AS_READ.ERROR",
          payload: error,
        });
      });
  };
}

export function markAsUnread(companyId, contactId, logId) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/MARK_AS_UNREAD",
      logId: logId,
    });

    const data = {
      companyId: companyId,
      contactId: contactId,
      logId: logId,
    };
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "threads/mark-as-unread.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "THREADS/MARK_AS_UNREAD.SUCCESS",
          payload: response.data,
          companyId: companyId,
          contactId: contactId,
          logId: logId,
        });
      })
      .then(() => {
        dispatch(refreshCounts(companyId));
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/MARK_AS_UNREAD.ERROR",
          payload: error,
        });
      });
  };
}

export function markAllUndeliveredAsRead(companyId, numbersFilter) {
  return function (dispatch) {
    dispatch({ type: "THREADS/MARK_ALL_UNDELIVERED_AS_READ" });

    const data = {
      companyId: companyId,
      numbersFilter: numbersFilter,
    };
    return axios
      .post(
        AxiosConfig.getEndpointAddress() +
          "threads/mark-all-undelivered-as-read.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "THREADS/MARK_ALL_UNDELIVERED_AS_READ.SUCCESS",
          payload: response.data,
          companyId: companyId,
        });
      })
      .then(() => {
        dispatch(refreshCounts(companyId));
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/MARK_ALL_UNDELIVERED_AS_READ.ERROR",
          payload: error,
        });
      });
  };
}

export function setFilter(filter) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/FILTER",
      filter: filter,
    });
  };
}

export function setVoiceFilter(filter) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/VOICE_FILTER",
      filter: filter,
    });
  };
}

/**
 * Method for reload items status in Redux store
 *
 * @param companyId
 * @param scheduledMessageIds
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export function updateItemsStatus(companyId, scheduledMessageIds) {
  return function (dispatch) {
    dispatch({ type: "THREADS/UPDATE_ITEMS_STATUS" });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        scheduledMessageIds: scheduledMessageIds,
      },
    });
    return axios
      .get(
        AxiosConfig.getEndpointAddress() + "threads/items-status.json",
        config
      )
      .then((response) => {
        dispatch({
          type: "THREADS/UPDATE_ITEMS_STATUS.SUCCESS",
          payload: response.data,
          companyId: companyId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/UPDATE_ITEMS_STATUS.ERROR",
          payload: error,
        });
      });
  };
}

export function clearUpdateItemsStatus() {
  return function (dispatch) {
    dispatch({ type: "THREADS/UPDATE_ITEMS_STATUS.CLEAR" });
  };
}

export function reloadItem(companyId, groupSmsBlastId) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/RELOAD_ITEM",
      groupSmsBlastId,
    });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId,
        groupSmsBlastId,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + `threads/item.json`, config)
      .then((response) => {
        dispatch({
          type: "THREADS/RELOAD_ITEM.SUCCESS",
          payload: response.data,
          companyId,
          groupSmsBlastId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/RELOAD_ITEM.ERROR",
          payload: error,
        });
      });
  };
}

export function refreshGroupMsgStats(companyId, groupSmsBlastId) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/REFRESH_GROUP_MSG_STATS",
      groupSmsBlastId,
    });

    const data = {
      companyId,
      groupSmsBlastId,
    };
    return axios
      .post(
        AxiosConfig.getEndpointAddress() +
          `threads/refresh-group-msg-stats.json`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "THREADS/REFRESH_GROUP_MSG_STATS.SUCCESS",
          payload: response.data,
          companyId,
          groupSmsBlastId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/REFRESH_GROUP_MSG_STATS.ERROR",
          payload: error,
        });
      });
  };
}

export function setScheduledVisible(visible) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/SCHEDULED_VISIBLE",
      visible,
    });
  };
}

export function clearItems(threadType, interlocutorId) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/CLEAR_ITEMS",
      threadType,
      interlocutorId,
    });
  };
}

export function setHeaderProgress(val) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/SET_HEADER_PROGRESS",
      val,
    });
  };
}

export function closeThreads(
  company_id,
  contact_id = [],
  group_id = [],
  workflow_id,
  needRouting = false
) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/CLOSE_THREADS",
      closingIds: [...contact_id, ...group_id],
    });
    const data = {
      company_id,
      contact_id,
      group_id,
    };
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + `threads/bulk-close.json`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        if (
          response.data &&
          (response.data.closed_groups_ids.length > 0 ||
            response.data.closed_contacts_ids.length > 0)
        ) {
          dispatch({
            type: "THREADS/CLOSE_THREADS.SUCCESS",
            payload: response.data,
            closingIds: [
              ...response.data.closed_groups_ids,
              ...response.data.closed_contacts_ids,
            ],
            workflow_id,
            needRouting,
          });
        } else {
          dispatch({
            type: "THREADS/CLOSE_THREADS.FAILURE",
            payload: "Couldn't close",
            workflow_id,
            closingIds: [...contact_id, ...group_id],
          });
        }
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/CLOSE_THREADS.FAILURE",
          payload: error,
          workflow_id,
          closingIds: [...contact_id, ...group_id],
        });
      });
  };
}

export function reopenThreads(
  company_id,
  contact_id = [],
  group_id = [],
  workflow_id,
  needRouting = false
) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/REOPEN_THREADS",
      reopeningIds: [...contact_id, ...group_id],
    });
    const data = {
      company_id,
      contact_id,
      group_id,
    };
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + `threads/bulk-reopen.json`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        if (
          response.data &&
          (response.data.reopened_groups_ids.length > 0 ||
            response.data.reopened_contacts_ids.length > 0)
        ) {
          dispatch({
            type: "THREADS/REOPEN_THREADS.SUCCESS",
            payload: response.data,
            reopeningIds: [
              ...response.data.reopened_groups_ids,
              ...response.data.reopened_contacts_ids,
            ],
            workflow_id,
            needRouting,
          });
        } else {
          dispatch({
            type: "THREADS/REOPEN_THREADS.FAILURE",
            payload: "Couldn't reopen",
            workflow_id,
            reopeningIds: [...contact_id, ...group_id],
          });
        }
      })
      .then(() => {
        dispatch(refreshCounts(company_id));
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/REOPEN_THREADS.FAILURE",
          payload: error,
          workflow_id,
          reopeningIds: [...contact_id, ...group_id],
        });
      });
  };
}

export function addSnackData(data, entity) {
  return (dispatch) => {
    dispatch({
      type: "THREADS/ADD_SNACK_DATA",
      payload: { data, entity },
    });
  };
}

export function removeSnackData(id, entity) {
  return (dispatch) => {
    dispatch({
      type: "THREADS/REMOVE_SNACK_DATA",
      payload: { id, entity },
    });
  };
}

let fetchVoiceCountsCancel;

export function cancelFetchVoiceCounts() {
  fetchVoiceCountsCancel &&
    fetchVoiceCountsCancel("THREADS/VOICE_COUNTS/CANCELED");
}

export function fetchVoiceCounts(companyId, numbersFilterValue, fields = []) {
  return function (dispatch) {
    dispatch({ type: "THREADS/VOICE_COUNTS" });
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        numbersFilterValue: numbersFilterValue,
        ...(Array.isArray(fields) && { fields }),
        all: fields?.length === 0 ? 1 : 0,
        type: "all_voice",
      },
      cancelToken: new CancelToken((c) => {
        fetchVoiceCountsCancel = c;
      }),
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + "threads/counts.json", config)
      .then((response) => {
        dispatch({
          type: "THREADS/VOICE_COUNTS.SUCCESS",
          payload: response.data,
          companyId: companyId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/VOICE_COUNTS.ERROR",
          payload: error,
        });
      });
  };
}

export function markAsReadThreads(
  company_id,
  contact_id = [],
  group_id = [],
  allParams,
  interlocutorId
) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/MARK_AS_READ_THREADS",
    });
    const data = {
      company_id,
      contact_id,
      group_id,
      all: allParams,
    };
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + `threads/bulk-mark-as-read.json`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "THREADS/MARK_AS_READ_THREADS.SUCCESS",
          payload: {
            ...response.data,
            contactIds: contact_id,
            groupIds: group_id,
            interlocutorId,
          },
        });
      })
      .then(() => {
        dispatch(refreshCounts(company_id));
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/MARK_AS_READ_THREADS.FAILURE",
          payload: error,
        });
      });
  };
}

export function markAsUnreadThreads(
  company_id,
  contact_id = [],
  group_id = []
) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/MARK_AS_UNREAD_THREADS",
    });
    const data = {
      company_id,
      contact_id,
      group_id,
    };
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + `threads/bulk-mark-as-unread.json`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "THREADS/MARK_AS_UNREAD_THREADS.SUCCESS",
          payload: response.data,
        });
      })
      .then(() => {
        dispatch(refreshCounts(company_id));
      })
      .catch(function (error) {
        dispatch({
          type: "THREADS/MARK_AS_UNREAD_THREADS.FAILURE",
          payload: error,
        });
      });
  };
}

export function updateDrafts(threadId, threadType, removeDraft) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/UPDATE_DRAFTS",
      threadId,
      threadType,
      removeDraft,
    });
  };
}

export function resetThreadItemId() {
  return function (dispatch) {
    dispatch({ type: "RESET_THREAD_ITEM_ID" });
  };
}

export function getNewDrafts(currentDraftsLength) {
  return function (dispatch) {
    dispatch({ type: "POPULATE_NEW_DRAFTS", currentDraftsLength });
  };
}
export function resetMarkAsReadId() {
  return function (dispatch) {
    dispatch({ type: "RESET_MARK_AS_READ_ID" });
  };
}

export function clearThreadSearchData() {
  return function (dispatch) {
    dispatch({ type: "CLEAR_THREAD_SEARCH_DATA" });
  };
}

export function setThreadSearchFilter(filter, condition, populateQuery) {
  return function (dispatch) {
    dispatch({
      type: "SET_THREAD_SEARCH_FILTER",
      filter,
      condition,
      populateQuery,
    });
  };
}

export function resendUndeliveredMessage(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      "MESSAGES/RESEND_UNDELIVERED_MESSAGE",
      "messages/resend-failed.json",
      data,
      {
        requestDispatchData: { requestId },
        successDispatchData: { requestId },
        errorDispatchData: { requestId },
        thenFunction: () => dispatch(fetchCounts(data.companyId, true)),
      }
    );
}

export function clearResendUndeliveredMessage() {
  return (dispatch) =>
    dispatch({
      type: "MESSAGES/RESEND_UNDELIVERED_MESSAGE.CLEAR",
    });
}

export function clearReprocessedUndeliveredLabel(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      "THREADS/CLEAR_REPROCESSED_UNDELIVERED_LABEL",
      "threads/clear-reprocessed-undelivered.json",
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: { data },
      }
    ).then(() => {
      dispatch(fetchCounts(data.company_id, true));
    });
}

export function markInitialLoadSuccess() {
  return function (dispatch) {
    dispatch({ type: "THREADS/INITIAL_LOAD.SUCCESS" });
  };
}

export function setHighlightedTerms(highlightedTerms) {
  return function (dispatch) {
    dispatch({ type: "THREADS/SET_HIGHLIGHTED_TERMS", highlightedTerms });
  };
}

export function setCurrentActiveTerm(searchedTerm) {
  return function (dispatch) {
    dispatch({ type: "THREADS/SET_CURRENT_ACTIVE_TERM", searchedTerm });
  };
}

export function resetThreadsPage() {
  return function (dispatch) {
    dispatch({ type: "THREADS/RESET_THREADS_PAGE" });
  };
}

export function resetNewMessageLogId() {
  return function (dispatch) {
    dispatch({ type: "THREADS/RESET_NEW_MESSAGE_LOG_ID" });
  };
}

export function clearMarkAsReadStatus() {
  return function (dispatch) {
    dispatch({ type: "THREADS/MARK_AS_READ.CLEAR" });
  };
}

export function setVoiceThreadsInitialFetchFlag(fetched = true) {
  return (dispatch) =>
    dispatch({
      type: "THREADS/VOICE_FILTERED.INITIAL_FETCH_FLAG",
      fetched: fetched,
    });
}

export function setMessagesThreadsInitialFetchFlag(fetched = true) {
  return (dispatch) =>
    dispatch({
      type: "FETCH_THREADS.INITIAL_FETCH_FLAG",
      fetched: fetched,
    });
}

export function setThreadItemsTypeFilter(filter) {
  return (dispatch) =>
    dispatch({
      type: "THREADS/SET_THREAD_ITEMS_TYPE_FILTER",
      filter,
    });
}

export const setScrollDownChatMessagesRedux = (scrollDown) => {
  return (dispatch) => {
    dispatch({
      type: "THREADS/SET_SCROLL_DOWN_CHAT_MESSAGES",
      scrollDown,
    });
  };
};

export const showUnread = () => {
  return (dispatch) => {
    dispatch({ type: "THREADS/UNIGNORE_UNREAD" });
    dispatch(setFilter("unread"));
  };
};

export function setMessagesTab(payload) {
  return function (dispatch) {
    dispatch({
      type: "THREADS/SET_MESSAGES_TAB_TYPE",
      payload,
    });
  };
}

export function addVCards(data) {
  return (dispatch) =>
    postRequest(dispatch, "THREADS/ADD_VCARDS", "vcards/bulk-add.json", data);
}

export function clearAddVcardStatus() {
  return function (dispatch) {
    dispatch({ type: "THREADS/ADD_VCARDS.CLEAR" });
  };
}
