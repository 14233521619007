import React from "react";

// new icons
import { ReactComponent as CcbSvg } from "./assets/img/icons-new/people-info/ccb/ccb-gray.svg";
import { ReactComponent as PcoSvg } from "./assets/img/icons-new/people-info/pco/pco-gray.svg";
import { ReactComponent as PLIconDarkSvg } from "./assets/img/icons-new/people-info/pastorsline/pastorsline-gray.svg";
import { ReactComponent as VoipIcon } from "./assets/img/icons-new/number-status/voip/voip-gray.svg";
import { ReactComponent as LandlineIcon } from "./assets/img/icons-new/number-status/landline/landline-gray.svg";
import { ReactComponent as MobileIcon } from "./assets/img/icons-new/number-status/mobile/mobile-gray.svg";
import { ReactComponent as SearchDottedIcon } from "./assets/img/icons-new/number-status/unchecked/unchecked-gray.svg";
import { ReactComponent as GroupsAddingAmcIcon } from "./assets/img/icons-new/settings/setting/setting-black.svg";
import { ReactComponent as McSvg } from "./assets/img/icons-new/people-info/mailchimp/mailchimp-white.svg";
import { ReactComponent as BreezeSvg } from "./assets/img/icons-new/integration/breeze/breeze-dark-gray.svg";
import { ReactComponent as RockRmsSvg } from "./assets/img/icons-new/integration/rockRMS/rockRMS-dark-gray.svg";
import { ReactComponent as ElvantoSvg } from "./assets/img/icons-new/people-info/elvanto/elvanto-black.svg";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

import AxiosConfig from "./AxiosConfig";
import { store, history } from "./store";

import {
  cancelFetchCounts,
  cancelFetchVoiceCounts,
  fetchCounts as fetchThreadsCounts,
  fetchVoiceCounts,
} from "./actions/threads";
import {
  cancelFetchCountsGroups,
  fetchCounts as fetchGroupCounts,
} from "./actions/groups";
import {
  cancelFetchCountsContacts,
  fetchCounts as fetchPeopleCounts,
} from "./actions/contacts";
import AnchorWithTooltip from "./components/AnchorWithTooltip";
import { subMenusSettingsLabelRoutes } from "./components/Menus/SubMenus/allSubMenus";
import { timeFormats } from "./utils/timeFormats";
import IntegrationIcon from "./components/IntegrationIcon";
import {
  getAdminSettingsMenu,
  getPermissionParsed,
  isNotAnEmptyArray,
} from "./utils/settingsHelpers";
import { breakpoints } from "./utils/breakpoints";
import {
  CONTACT,
  DRAFTS_LOCAL_STORAGE_PREFIX,
  GROUP,
  GROUP_TEXT_SIGNATURE,
  ROCK,
  STANDARD_TEXT_SIGNATURE,
} from "./utils/constants";
import { TAGS_ROUTE } from "./components/Menus/routes";

export const timeAgo = (time, timezoneOffset) => {
  const date = new Date(time);
  const diff = (new Date().getTime() - date.getTime()) / 1000;
  if (diff < -86400) {
    let exDate = new Date(time);
    exDate.setTime(exDate.getTime() + timezoneOffset * 1000);
    let text = "";
    if (diff < -432000) {
      text =
        ("0" + exDate.getUTCDate()).substr(-2) +
        "." +
        ("0" + (exDate.getUTCMonth() + 1)).substr(-2);
    } else {
      const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      text = weekdays[exDate.getUTCDay()];
    }
    text +=
      ", " +
      ("0" + exDate.getUTCHours()).substr(-2) +
      ":" +
      ("0" + exDate.getUTCMinutes()).substr(-2);
    return text;
  } else if (diff < -3600) {
    const hours = Math.abs(Math.ceil(diff / 3600 - 0.5));
    return "In " + hours + (hours > 1 ? " hrs" : " hr");
  } else if (diff < 0) {
    return "In " + Math.abs(Math.ceil(diff / 60 - 0.5)) + " min";
  } else if (diff < 10) {
    return "few seconds ago";
  } else if (diff < 60) {
    return Math.floor(diff) + " sec";
  } else if (diff < 3600) {
    return Math.floor(diff / 60) + " min";
  } else if (diff < 86400) {
    return Math.floor(diff / 3600) + " hr";
  }
  return Math.floor(diff / 86400) + " d";
};

/**
 * @param time
 * @param timezoneOffset
 * @returns {string} i.e. August 16th, 2018 | 01:40 PM
 */
export const formatDateTime = (time, timezoneOffset, withTime = true) => {
  if (!time) return "-";
  let date = new Date(time);
  date.setTime(date.getTime() + timezoneOffset * 1000);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayOfMonth = date.getUTCDate();
  let text = monthNames[date.getUTCMonth()] + " " + dayOfMonth;
  // Assumption Days do not go beyond 31
  // or else we would have to check for numbers like 111 or 211
  if (dayOfMonth >= 11 && dayOfMonth <= 19) {
    text += "th";
  } else {
    let dayString = dayOfMonth.toString();
    let lastNumber = Number(dayString.charAt(dayString.length - 1));

    if (lastNumber === 1) {
      text += "st";
    } else if (lastNumber === 2) {
      text += "nd";
    } else if (lastNumber === 3) {
      text += "rd";
    } else {
      text += "th";
    }
  }
  text += ", " + date.getUTCFullYear() + " | ";
  const hours = date.getUTCHours();
  let showHours = hours;
  if (showHours === 0) {
    showHours = 12;
  } else if (showHours > 12) {
    showHours -= 12;
  }
  const ampm = hours >= 12 ? "PM" : "AM";
  if (withTime) {
    text +=
      ("0" + showHours).substr(-2) +
      ":" +
      ("0" + date.getUTCMinutes()).substr(-2) +
      " " +
      ampm;
  }

  return withTime ? text : text.replace(" | ", "");
};

/**
 * @param time
 * @param timezoneOffset
 * @returns {string} i.e. Thursday, August 5th or just Thursday (if last 6 days)
 */
export const formatDateTime2 = (time, timezoneOffset) => {
  if (typeof time === "undefined" || time === null) {
    return null;
  }
  let date = new Date(time);
  date.setTime(date.getTime() + timezoneOffset * 1000);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // If date is from last 6 days, return just week day
  const currentDate = new Date();
  currentDate.setUTCHours(date.getUTCHours());
  currentDate.setUTCMinutes(date.getUTCMinutes());
  currentDate.setUTCSeconds(date.getUTCSeconds());
  currentDate.setUTCMilliseconds(date.getUTCMilliseconds());
  if ((currentDate.getTime() - date.getTime()) / 86400000 < 7) {
    return weekDays[date.getUTCDay()];
  }

  const dayOfMonth = date.getUTCDate();
  let text =
    weekDays[date.getUTCDay()] +
    ", " +
    monthNames[date.getUTCMonth()] +
    " " +
    dayOfMonth;
  // Assumption Days do not go beyond 31
  // or else we would have to check for numbers like 111 or 211
  if (dayOfMonth >= 11 && dayOfMonth <= 19) {
    text += "th";
  } else {
    let dayString = dayOfMonth.toString();
    let lastNumber = Number(dayString.charAt(dayString.length - 1));

    if (lastNumber === 1) {
      text += "st";
    } else if (lastNumber === 2) {
      text += "nd";
    } else if (lastNumber === 3) {
      text += "rd";
    } else {
      text += "th";
    }
  }

  return text;
};

/**
 * @param time
 * @param timezoneOffset
 * @returns {string} i.e. August 16, 2018
 */
export const formatDateTime3 = (time, timezoneOffset) => {
  let date = new Date(time);
  date.setTime(date.getTime() + timezoneOffset * 1000);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayOfMonth = date.getUTCDate();
  let text = monthNames[date.getUTCMonth()] + " " + dayOfMonth;
  text += ", " + date.getUTCFullYear();

  return text;
};

/**
 * @param time
 * @param timezoneOffset
 * @returns {string} i.e. August 20th, 2018 | 1.55 AM
 */
export const formatDateTime4 = (time, timezoneOffset) => {
  let date = new Date(time);
  if (timezoneOffset) {
    date.setTime(date.getTime() + timezoneOffset * 1000);
  }

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayOfMonth = date.getUTCDate();
  let text =
    monthNames[date.getUTCMonth()] +
    " " +
    dayOfMonth +
    englishOrdinalSuffix(dayOfMonth);
  text += ", " + date.getUTCFullYear();
  const hours = date.getUTCHours();

  let showHours = hours;
  if (showHours === 0) {
    showHours = 12;
  } else if (showHours > 12) {
    showHours -= 12;
  }
  text +=
    " | " +
    showHours +
    "." +
    ("0" + date.getUTCMinutes()).substr(-2) +
    " " +
    (hours >= 12 ? "PM" : "AM");

  return text;
};

/**
 * @param time
 * @param timezoneOffset
 * @returns {string} i.e. 2019-06-30 14:00:00
 */
export const formatDateTime5 = (time, timezoneOffset) => {
  if (!time) return "";
  let date = new Date(time);
  if (timezoneOffset) {
    date.setTime(date.getTime() + timezoneOffset * 1000);
  }

  let text =
    date.getUTCFullYear() +
    "-" +
    ("0" + (date.getUTCMonth() + 1)).substr(-2) +
    "-" +
    ("0" + date.getUTCDate()).substr(-2) +
    " " +
    ("0" + date.getUTCHours()).substr(-2) +
    ":" +
    ("0" + date.getUTCMinutes()).substr(-2) +
    ":" +
    ("0" + date.getUTCSeconds()).substr(-2);
  return text;
};

/**
 * @param time
 * @param timezoneOffset
 * @returns {string} i.e. Aug 16, 2018
 */
export const formatDateTime6 = (time, timezoneOffset) => {
  let date = new Date(time);
  date.setTime(date.getTime() + timezoneOffset * 1000);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayOfMonth = date.getUTCDate();
  let text = monthNames[date.getUTCMonth()] + " " + dayOfMonth;
  text += ", " + date.getUTCFullYear();

  return text;
};

export const formatDate5 = (dateString) => {
  let date = new Date(dateString);

  let text =
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).substr(-2) +
    "-" +
    ("0" + date.getDate()).substr(-2);
  return text;
};

export const formatDate6 = (dateString) => {
  if (!dateString) return "";

  let date = new Date(dateString);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayOfMonth = date.getDate();
  let text = monthNames[date.getMonth()] + " " + dayOfMonth;
  text += date.getFullYear() === 1900 ? "" : ", " + date.getFullYear();

  return text;
};

// date needs to be of form: 1934-02-04T00:00:00+00:00
export const formatWithoutTimezoneDate6 = (date) => {
  if (!date) return "";

  const dateString = date.substr(0, 10);
  const matched = dateString.match(/(\d+)-(\d+)-(\d+)/);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return `${monthNames[matched[2] - 1]} ${Number(matched[3])}, ${Number(
    matched[1]
  )}`;
};

// date needs to be of form: 1934-02-04T00:00:00+00:00
export const getDateAsObjectWithoutTimezone = (date) => {
  const dateString = date.substr(0, 10);
  const matched = dateString.match(/(\d+)-(\d+)-(\d+)/);

  return {
    day: Number(matched[3]),
    month: Number(matched[2]),
    year: Number(matched[1]),
  };
};

export const getDateObjectToSimpleString = (birthdayForAPI) => {
  return `${birthdayForAPI.year}-${String(birthdayForAPI.month).padStart(
    2,
    "0"
  )}-${String(birthdayForAPI.day).padStart(2, "0")}`;
};

export const englishOrdinalSuffix = (date) => {
  return date % 10 === 1 && date !== 11
    ? "st"
    : date % 10 === 2 && date !== 12
    ? "nd"
    : date % 10 === 3 && date !== 13
    ? "rd"
    : "th";
};

export const formatDuration = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;
  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  let res = [];
  if (hours) {
    res.push(("0" + hours).substr(-2));
  }
  res.push(("0" + minutes).substr(-2));
  res.push(("0" + Math.floor(seconds + 0.5)).substr(-2));
  return res.join(":");
};

export const timezoneOffsetToStr = (timezoneOffset) => {
  const offset = Math.abs(timezoneOffset / 60);
  const offsetStr =
    (timezoneOffset >= 0 ? "+" : "-") +
    `0${Math.floor(offset / 60)}`.slice(-2) +
    ":" +
    `0${(offset / 60 - Math.floor(offset / 60)) * 60}`.slice(-2);
  return offsetStr;
};

export const ucfirst = (string) => {
  if (!string || string === "") {
    return "";
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const trim = (string, length) => {
  if (!string || string === "") {
    return "";
  }
  return (
    string.trim().substr(0, length).trim() +
    (string.length > length ? "..." : "")
  );
};

/**
 * Format number. Similar function you can use in API endpoint, NumbersComponent::formatNumber
 * @param number Full number (with country code, without + prefix)
 * @param allowNonDigit
 * @returns {string} Formatted number
 */
export const formatPhoneNumber = (
  number,
  phonecode,
  allowNonDigit = false,
  useShorterVersion = false
) => {
  number = number ? String(number) : "";
  if (allowNonDigit && number.replace(/[^0-9]/g, "") !== number) {
    return number;
  }
  if (number.match(/[a-zA-Z]+/)) {
    return number;
  }
  number = number.replace(/[^0-9]/g, "");
  if (phonecode) {
    if (useShorterVersion) {
      number = `+${phonecode} ${number}`;
      number = number.length <= 4 ? number : `...${number.substr(-4)}`;
    } else if (number.length === 10 && phonecode) {
      number = `+${phonecode} ${number.replace(
        /([0-9]{3})([0-9]{3})([0-9]{4})/,
        "($1) $2-$3"
      )}`;
    } else {
      number = `+${phonecode} ${number}`;
    }
  } else {
    if (useShorterVersion) {
      number = number.length <= 4 ? number : `...${number.substr(-4)}`;
    } else if (number.length >= 7 && number.length <= 9) {
      number = number.replace(/([0-9]{3})([0-9]{4})/, "$1-$2");
    } else if (number.length === 10) {
      number = number.replace(/([0-9]{3})([0-9]{3})([0-9]{4})/, "($1) $2-$3");
    } else if (number.length === 11) {
      number = number.replace(
        /([0-9]{1})([0-9]{3})([0-9]{3})([0-9]{4})/,
        "+$1 ($2) $3-$4"
      );
    }
  }
  return number;
};
export const contactsName = (contacts, asHtml, maxCount) => {
  // filter only primary contacts
  let filteredContacts = [];
  if (contacts.length > 1) {
    for (let i = 0; i < contacts.length; i++) {
      if (contacts[i].is_primary) {
        filteredContacts.push(contacts[i]);
      }
    }
  } else {
    filteredContacts = contacts;
  }
  contacts = filteredContacts;

  if (contacts && contacts.length === 1) {
    let name = contactName(
      contacts[0].first_name,
      contacts[0].last_name,
      "" +
        (contacts[0].country ? contacts[0].country.phonecode : "") +
        contacts[0].phone_number
    );
    if (asHtml) {
      return (
        <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          {name}
        </span>
      );
    }
  }

  let names = [];
  for (let i = 0; i < contacts.length; i++) {
    /*if (contacts[i].is_duplicate) {
      continue;
    }*/
    names.push(
      contactName(
        contacts[i].first_name,
        contacts[i].last_name,
        "" +
          (contacts[i].country ? contacts[i].country.phonecode : "") +
          contacts[i].phone_number
      )
    );
  }
  maxCount = 4;
  let namesUnique = [...new Set(names)];
  if (namesUnique.length > maxCount) {
    namesUnique = namesUnique.slice(0, maxCount);
  }

  if (!asHtml) {
    if (contacts.length > namesUnique.length) {
      namesUnique.push("+" + (contacts.length - namesUnique.length));
    }
    return namesUnique.join(", ");
  }

  let namesArr = [];
  for (let i = 0; i < namesUnique.length; i++) {
    namesArr.push(
      <span
        key={namesUnique[i]}
        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
      >
        {namesUnique[i]}
        {i + 1 < namesUnique.length ? "," : ""}&nbsp;
      </span>
    );
  }

  return (
    <React.Fragment>
      {namesArr}
      {contacts.length > namesUnique.length &&
        "+" + (contacts.length - namesUnique.length)}
    </React.Fragment>
  );
};

export const contactName = (
  first_name,
  last_name,
  phone_number,
  options = { firstLetterLastName: false }
) => {
  let str = first_name && first_name !== "" ? first_name : "";
  if (options.firstLetterLastName) {
    str +=
      last_name && last_name !== ""
        ? " " + last_name[0].toUpperCase() + "."
        : "";
  } else {
    str += last_name && last_name !== "" ? " " + last_name : "";
  }
  str = str.trim();
  if (str === "") {
    str = phone_number ? formatPhoneNumber(phone_number) : "Unnamed/Empty";
  }
  return str;
};

export const getAppTime = () => {
  if (!store.getState().settings || !store.getState().settings?.settings) {
    return null;
  }
  return (
    new Date().getTime() - store.getState().settings?.settings?._appTimeDiffMs
  );
};

export const getCampaignType = (log, scheduleMessage) => {
  let campaignId = null;
  let contestId = null;
  let questionId = null;
  let autoresponderId = null;

  if (scheduleMessage) {
    campaignId = scheduleMessage.automessage_id;
    contestId = scheduleMessage.contest_id;
    questionId = scheduleMessage.question_id;
  } else if (log) {
    campaignId = log.campaign_id;
    contestId = log.contest_id;
    questionId = log.question_id;
    autoresponderId = log.autoresponder_id;
  }

  if (campaignId > 0) {
    return "am";
  } else if (contestId > 0) {
    return "contests";
  } else if (questionId > 0) {
    return "poll";
  } else if (autoresponderId > 0) {
    return "dc";
  } /* else if (birthdayId > 0) { // Birthday flag is not supported in main PHP app yet
    return 'birthday';
  }*/
  return null;
};

export const isNumber = (query) => {
  return query && query.match(/^[0-9()\-\s+]+$/) ? true : false;
};

export const getFormattedAccountCredits = (credits, name = "Credits") => {
  const creditsFull =
    credits.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ` ${name}`;
  let creditsSimple = "";
  if (credits > 1000000) {
    creditsSimple = "Over " + Math.floor(credits / 1000000) + `M ${name}`;
  } else if (credits > 500000) {
    creditsSimple = `Over 0.5M ${name}`;
  } else if (credits > 100000) {
    creditsSimple = "Over " + Math.floor(credits / 100000) * 100 + `K ${name}`;
  } else if (credits > 10000) {
    creditsSimple = "Over " + Math.floor(credits / 10000) * 10 + `K ${name}`;
  } else {
    creditsSimple = creditsFull;
  }

  return { creditsSimple, creditsFull };
};

export const getFormattedNumber = (n) => {
  if (typeof n !== "number") return n;
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +Math.floor(n / 1e3) + "K+";
  if (n >= 1e6 && n < 1e9) return +Math.floor(n / 1e6) + "M+";
  if (n >= 1e9 && n < 1e12) return +Math.floor(n / 1e9) + "B+";
  if (n >= 1e12) return +Math.floor(n / 1e12) + "T+";
};

export const contactsStats = (contacts) => {
  let blockedCount = 0;
  let unsubscribedCount = 0;
  let optedoutCount = 0;
  let donotcallCount = 0;
  let duplicatesCount = 0;
  let voipCount = 0;
  let landlineCount = 0;
  let mobileCount = 0;
  let uncheckedCount = 0;
  let failedCount = 0;

  let blockedNumbers = "";
  let unsubscribedNumbers = "";
  let optedoutNumbers = "";
  let donotcallNumbers = "";
  let duplicatesNumbers = "";
  let voipNumbers = "";
  let landlineNumbers = "";
  let mobileNumbers = "";
  let uncheckedNumbers = "";
  let failedNumbers = "";

  for (let i = 0; i < contacts.length; i++) {
    // ommit groups
    if (typeof contacts[i].group_name !== "undefined") {
      continue;
    }
    const contact = contacts[i];
    const phoneNumber = formatPhoneNumber(
      "" +
        (contact.country ? contact.country.phonecode : "") +
        contact.phone_number
    );

    if (contact.status === 3 && contact.unsub_by_user_id > 0) {
      unsubscribedCount++;
      unsubscribedNumbers +=
        (unsubscribedNumbers.length ? ", " : "") + phoneNumber;
    }
    if (contact.status === 3 && contact.unsub_by_user_id === 0) {
      optedoutCount++;
      optedoutNumbers += (optedoutNumbers.length ? ", " : "") + phoneNumber;
    }
    if (contact.status === 1) {
      blockedCount++;
      blockedNumbers += (blockedNumbers.length ? ", " : "") + phoneNumber;
    }
    if (contact.is_duplicate === 1) {
      duplicatesCount++;
      duplicatesNumbers += (duplicatesNumbers.length ? ", " : "") + phoneNumber;
    }
    if (contact.number_lookup) {
      if (contact.number_lookup.type === "voip") {
        voipCount++;
        voipNumbers += (voipNumbers.length ? ", " : "") + phoneNumber;
      } else if (contact.number_lookup.type === "landline") {
        landlineCount++;
        landlineNumbers += (landlineNumbers.length ? ", " : "") + phoneNumber;
      } else if (contact.number_lookup.type === "mobile") {
        mobileCount++;
        mobileNumbers += (mobileNumbers.length ? ", " : "") + phoneNumber;
      } else if (contact.number_lookup.type === "zeroNumber") {
        donotcallCount++;
        donotcallNumbers += (donotcallNumbers.length ? ", " : "") + phoneNumber;
      } else {
        failedCount++;
        failedNumbers += (failedNumbers.length ? ", " : "") + phoneNumber;
      }
    } else {
      uncheckedCount++;
      uncheckedNumbers += (uncheckedNumbers.length ? ", " : "") + phoneNumber;
    }
  }

  return {
    blockedCount,
    unsubscribedCount,
    optedoutCount,
    donotcallCount,
    duplicatesCount,
    voipCount,
    landlineCount,
    mobileCount,
    uncheckedCount,
    failedCount,
    blockedNumbers,
    unsubscribedNumbers,
    optedoutNumbers,
    donotcallNumbers,
    duplicatesNumbers,
    voipNumbers,
    landlineNumbers,
    mobileNumbers,
    uncheckedNumbers,
    failedNumbers,
  };
};

export const getIntegrationLabelBy = (contact) => {
  if (!contact) return "";
  if (contact.is_ccb) {
    return "CCB";
  }
  if (contact.is_pco) {
    return "PCO";
  }
  if (contact.is_mc) {
    return "MailChimp";
  }
  if (contact.is_elvanto) {
    return "Elvanto";
  }
  if (contact.is_breeze) {
    return "Breeze";
  }
  if (contact.is_rock) {
    return "Rock";
  }
  return "";
};

export const isIOs = () => {
  return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
};

export const isAndroid = () => {
  return /Android/.test(navigator.userAgent);
};

export const getDeviceSize = () => {
  if (document.body.clientWidth >= 1200) {
    return "xl";
  } else if (document.body.clientWidth >= 992) {
    return "lg";
  } else if (document.body.clientWidth >= 768) {
    return "md";
  } else if (document.body.clientWidth >= 576) {
    return "sm";
  } else {
    return "xs";
  }
};

export const getBreakPoints = (deviceWidth) => {
  if (0 < deviceWidth && deviceWidth < 468) {
    return breakpoints[468];
  } else if (468 <= deviceWidth && deviceWidth <= 768) {
    return breakpoints[768];
  } else if (768 < deviceWidth && deviceWidth <= 992) {
    return breakpoints[992];
  } else if (992 < deviceWidth && deviceWidth <= 1200) {
    return breakpoints[1200];
  } else if (deviceWidth >= 1200) {
    return breakpoints[1500];
  }
  return "";
};

export const isCordovaApp = () => {
  return (
    typeof cordova == "object" ||
    navigator.userAgent.toLowerCase().indexOf("cordova") > -1
  );
};

export const isRecordingAudioSupported = () => {
  if (
    typeof MediaRecorder !== "undefined" &&
    typeof navigator.mediaDevices !== "undefined" &&
    typeof navigator.mediaDevices.getUserMedia !== "undefined"
  ) {
    return true;
  }
  return false;
};

export const flattenErrorsObject = (errors) => {
  return [].concat.apply(
    [],
    Object.keys(errors).map((field) => {
      return Object.values(errors[field]);
    })
  );
};

export const parseApiErrors = (
  data,
  defaultErrors = {},
  insertBreakLines = false
) => {
  const errors = defaultErrors;
  if (typeof data === "string") {
    errors["form"] = data;
  } else if (typeof data === "object") {
    if (!data) {
      errors["form"] = "An error occurred.";
    } else if (
      typeof data.message !== "undefined" &&
      typeof data.url !== "undefined" &&
      typeof data.code !== "undefined"
    ) {
      errors["form"] = data.message;
      if (errors["form"].substr(-1) !== ".") {
        errors["form"] += ".";
      }
    } else {
      const fields = Object.keys(data);
      for (let i = 0; i < fields.length; i += 1) {
        let field = fields[i];
        if (field === "_") {
          field = "form";
        }
        errors[field] = Object.values(data[fields[i]]);
        for (let j = 0; j < errors[field].length; j += 1) {
          if (errors[field][j].substr(-1) !== ".") {
            errors[field][j] += ".";
          }
        }
      }
    }
  }

  if (insertBreakLines) {
    const errorsFields = Object.keys(errors);
    for (let i = 0; i < errorsFields.length; i += 1) {
      const errorsField = errorsFields[i];
      if (errorsField === "form" || !errors[errorsField]) {
        continue;
      }
      const errorsWithBr = [];
      for (let j = 0; j < errors[errorsField].length; j += 1) {
        if (j > 0) {
          errorsWithBr.push(<br key={j} />);
        }
        errorsWithBr.push(errors[errorsField][j]);
      }
      errors[errorsField] = errorsWithBr;
    }
  }

  return errors;
};

export const injectTextIntoField = (field, text, smartInsert = true) => {
  if (!text || text.length === 0) {
    return;
  }

  const { maxLength } = field;

  //IE support
  if (document.selection) {
    field.focus();
    let sel = document.selection.createRange();
    sel.text = text;
  }
  //MOZILLA and others
  else if (field.selectionStart || field.selectionStart === 0) {
    let startPos = field.selectionStart;
    let endPos = field.selectionEnd;

    let insertText = text;
    if (smartInsert) {
      if (startPos === endPos) {
        const preChar =
          startPos > 0 ? field.value.substr(startPos - 1, 1) : null;
        const postChar =
          endPos < field.value.length ? field.value.substr(endPos, 1) : null;

        if (
          preChar !== null &&
          preChar !== "(" &&
          preChar !== "[" &&
          preChar !== "<" &&
          preChar !== " "
        ) {
          insertText = ` ${insertText}`;
        }
        if (
          postChar !== null &&
          postChar !== ")" &&
          postChar !== "]" &&
          postChar !== ">" &&
          postChar !== " "
        ) {
          insertText = `${insertText} `;
        }
      }
    }

    const newValue =
      field.value.substring(0, startPos) +
      insertText +
      field.value.substring(endPos, field.value.length);
    if (typeof maxLength !== "undefined" && newValue.length > maxLength) {
      field.focus();
      throw new Error("too-long");
    }

    field.value = newValue;
    field.setSelectionRange(
      startPos + insertText.length,
      startPos + insertText.length
    );
  } else {
    const newValue = field.value + (field.value.length ? " " : "") + text;
    if (typeof maxLength !== "undefined" && newValue.length > maxLength) {
      field.focus();
      throw new Error("too-long");
    }

    field.value += newValue;
  }
  field.focus();
};

export const sidebarSpaceAvailable = () => {
  // total height - ul(renderGroup items) height > bottom panel + addbutton height
  return document.body.clientHeight - 315 > 383;
};

let lastUsedNumbers = null;
let lastReturnedNumbers = null;
export const getOnlyNonPersonalNumbers = (numbers) => {
  if (numbers === lastUsedNumbers) {
    return lastReturnedNumbers;
  }

  const filteredNumbers = [];
  if (numbers) {
    for (let i = 0; i < numbers.length; i++) {
      if (numbers[i].src === 4) {
        continue;
      }
      filteredNumbers.push(numbers[i]);
    }
  }

  lastUsedNumbers = numbers;
  lastReturnedNumbers = filteredNumbers;
  return filteredNumbers;
};

let lastUsedNumbersForShortCode = null;
let lastReturnedShortcode = null;
export const getFirstShortCodeNumber = (numbers) => {
  if (numbers === lastUsedNumbersForShortCode) {
    return lastReturnedShortcode;
  }

  let shortCode = null;
  if (numbers) {
    for (let i = 0; i < numbers.length; i++) {
      if (numbers[i].src === 5) {
        shortCode = numbers[i].number;
        break;
      }
    }
  }

  lastUsedNumbersForShortCode = numbers;
  lastReturnedShortcode = shortCode;
  return lastReturnedShortcode;
};

export const getVerifiedTollFreeNumber = (numbers) => {
  if (!numbers) return;

  for (let number of numbers) {
    if (number.src === 6) return number.number;
  }
};

/**
 *
 * @param company
 * @param users
 * @param userId
 * @param number
 * @param useDefaultNumber - 0 - don't, 1 - only if not passed, 2 - always
 * @returns {{number: string, signaturePreview: string, userId: number}}
 */
export const getSignatureData = (
  company,
  users,
  userId,
  number,
  useDefaultNumber = 0
) => {
  let signaturePreview = "";
  let useUserId = 0;
  let useUser = null;
  let useNumber = "";

  if (userId > 0) {
    for (let i = 0; i < users.length; i += 1) {
      if (users[i].user.id === userId) {
        useUserId = userId;
        useUser = users[i];
        break;
      }
    }
  }

  if (useUser) {
    if (number !== "") {
      for (let i = 0; i < useUser.numbers.length; i += 1) {
        if (useUser.numbers[i].number === number) {
          useNumber = useUser.numbers[i].number;
          break;
        }
      }
    }
    if (
      useNumber === "" &&
      (useDefaultNumber === 2 || (useDefaultNumber === 1 && number === ""))
    ) {
      if (useDefaultNumber && useUser.numbers.length === 1) {
        useNumber = useUser.numbers[0].number;
      } else {
        for (let i = 0; i < useUser.numbers.length; i += 1) {
          if (useUser.numbers[i].is_default) {
            useNumber = useUser.numbers[i].number;
            break;
          }
        }
      }
    }
  }

  if (useUser && useNumber !== "") {
    const template = company.short_code_signature_normal_send;

    signaturePreview = template
      .replace("[User Primary Number]", useNumber)
      .replace("[Org Short Name]", company.short_name)
      .replace("[User Short Name]", useUser.user.short_name);
  }

  return {
    userId: useUserId,
    number: useNumber,
    signaturePreview,
  };
};

/**
 * Similar method is coded in API
 */
export const isGsmAlphabet = (text) => {
  const regexp = new RegExp(
    "^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$"
  );
  return regexp.test(text);
};

export const getNonGsmCharactersCount = (text) => {
  if (!text) return 0;
  const regexp = new RegExp(
    "[^A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]",
    "g"
  );
  const matches = text.match(regexp);
  return matches ? [...matches].length : 0;
};

export const getExtGsmCharactersCount = (text) => {
  if (!text) return 0;
  // Count characters: ~ ^ | \ [ ] { } €
  const regexp = new RegExp(
    "[\u007B\u007C\u007D\u007E\u005B\u005C\u005D\u005E\u20AC\\\\]",
    "g"
  );
  const matches = text.match(regexp);
  return matches ? [...matches].length : 0;
};

export const getCreditsCostAndStats = (
  text,
  unicodeEncoding,
  senderNumber,
  subscribersCount = 1,
  imagesAndVideos = [],
  withSegmentLogic = false
) => {
  if (!text) return 0;
  /** Similar calculations are coded in MessageTemplateModal.js and in API */
  const fullText = text;
  const extGsmCharactersCount = unicodeEncoding
    ? 0
    : getExtGsmCharactersCount(fullText);
  const characters = fullText.length + extGsmCharactersCount;
  let maxPerSegment = unicodeEncoding ? 70 : 160;
  if (characters > maxPerSegment && !withSegmentLogic) {
    maxPerSegment = unicodeEncoding ? 67 : 153;
  }
  const smsCount = Math.ceil(characters / maxPerSegment);
  const mmsCount = imagesAndVideos.length > 0 ? 1 : 0;
  const calculatedCredits =
    senderNumber && subscribersCount
      ? subscribersCount *
        ((mmsCount ? 0 : smsCount) * senderNumber.sms_cost +
          mmsCount * senderNumber.mms_cost)
      : 0;
  const tooLongWarning =
    characters > 1600 ? "Max characters count 1600 exceed." : null;
  return {
    calculatedCredits,
    characters,
    smsCount,
    mmsCount,
    tooLongWarning,
  };
};

export const isPastorsLineGroup = (groups = {}) => {
  if (!groups?.hasOwnProperty("id")) return;
  let groupClone = { ...groups };
  const validKeys = [
    "ccb_status",
    "is_breeze",
    "is_rock",
    "is_elvanto",
    "mc_status",
    "pco_status",
    "pco_workflow_id",
    "saved_search_id",
    "ccb_sync_queue_id",
  ];
  Object.keys(groupClone).forEach(
    (key) => validKeys.includes(key) || delete groupClone[key]
  );
  const isPastorsLineGroup = Object.values(groupClone).every(
    (el) => el === 0 || el === null
  );
  return isPastorsLineGroup;
};

export const isPastorsLineContact = (contact = {}) => {
  if (!contact?.hasOwnProperty("id")) return;
  let contactClone = { ...contact };
  const validKeys = [
    "is_ccb",
    "is_pco",
    "is_mc",
    "is_breeze",
    "is_rock",
    "is_elvanto",
  ];
  Object.keys(contactClone).forEach(
    (key) => validKeys.includes(key) || delete contactClone[key]
  );
  const isPastorsLineContact = Object.values(contactClone).every(
    (el) => el === 0 || el === null
  );
  return isPastorsLineContact;
};

export const isAllPastorsLineGroups = (selectedThreads = {}) => {
  let cloneObj = Object.assign({}, selectedThreads);
  const allSelectedGroups = Object.values(cloneObj);
  const removeKeys = allSelectedGroups.map(
    ({
      ccb_status,
      is_breeze,
      is_rock,
      is_elvanto,
      mc_status,
      pco_status,
      pco_workflow_id,
      saved_search_id,
      ccb_sync_queue_id,
      ...rest
    }) => ({
      ccb_status,
      is_breeze,
      is_rock,
      is_elvanto,
      mc_status,
      pco_status,
      pco_workflow_id,
      saved_search_id,
      ccb_sync_queue_id,
    })
  );
  const isPastorsLineGroup = removeKeys.every((item, index) =>
    Object.values(removeKeys[index]).every((el) => el === 0 || el === null)
  );
  return isPastorsLineGroup;
};

export const getNumberOfContactsFromGroups = (groupIds, groupsData) => {
  let noContacts = 0;
  for (let id of groupIds) {
    noContacts += groupsData[id].members_count;
  }

  return noContacts;
};

export const getNumberOfContacts = (
  contactIds,
  groupIds,
  contactsData,
  groupsData
) => {
  let numberOfContacts = 0;

  if (groupIds.length > 1 && contactIds.length === 0) {
    numberOfContacts = getNumberOfContactsFromGroups(groupIds, groupsData);
  } else if (groupIds.length === 0 && contactIds.length > 1) {
    numberOfContacts = contactIds.length;
  } else if (contactIds.length >= 1 && groupIds.length >= 1) {
    numberOfContacts =
      getNumberOfContactsFromGroups(groupIds, groupsData) + contactIds.length;
  }

  return numberOfContacts;
};

export const isEmpty = (value) => {
  return value === null || value === 0 || value === undefined;
};

export const isEligibleForSync = (thread) => {
  return (
    !isEmpty(thread.ccb_status) ||
    !isEmpty(thread.is_elvanto) ||
    !isEmpty(thread.pco_status) ||
    !isEmpty(thread.mc_status) ||
    !isEmpty(thread.is_breeze) ||
    !isEmpty(thread.is_rock) ||
    (thread.ccb_queue && thread.ccb_queue?.is_sync === 0)
  );
};

export const groupName = (group, withShortAdhocName) => {
  let adHocName = null;

  if (group?.group_name && group?.adhoc_name == null) {
    return group.group_name;
  } else if (group?.adhoc_name && group?.adhoc_name.length > 0) {
    adHocName = group.adhoc_name;
  } else if (group?.adhoc_name && group?.adhoc_name.length === 0) {
    return "Unnamed/Empty";
  } else {
    return group ? "Unnamed/Empty" : "Unknown";
  }

  if (adHocName && withShortAdhocName) {
    adHocName =
      adHocName.length > 20 ? `${adHocName.substring(0, 20)}...` : adHocName;
  }

  return adHocName;
};

export const tagName = (group) => {
  let name = "Unknown";
  if (!!group) {
    if (group.tag_name) {
      name = group.tag_name;
    } else if (!!group.tag && group.tag.tag_name) {
      name = group.tag.tag_name;
    }
  }
  if (name) {
    name = name.length > 20 ? `${name.substring(0, 20)}...` : name;
  }
  return name;
};

export const getNameBy = (
  contactIds = [],
  groupIds = [],
  contactsData,
  groupsData
) => {
  let name = "...";

  if (contactIds.length === 1 && groupIds.length === 0) {
    const contactData = contactsData[contactIds[0]] || {};
    name = contactData
      ? contactName(
          contactData?.first_name,
          contactData?.last_name,
          contactData?.phone_number
        )
      : "";
  } else if (contactIds.length === 0 && groupIds.length === 1) {
    name = groupName(groupsData[groupIds[0]]);
  } else if (contactIds.length >= 1 || groupIds.length >= 1) {
    name = getNumberOfContacts(contactIds, groupIds, contactsData, groupsData);
  }

  return name;
};

const arrayToStringForModals = (array, name, number) => {
  let text = "";
  for (let item of array) {
    if (typeof item !== "string") {
      text = `${text}${
        item.addName ? name : item.addNumberGroup ? number : ""
      }`;
    } else {
      text = `${text}${item}`;
    }
  }
  return text;
};

export const formatTextForModalSnackbar = (
  contactIds,
  groupIds,
  contactsData,
  groupsData,
  singleContactTexts,
  singleGroupTexts,
  multipleContactsTexts,
  multipleGroupsTexts,
  customMessage,
  isGroupAllMembersSelected,
  sourceGroupMemberCount,
  isGroupTag
) => {
  let title = "";
  const contactNameStr = getNameBy(
    contactIds,
    groupIds,
    contactsData,
    groupsData
  );
  const numberOfContactsInGroup = getNumberOfContactsFromGroups(
    groupIds,
    groupsData
  );

  if (contactIds.length === 1 && groupIds.length === 0) {
    title = arrayToStringForModals(
      singleContactTexts,
      contactNameStr,
      numberOfContactsInGroup
    );
  } else if (contactIds.length === 0 && groupIds.length === 1) {
    title = arrayToStringForModals(
      singleGroupTexts,
      contactNameStr,
      numberOfContactsInGroup
    );
  } else if (contactIds.length > 1 && groupIds.length === 0) {
    title = arrayToStringForModals(
      multipleContactsTexts,
      isGroupAllMembersSelected ? sourceGroupMemberCount : contactNameStr,
      numberOfContactsInGroup
    );
  } else if (contactIds.length === 0 && groupIds.length > 1) {
    title = arrayToStringForModals(
      multipleGroupsTexts,
      contactNameStr,
      isGroupTag ? groupIds : numberOfContactsInGroup
    );
  } else if (contactIds.length >= 1 && groupIds.length >= 1) {
    title = arrayToStringForModals(
      multipleContactsTexts,
      contactNameStr,
      numberOfContactsInGroup
    );
  }

  if (customMessage) {
    return customMessage;
  }

  return title;
};

export const getNumberOfContactsForGroups = (groupIds, groupData) => {
  let sum = 0;

  for (let id of groupIds) {
    sum += groupData[id].members_count;
  }

  return sum;
};

const getThreadActionStatus = (number, firstCheck, secondCheck) => {
  return number > 1 ? firstCheck && secondCheck : secondCheck;
};

export const updateThreadActionStatusesOnDeselection = (
  selectedThreads,
  groupsData
) => {
  let isUnsubscribed = true,
    isBlocked = true,
    isFavorite = true,
    allowCalls = true,
    isArchived = true,
    eligibleForSync = false;
  const keys = Object.keys(selectedThreads);

  for (let key of keys) {
    if (selectedThreads[key]?.item_key?.substr(0, 7) === CONTACT) {
      const contact = selectedThreads[key].contact
        ? selectedThreads[key].contact
        : selectedThreads[key];
      isUnsubscribed = getThreadActionStatus(
        keys.length,
        isUnsubscribed,
        contact.status === 3
      );
      isBlocked = getThreadActionStatus(
        keys.length,
        isBlocked,
        contact.status === 1
      );
      isFavorite = getThreadActionStatus(
        keys.length,
        isFavorite,
        !!contact.is_favorite || !!contact.is_number_favorite
      );
      allowCalls = getThreadActionStatus(
        keys.length,
        allowCalls,
        contact.do_not_call > 0
      );
      eligibleForSync = false;
      isArchived = getThreadActionStatus(
        keys.length,
        isArchived,
        contact.is_archived === 1
      );
    } else if (selectedThreads[key]?.item_key?.substr(0, 5) === GROUP) {
      const thread = selectedThreads[key];
      const groupId =
        thread.group && thread.group.id ? thread.group.id : thread.id; // if is in the group tab, then a thread is a group

      isUnsubscribed = getThreadActionStatus(
        keys.length,
        isBlocked,
        groupsData[groupId].all_unsubscribed
      );
      isBlocked = getThreadActionStatus(
        keys.length,
        isBlocked,
        groupsData[groupId].all_blocked
      );
      isFavorite = getThreadActionStatus(
        keys.length,
        isFavorite,
        !!groupsData[groupId].is_favorite
      );
      allowCalls = false;
      eligibleForSync =
        keys.length === 1 ? isEligibleForSync(groupsData[groupId]) : false;
      isArchived = getThreadActionStatus(
        keys.length,
        isArchived,
        groupsData[groupId].is_archived === 1
      );
    }
  }

  return {
    isUnsubscribed,
    isBlocked,
    isFavorite,
    allowCalls,
    eligibleForSync,
    isArchived,
  };
};

export const updateThreadActionStatusesOnSelection = (
  thread,
  contacts,
  numberOfSelectedThreads,
  groupsData,
  state
) => {
  let {
    isUnsubscribed,
    isFavorite,
    isBlocked,
    allowCalls,
    eligibleForSync,
    isArchived,
  } = state;
  const groupId = thread.group && thread.group.id ? thread.group.id : thread.id; // if is in the group tab, then a thread is a group

  if (contacts) {
    const status = contacts.status;

    isUnsubscribed = getThreadActionStatus(
      numberOfSelectedThreads,
      isUnsubscribed,
      status === 3
    );
    isBlocked = getThreadActionStatus(
      numberOfSelectedThreads,
      isBlocked,
      status === 1
    );
    isFavorite = getThreadActionStatus(
      numberOfSelectedThreads,
      isFavorite,
      !!contacts.is_favorite
    );
    allowCalls = getThreadActionStatus(
      numberOfSelectedThreads,
      allowCalls,
      contacts.do_not_call > 0
    );
    eligibleForSync = false;
    isArchived = getThreadActionStatus(
      numberOfSelectedThreads,
      isArchived,
      contacts.is_archived === 1
    );
  } else {
    isUnsubscribed = getThreadActionStatus(
      numberOfSelectedThreads,
      isBlocked,
      groupsData[groupId]?.all_unsubscribed
    );
    isBlocked = getThreadActionStatus(
      numberOfSelectedThreads,
      isBlocked,
      groupsData[groupId].all_blocked
    );
    isFavorite = getThreadActionStatus(
      numberOfSelectedThreads,
      isFavorite,
      !!groupsData[groupId].is_favorite
    );
    allowCalls = false;
    eligibleForSync =
      numberOfSelectedThreads === 1
        ? isEligibleForSync(groupsData[groupId])
        : false;
    isArchived = getThreadActionStatus(
      numberOfSelectedThreads,
      isArchived,
      groupsData[groupId].is_archived === 1
    );
  }

  return {
    isUnsubscribed,
    isFavorite,
    isBlocked,
    allowCalls,
    eligibleForSync,
    isArchived,
  };
};

//change people sort options for API
export const changePeopleSortKeys = (key) => {
  switch (key) {
    case "firstNameAsc":
      return "first_name asc";
    case "firstNameDesc":
      return "first_name desc";
    case "lastNameAsc":
      return "last_name asc";
    case "lastNameDesc":
      return "last_name desc";
    case "newest":
      return "id desc";
    case "oldest":
      return "id asc";
    default:
      return "first_name asc, last_name asc, favorite desc, id asc";
  }
};

export const setUnsubscribeContact = (contact) => {
  if (contact) {
    contact.status = contact.status === 1 ? 1 : 3;
    contact.unsub_by_user_id = 1; // better to do it in other way
  }
};

export const setSubscribeContact = (contact) => {
  if (contact) {
    contact.status = contact.status === 1 ? 1 : 0;
    contact.unsub_by_user_id =
      contact.status === 1 ? contact.unsub_by_user_id : 0;
  }
};

export const setBlockedContact = (contact) => {
  if (contact) {
    contact.status = 1;
    contact.unsub_by_user_id = 1;
  }
};

export const setUnlockedContact = (contact) => {
  if (contact) {
    contact.status = 0;
    contact.unsub_by_user_id = 0;
  }
};

export const setDoNotCallContact = (contact) => {
  if (contact) {
    contact.do_not_call = 1;
  }
};

export const setResetDoNotCallContact = (contact) => {
  if (contact) {
    contact.do_not_call = 0;
  }
};
export const setMarkAsFavoriteContact = (contact) => {
  if (contact) {
    contact.is_favorite = 1;
    contact.is_number_favorite = 1;
  }
};

export const setMarkAsUnfavoriteContact = (contact) => {
  if (contact) {
    contact.is_favorite = 0;
    contact.is_number_favorite = 0;
  }
};
export const toggleMarkAsFavoriteGroup = (group) => {
  if (group) {
    group.is_favorite = group.is_favorite === 1 ? 0 : 1;
  }
};

//change group sort options for API
export const changeGroupSortKeys = (key) => {
  switch (key) {
    case "groupNameAsc":
      return "group_name asc,favorite desc,id asc";
    case "groupNameDesc":
      return "group_name desc,favorite desc,id desc";
    case "newest":
      return "id desc";
    case "oldest":
      return "id asc";
    default:
      return "group_name asc,favorite desc,id asc";
  }
};

export const changeTagSortKeys = (key) => {
  switch (key) {
    case "tagNameAsc":
      return "group_name asc,favorite desc,id asc";
    case "tagNameDesc":
      return "group_name desc,favorite desc,id desc";
    case "newest":
      return "id desc";
    case "oldest":
      return "id asc";
    default:
      return "group_name asc,favorite desc,id asc";
  }
};

export const getGroupIcon = (groupData, className = undefined) => {
  let integrationIcon;
  if (groupData) {
    if (!!groupData.ccb_status) {
      integrationIcon = (
        <CcbSvg
          width={16}
          className={className === undefined ? "ml-2" : className}
        />
      );
    } else if (!!groupData.pco_status) {
      integrationIcon = (
        <PcoSvg
          width={16}
          className={className === undefined ? "ml-2" : className}
        />
      );
    } else if (!!groupData.mc_status) {
      integrationIcon = (
        <McSvg
          width={16}
          className={className === undefined ? "ml-2" : className}
        />
      );
    } else if (!!groupData.is_elvanto) {
      integrationIcon = <ElvantoSvg width={16} className={"ml-2"} />;
    } else if (!!groupData.is_breeze) {
      integrationIcon = <BreezeSvg width={12} className={"ml-2"} />;
    } else if (!!groupData.is_rock) {
      integrationIcon = (
        <RockRmsSvg width={18} height={15} className={"ml-2"} />
      );
    } else {
      integrationIcon = (
        <PLIconDarkSvg
          width={16}
          className={className === undefined ? "ml-2" : className}
        />
      );
    }
  }
  return integrationIcon;
};

export const getAmcIcon = (group) => {
  let amcIcon = null;
  if (group.is_automessage && group.autoresponder_auto_message_id) {
    amcIcon = <GroupsAddingAmcIcon className="ml-1" />;
  }
  return amcIcon;
};

export const getDetailsGroup = (group) => {
  return (
    <>
      <div className="icon-details">
        {getGroupIcon(group, "")}
        {getAmcIcon(group)}
      </div>
    </>
  );
};

export const getIntegrationIconGroup = (group) => {
  return (
    <>
      <div className="icon-details">
        {(group.integration === "ccb_group" ||
          group.integration === "ccb_saved_search" ||
          group.integration === "ccb_process_queue") && (
          <CcbSvg className="user-icon" width={16} />
        )}
        {(group.integration === "pco_list" ||
          group.integration === "pco_workflow") && (
          <PcoSvg className="user-icon" width={16} />
        )}
        {group.integration === "mc_list" && (
          <McSvg className="user-icon" width={16} />
        )}
        {group.integration === "elvanto_group" && (
          <ElvantoSvg className="user-icon" width={16} />
        )}
        {(group.integration === "breeze_tag" ||
          group.integration === "breeze_event") && (
          <BreezeSvg className="user-icon" width={16} />
        )}
        {group.integration === "breeze_event" && (
          <BreezeSvg className="user-icon" width={16} />
        )}
        {group.integration?.startsWith(ROCK) && (
          <RockRmsSvg width={16} height={16} />
        )}
      </div>
    </>
  );
};

export const range = (start, end, mapFunction = (_, idx) => start + idx) => {
  return Array(end - start + 1)
    .fill()
    .map(mapFunction);
};

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 11,
    border: "1px solid #acacac",
    padding: "5px 8px",
    borderRadius: "0.25rem",
    maxWidth: 200,
    position: "left",
  },
  popper: {
    zIndex: 999999,
  },
  popperArrow: {
    '&[x-placement*="bottom"] .MuiTooltip-arrow': {
      background: theme.palette.common.white,
      borderTop: "1px solid #acacac",
      borderLeft: "1px solid #acacac",
      width: "1.2em",
      height: "1.2em",
    },
    '&[x-placement*="top"] .MuiTooltip-arrow': {
      background: theme.palette.common.white,
      borderRight: "1px solid #acacac",
      borderBottom: "1px solid #acacac",
      width: "1.2em",
      height: "1.2em",
    },
    '&[x-placement*="left"] .MuiTooltip-arrow': {
      background: "#fff",
      borderTop: "1px solid #acacac",
      borderRight: "1px solid #acacac",
      width: "1.25em",
      height: "1.25em",
    },
    '&[x-placement*="right"] .MuiTooltip-arrow': {
      background: "#fff",
      borderBottom: "1px solid #acacac",
      borderLeft: "1px solid #acacac",
      width: "1.25em",
      height: "1.25em",
    },
  },
  arrow: {
    color: theme.palette.common.white,
    background: "#fff",
    transform: "rotate(45deg)",
    "&:before": {
      display: "none",
    },
  },
}))(Tooltip);

export const mapSortTypeToApiType = (sortType) => {
  if (sortType === "up") {
    return `asc`;
  } else if (sortType === "down") {
    return `desc`;
  }
  return "";
};

export const onAddRemoveObjects = (
  obj,
  selectedObjectsToAdd,
  selectedObjectsToRemove,
  defaultSelectedObjects,
  mapObjectId
) => {
  let mapObjectToId = () => obj.id;
  if (mapObjectId) {
    mapObjectToId = mapObjectId;
  }
  const objectsToAdd = { ...selectedObjectsToAdd };
  const objectsToRemove = { ...selectedObjectsToRemove };
  const selectedObjects = { ...defaultSelectedObjects };

  if (selectedObjects[mapObjectToId()] && !objectsToRemove[mapObjectToId()]) {
    objectsToRemove[mapObjectToId()] = obj;
    delete selectedObjects[mapObjectToId()];
  } else if (
    objectsToRemove[mapObjectToId()] &&
    !selectedObjects[mapObjectToId()]
  ) {
    selectedObjects[mapObjectToId()] = obj;
    delete objectsToRemove[mapObjectToId()];
  } else if (
    !selectedObjects[mapObjectToId()] &&
    !objectsToAdd[mapObjectToId()]
  ) {
    objectsToAdd[mapObjectToId()] = obj;
  } else if (objectsToAdd[mapObjectToId()]) {
    delete objectsToAdd[mapObjectToId()];
  }

  return {
    objectsToAdd,
    objectsToRemove,
    selectedObjects,
  };
};

export const getComposeContactsAndGroupsIds = () => {
  const storage = { ...localStorage };
  const filterDrafts = {};
  for (const [key, value] of Object.entries(storage)) {
    if (key.startsWith(DRAFTS_LOCAL_STORAGE_PREFIX)) {
      const parseValues = JSON.parse(value);
      if (
        Boolean(parseValues.messageText) ||
        Boolean(parseValues.images?.length) ||
        Boolean(parseValues.videos?.length) ||
        Boolean(parseValues?.vCards?.length) ||
        Boolean(parseValues.sendOn) ||
        Boolean(parseValues.sendOnFormatted)
      ) {
        filterDrafts[key] = value;
      } else {
        localStorage.removeItem(key);
      }
    }
  }
  const storageKeys = Object.keys(filterDrafts).filter((key) =>
    key.startsWith(DRAFTS_LOCAL_STORAGE_PREFIX)
  );
  const contacts = [],
    groups = [];
  for (const key of storageKeys) {
    const keyParsed = key.split("-");
    if (keyParsed.length === 3) {
      if (keyParsed[1] === CONTACT && containsOnlyNumbers(keyParsed[2])) {
        contacts.push(keyParsed[2]);
      } else if (keyParsed[1] === GROUP && containsOnlyNumbers(keyParsed[2])) {
        groups.push(keyParsed[2]);
      }
    }
  }
  return { contacts, groups };
};

export const getFontWeightBoldClassBasedOn = (bool) => {
  if (bool) {
    return "font-weight-bold";
  }
  return "";
};

export const joinKeyValueAndJoinResultOf = (
  object,
  joinKeyValueString = " ",
  joinString = ", "
) => {
  const keys = Object.keys(object);

  if (keys.length === 1 && object[keys[0]]) {
    const sortType = object[keys[0]]?.sortType || object[keys[0]];
    return `${keys[0]}${joinKeyValueString}${sortType}`;
  }

  keys.sort((a, b) => (object[b]?.priority || 0) - (object[a]?.priority || 0));
  let joinedString = "";

  for (let i = 0; i < keys.length; i++) {
    const prop = keys[i];
    const sortType = object[prop]?.sortType || object[prop];

    if (i + 1 === keys.length && object[prop]) {
      joinedString = `${joinedString}${prop}${joinKeyValueString}${sortType}`;
    } else if (object[prop]) {
      joinedString = `${joinedString}${prop}${joinKeyValueString}${sortType}${joinString}`;
    }
  }
  return joinedString;
};

export const getContactsDataForMultiContact = (contactData, contacts) => {
  const contactsData = [];

  for (let i = 0; i < contactData.contacts_ids.length; i++) {
    if (typeof contacts[contactData.contacts_ids[i]] !== "undefined") {
      contactsData.push(contacts[contactData.contacts_ids[i]]);
    }
  }

  return contactsData;
};

export const getNumberLookUpInfo = (contactData) => {
  let numberLookupInfo = "";

  if (
    contactData &&
    contactData.number_lookup &&
    contactData.number_lookup.type === null
  ) {
    numberLookupInfo = "Number lookup completed. Format Error.";
  } else if (contactData && contactData.number_lookup) {
    const lookupData = contactData.number_lookup.data
      ? JSON.parse(contactData.number_lookup.data)
      : {
          carrier: { name: "" },
        };
    numberLookupInfo = lookupData.carrier.name;
  }

  return numberLookupInfo;
};

export const getNumberLookUpIcon = (contactData) => {
  if (!contactData) return null;

  if (contactData.number_lookup && contactData.number_lookup.type === null) {
    return (
      <React.Fragment>
        <AnchorWithTooltip
          href="#void"
          className="ml-1"
          placement="top"
          title="Unchecked"
        >
          <SearchDottedIcon />
        </AnchorWithTooltip>
      </React.Fragment>
    );
  } else if (
    contactData.number_lookup &&
    contactData.number_lookup.type === "mobile"
  ) {
    return (
      <AnchorWithTooltip
        href="#void"
        className="ml-1"
        placement="top"
        title="Mobile"
      >
        <MobileIcon width={13} />
      </AnchorWithTooltip>
    );
  } else if (
    contactData.number_lookup &&
    contactData.number_lookup.type === "landline"
  ) {
    return (
      <AnchorWithTooltip
        href="#void"
        className="ml-1"
        placement="top"
        title="Landline"
      >
        <LandlineIcon width={13} />
      </AnchorWithTooltip>
    );
  } else if (
    contactData.number_lookup &&
    contactData.number_lookup.type === "voip"
  ) {
    return (
      <AnchorWithTooltip
        href="#void"
        className="ml-1"
        placement="top"
        title="VOIP"
      >
        <VoipIcon width={13} />
      </AnchorWithTooltip>
    );
  }
};

export const getContactsByIntegration = (contactsData) => {
  const contactsByIntegration = {
    no: [],
    ccb: [],
    pco: [],
    mc: [],
    elvanto: [],
    breeze: [],
  };

  let isFavorite = false;

  for (let i = 0; i < contactsData.length; i++) {
    if (contactsData[i].is_favorite || contactsData[i].is_number_favorite) {
      isFavorite = true;
    }

    let key = "no";
    if (!!contactsData[i].is_ccb) {
      key = "ccb";
    } else if (!!contactsData[i].is_pco) {
      key = "pco";
    } else if (!!contactsData[i].is_mc) {
      key = "mc";
    } else if (!!contactsData[i].is_elvanto) {
      key = "elvanto";
    } else if (!!contactsData[i].is_breeze) {
      key = "breeze";
    } else if (!!contactsData[i].is_rock) {
      key = "rock";
    }

    if (contactsByIntegration[key]) {
      if (key === "no" || !contactsData[i].is_primary) {
        contactsByIntegration[key].push(contactsData[i]);
      } else {
        contactsByIntegration[key].unshift(contactsData[i]);
      }
    }
  }

  return {
    contactsByIntegration,
    isFavorite,
  };
};

export const getPhoneCode = (contact) => {
  return contact.country ? "" + contact.country.phonecode : "";
};

export const updateCountryCode = (phoneNumber, selectedCountry) => {
  const { countries, areaCodes } = store.getState().countries;
  const selectedCountryObject = countries.find(
    (country) => country.id === Number(selectedCountry)
  );
  const selectedCountryAreaCodes = areaCodes[selectedCountryObject?.phonecode];
  const areaFound =
    selectedCountryAreaCodes &&
    Object.entries(selectedCountryAreaCodes).find(
      (areas) => !!areas[1].find((area) => phoneNumber.startsWith(area))
    );
  const [countryCode] = areaFound || [];
  return countryCode;
};

export const truncateString = (str, n) => {
  return str?.length > n ? str.substr(0, n - 1) + "..." : str;
};

export const increaseDecreaseBy = (
  number,
  isIncreasing,
  numberToIncreaseDecrease
) => {
  let increaseDecreaseNumber;
  if (numberToIncreaseDecrease == null) {
    increaseDecreaseNumber = 1;
  } else {
    increaseDecreaseNumber = numberToIncreaseDecrease;
  }

  if (number && isIncreasing) {
    return number + increaseDecreaseNumber;
  } else if (number && number > 0 && !isIncreasing) {
    return number - increaseDecreaseNumber;
  } else if (number && number === 0 && !isIncreasing) {
    return 0;
  }
  return null;
};

export const birthDayValidation = (birthday) => {
  if (birthday.day && birthday.month && birthday.year) {
    return "";
  }
  return "Please select at least a day and a month";
};

export const getDuplicateViewInfo = (contactsData) => {
  let notArchivedCounter = 0;
  let hasArchivedContacts = false;
  let singleUnArchivedContact = null;

  if (contactsData == null) return {};

  for (const contactData of contactsData) {
    if (contactData.is_archived === 0) {
      notArchivedCounter += 1;
      singleUnArchivedContact = contactData;
    } else if (contactData.is_archived === 1) {
      hasArchivedContacts = true;
    }
    if (notArchivedCounter > 1) {
      singleUnArchivedContact = null;
    }
  }
  return {
    isSingleMemberDuplicateRestArchived: notArchivedCounter === 1,
    hasArchivedContacts,
    singleUnArchivedContact,
  };
};

const expandMainNav = (element) => {
  document.getElementById(element.id).classList.add("show");
  document
    .getElementById(element.parent_id)
    .setAttribute("aria-expanded", "true");
};

const expandGroupNav = (group) => {
  document.getElementById(group.id).classList.add("show");
  document
    .getElementById(group.parent_id)
    .setAttribute("aria-expanded", "true");
};

export const onLoadNavigatorSideMenu = (filter = null, menus = []) => {
  menus.forEach((element) => {
    let found = !!element?.items?.find((item) => item === filter);
    if (found || element.defaultHidden === false) {
      expandMainNav(element);
    } else {
      if (element?.groups) {
        element.groups.forEach((group) => {
          found = !!group.items.find((item) => item === filter);
          if (found) {
            expandMainNav(element);
            expandGroupNav(group);
          } else if (group?.groups) {
            group.groups.forEach((nestedGroup) => {
              found = !!nestedGroup.items.find((item) => item === filter);
              if (found) {
                expandMainNav(element);
                expandGroupNav(group);
                expandGroupNav(nestedGroup);
              }
            });
          }
        });
      }
    }
  });
};

export const refreshCounts = (companyId) => {
  const [, , tab] = history.location.pathname.split("/");
  let countAction;
  const numbersFilter = store.getState().numbers?.numbersFilter || [];

  if (tab === "groups") {
    countAction = fetchGroupCounts(companyId, true);
  } else if (tab === "messages") {
    countAction = fetchThreadsCounts(companyId, numbersFilter);
  } else if (tab === "calls") {
    countAction = fetchVoiceCounts(companyId, numbersFilter);
  } else if (tab === "people") {
    countAction = fetchPeopleCounts(companyId);
  } else {
    countAction = () => {};
  }

  return countAction;
};

export const getTruncatedHeaderLabel = (header) => {
  return `...  ${header.slice(header.lastIndexOf(">"))}`;
};

export const validateNumber = (value) => {
  var numReg = /^[0-9]+$/;
  return value.match(numReg) ? true : false;
};

export const spliceNonNumericCharacters = (string) => {
  return string.replace(/[^0-9]+/g, "");
};

export const removeSpacesFromText = (params = "") => {
  return params.replace(/\s/g, "");
};

export const containsOnlyNumbers = (str = "") => {
  return /^\d+$/.test(str);
};

export const getThreadName = (threadObj) => {
  let name = "";
  const isGroup = typeof threadObj.group_name !== "undefined";
  if (isGroup) {
    name = threadObj.group_name;
  } else if (threadObj.first_name) {
    name = contactName(
      threadObj.first_name,
      threadObj.last_name,
      "" +
        (threadObj.country ? threadObj.country.phonecode : "") +
        threadObj.phone_number
    );
  } else {
    name = threadObj.phone_number;
  }
  return name;
};

export const filterGroupAndContactThreads = (allThreads) => {
  if (allThreads.length) {
    const filterPeople = allThreads.filter((item) =>
      item.item_key.startsWith(CONTACT)
    );
    const filterGroups = allThreads.filter((item) =>
      item.item_key.startsWith(GROUP)
    );
    return {
      people: filterPeople,
      groups: filterGroups,
    };
  }

  return {
    people: [],
    groups: [],
  };
};

export const displayMessageFilterAndCondtion = (currentFilter) => {
  switch (currentFilter) {
    case "favorites":
      return "is:favorites";
    case "unread":
      return `is:${currentFilter}`;
    case "received":
      return `in:${currentFilter}`;
    case "sent-contacts":
      return `in:sent in:people`;
    case "sent-groups":
      return "in:sent in:groups";
    case "sent-reprocessed":
      return "in:sent is:reprocessed";
    case "drafts":
      return `is:${currentFilter}`;
    case "scheduled-contacts":
      return "is:scheduled in:people";
    case "scheduled-groups":
      return "is:scheduled in:group";
    case "scheduled-groups-named":
      return "is:scheduled in:groups-named";
    case "scheduled-groups-unnamed":
      return "is:scheduled in:groups-unnamed";
    case "undelivered-unread":
      return "in:undelivered is:unseen";
    case "undelivered-read":
      return "in:undelivered is:seen";
    case "sent-reprocessed-unread":
      return "is:reprocessed is:unseen";
    case "sent-reprocessed-read":
      return "is:reprocessed is:seen";
    case "campaigns-am":
      return "in:campains-AM";
    case "campaigns-auto-replies":
      return `in:${currentFilter}`;
    case "campaigns-birthdays":
      return `in:${currentFilter}`;
    case "campaigns-contests":
      return `in:${currentFilter}`;
    case "campaigns-dc":
      return `in:${currentFilter}`;
    case "campaigns-polls":
      return `in:${currentFilter}`;
    case "unsubscribed-text":
      return "in:texts is:unsubscribed";
    case "opted-out":
      return "in:texts is:opted-out";
    case "unsubscribed-voice":
      return "in:voice is:unsubribed";
    case "do-not-call":
      return "in:voice is:do_not_call";
    case "mc-subscribed":
      return "in:mailchimp is:subscribed";
    case "mc-unsubscribed":
      return "n:mailchimp is:unsubscribed";
    case "mc-non-subscribed":
      return "in:mailchimp is:non-subscribed";
    case "mc-cleaned":
      return "in:mailchimp is:cleaned";
    case "blocked":
      return `is:${currentFilter}`;
    case "closed":
      return `in:${currentFilter}`;
    default:
      return "messages";
  }
};

export const highlight = (msgText, searchString) => {
  let content = JSON.stringify(msgText);
  const searchTerm = removeSpecialCharactersExceptSpaces(searchString.trim());
  if (!searchTerm) {
    return msgText;
  } else {
    const result = transformContent(content, searchTerm);
    return JSON.parse(result);
  }
};

const transformContent = (content, keywords) => {
  let temp = content;
  temp = temp.replace(
    new RegExp(keywords, "ig"),
    wrapKeywordWithHTML(keywords)
  );
  return temp;
};

const wrapKeywordWithHTML = (keyword) => {
  return `<mark class='highlightText'>${keyword}</mark>`;
};

export const createPhoneItemKey = (contactData) => {
  if (!contactData) return "";
  return `contact-p${contactData.country ? contactData.country.phonecode : ""}${
    contactData.phone_number
  }`;
};

export const doGroupMembersContainDuplicates = (
  groupKeys,
  groupMembers,
  contactsData
) => {
  for (let groupKey of groupKeys) {
    if (!groupMembers[groupKey]) return false;
    for (let contactId of groupMembers[groupKey]) {
      if (contactsData[contactId].has_duplicate === 1) {
        return true;
      }
    }
  }
  return false;
};

export const doSelectedThreadsContainDuplicates = (selectedThreads) => {
  for (let threadKey of Object.keys(selectedThreads)) {
    if (
      selectedThreads[threadKey].contacts_ids?.length > 1 &&
      !selectedThreads[threadKey]?.is_archived
    ) {
      return true;
    }
  }
  return false;
};

export const getTitleBasedOnContactStatus = ({
  isContactArchived,
  isContactBlocked,
}) => {
  if (isContactArchived) {
    return "Can't select or deselect if in archived state";
  } else if (isContactBlocked) {
    return "Can't select or deselect if in blocked state";
  }
  return "";
};

export const getSearchFilterForMessages = (threadSearchCondition, filter) => {
  let filterParams;
  if (threadSearchCondition && !filter) {
    if (threadSearchCondition === "archived") {
      filterParams = null;
    } else {
      filterParams = threadSearchCondition;
    }
  }
  return filterParams;
};

export const getMessageType = (log) => {
  let itemType = "";
  if (log.is_email === 1) {
    itemType = "email";
  } else if (log.msg_type === "text") {
    itemType = "text";
  } else {
    if (log.msg_type === "voice" && log.duration > 0) {
      itemType = "voicemail";
    } else if (log.msg_type === "broadcast") {
      itemType = "broadcast";
    } else if (
      (log.msg_type === "voice" || log.msg_type === "called") &&
      log.duration === 0
    ) {
      itemType = "call-missed";
    } else if (log.msg_type === "callforward") {
      itemType = "call-forwarded";
    } else {
      itemType = "call-connected";
    }
  }
  return itemType;
};

export const getUndeliveredContactIds = (allThreads = {}) => {
  let undeliveredContactIds = [];
  for (const key in allThreads) {
    const thread = allThreads[key];
    if (
      thread.item_key.substr(0, 7) === CONTACT &&
      thread.contacts_ids &&
      thread.contacts_ids.length
    ) {
      if (thread.undelivered_count > 0) {
        undeliveredContactIds.push(thread?.contacts_ids[0]);
      }
    }
  }
  return undeliveredContactIds;
};

export const getReprocessedContactIds = (allThreads = {}) => {
  let reprocessedContactIds = [];
  for (const key in allThreads) {
    const thread = allThreads[key];
    if (
      thread.item_key.substr(0, 7) === CONTACT &&
      thread.contacts_ids &&
      thread.contacts_ids.length
    ) {
      if (thread.reprocessed_unread_count > 0) {
        reprocessedContactIds.push(thread?.contacts_ids[0]);
      }
    }
  }
  return reprocessedContactIds;
};

export const softDeleteContactsMapper = (payloadContactIds, actionName) => {
  return (contactId) => {
    if (
      payloadContactIds.some(
        (payloadId) => Number(payloadId) === Number(contactId)
      )
    ) {
      return { id: contactId, actionName };
    } else return contactId;
  };
};

export const restoreDeletedContactsMapper = (actionName) => {
  return (contactId) => {
    return typeof contactId === "object" && contactId?.actionName === actionName
      ? contactId.id
      : contactId;
  };
};

export const isAlphabetFound = (num) => {
  return num.match(/[a-zA-Z]+/g);
};

export const serializePhoneNumber = (num) => {
  const numTrimmed = num.trim();

  if (!isAlphabetFound(numTrimmed)) {
    const updatedNumber = removeSpecialCharacters(numTrimmed);

    return removeLeadingZeroAndOne(updatedNumber);
  }

  return numTrimmed;
};

export const removeLeadingZeroAndOne = (num) => {
  return num.replace(/^[+0-1]+/g, "");
};

export const removeSpecialCharacters = (num) => {
  return num.replace(/[^a-zA-Z0-9]/g, "");
};

export const removeSpecialCharactersExceptSpaces = (str) => {
  return str.replace(/[^a-zA-Z ]/g, "");
};

export const saveRecentSearchInLocalStorage = (query, storageIdentity) => {
  const savedRecentSearches = JSON.parse(localStorage.getItem(storageIdentity));
  if (!savedRecentSearches) {
    return localStorage.setItem(storageIdentity, JSON.stringify([]));
  }
  if (savedRecentSearches && query && !savedRecentSearches.includes(query)) {
    if (savedRecentSearches.length > 10) {
      savedRecentSearches.length = 10;
      localStorage.setItem(
        storageIdentity,
        JSON.stringify([query, ...savedRecentSearches])
      );
      return [query, ...savedRecentSearches];
    } else {
      localStorage.setItem(
        storageIdentity,
        JSON.stringify([query, ...savedRecentSearches])
      );
      return [query, ...savedRecentSearches];
    }
  }
  return savedRecentSearches;
};

export const removeRecentSearchItem = (itemToRemove, storageIdentity) => {
  const savedRecentSearches = JSON.parse(localStorage.getItem(storageIdentity));
  if (savedRecentSearches && savedRecentSearches.length) {
    const filterRecentSearches = savedRecentSearches.filter(
      (el) => el !== itemToRemove
    );
    localStorage.setItem(storageIdentity, JSON.stringify(filterRecentSearches));
    return filterRecentSearches;
  }
  return [];
};

export const seprateQueryFromFilter = (filtersToRemove, query) => {
  const normalizeQuery = query.split(filtersToRemove);
  return normalizeQuery[1]?.trim();
};

export const getNumOfTextOccurrence = (arr, value) => {
  let apperance = 0;
  arr.forEach((item) => {
    apperance = item.toLowerCase().split(value).length - 1 + apperance;
  });
  return apperance;
};

const sortDataByNumOfMatches = (searchedData) => {
  return searchedData.sort(
    (a, b) => parseFloat(b.occurenceCount) - parseFloat(a.occurenceCount)
  );
};

const searchDataByTheValue = (nestedObj, keys, val, filter, keyArr) => {
  for (let newKeys in nestedObj) {
    const exists = nestedObj[newKeys].some((el) =>
      el.toLowerCase().includes(val)
    );
    if (exists) {
      const occurenceCount = getNumOfTextOccurrence(nestedObj[newKeys], val);
      keyArr[keys].push({
        subMenu: subMenusSettingsLabelRoutes[newKeys],
        occurenceCount,
        route: `hub/menus/filter/${filter}/${keys}/submenu/${newKeys}`,
      });
      sortDataByNumOfMatches(keyArr[keys]);
    }
  }
};

export const performSearchingInMenus = (obj, val, filter) => {
  if (!val) return {};
  let keyArr = {};
  Object.keys(obj).forEach((item) => (keyArr[item] = []));
  for (let keys in obj) {
    searchDataByTheValue(obj[keys], keys, val, filter, keyArr);
  }
  return keyArr;
};

export const getPrimaryUrl = (url, currentCompanyId = "") => {
  const urlObject = new URL(`${AxiosConfig.getPrimaryAppAddress()}${url}`);
  const isSearchParamFound = !!urlObject.search;
  const compId =
    store.getState().companies?.currentCompany?.id || currentCompanyId;

  const newUrl = urlObject.href.replace(urlObject.hash, "");
  return `${newUrl}${isSearchParamFound ? "&" : "?"}compId=${compId}${
    urlObject.hash
  }`;
};

export const getNextItem = (item, arr) => {
  const currentIndex = arr.indexOf(item);
  if (arr.indexOf(item) + 1 === arr.length) {
    return arr[0];
  }
  return arr[currentIndex + 1];
};

export const getPrevItem = (item, arr) => {
  const currentIndex = arr.indexOf(item);
  if (arr.indexOf(item) === 0) {
    return arr[arr.length - 1];
  }
  return arr[currentIndex - 1];
};

export const getGroupFilterCount = (membersFilter, group) => {
  let headerFilterMappedName = "";
  let number = "";

  if (membersFilter === "blocked") {
    headerFilterMappedName = "Blocked";
    number = group.members_blocked_count;
  } else if (membersFilter === "unsubscribed") {
    headerFilterMappedName = "Unsubscribed";
    number = group.members_unsubscribed_count;
  } else if (membersFilter === "opted-out") {
    headerFilterMappedName = "Opted Out";
    number = group.members_optedout_count;
  } else if (membersFilter === "do-not-call") {
    headerFilterMappedName = "Do Not Call";
    number = group.members_donotcall_count;
  } else if (membersFilter === "duplicates") {
    headerFilterMappedName = "Duplicates";
    number = group.members_duplicates_count;
  } else if (membersFilter === "voip") {
    headerFilterMappedName = "VOIP";
    number = group.members_voip_count;
  } else if (membersFilter === "landline") {
    headerFilterMappedName = "Landlines";
    number = group.members_landline_count;
  } else if (membersFilter === "mobile") {
    headerFilterMappedName = "Mobile";
    number = group.members_mobile_count;
  } else if (membersFilter === "number-not-checked") {
    headerFilterMappedName = "Unchecked";
    number = group.members_unchecked_count;
  } else if (membersFilter === "unformatted-number") {
    headerFilterMappedName = "Format Error";
    number = group.members_failed_count;
  } else if (membersFilter === "archived") {
    headerFilterMappedName = "Archived";
    number = group.members_archived_count;
  } else {
    number = group?.members_count;
  }

  return {
    number,
    headerFilterMappedName,
  };
};

export const getAllParamsObject = ({
  isGroupAllMembersSelected,
  filters,
  sourceGroupId,
  tab,
  enabled = 1,
  isDeleteRequest = false,
}) => {
  let allParams;
  if (isGroupAllMembersSelected) {
    // if all members of group selected then send this all params with api call
    allParams = {
      tab,
      enabled,
      isDeleteRequest,
      filters,
      group_id: sourceGroupId,
    };
  }

  return allParams;
};

export const getGroupUrl = ({ filter, groupId, pathname }) => {
  if (filter) {
    return `/hub/groups/filter/${filter}/group/${groupId}/${pathname}`;
  } else {
    return `/hub/groups/group/${groupId}/${pathname}`;
  }
};

export const replaceUnderscoreToSpace = (str) => {
  if (!str.includes("_")) return str;
  return str.replace(/_/g, " ");
};

export const capitalizeFirstLetter = (str = "") => {
  if (str == null) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};
// Only regular nouns
export const getPluralFromNumber = (number, word, prefix = "") => {
  const actualNumber = number == null ? 0 : number;

  if (actualNumber === 1) {
    return `${actualNumber} ${prefix}${word}`;
  } else {
    const endingSingle = word.slice(-1);
    const endingDouble = word.slice(-2);

    if (word.slice(-1) === "y") {
      return `${actualNumber} ${prefix}${word}ies`;
    } else if (
      endingSingle === "s" ||
      endingSingle === "x" ||
      endingSingle === "z" ||
      endingDouble === "ch" ||
      endingDouble === "sh"
    ) {
      return `${actualNumber} ${prefix}${word}es`;
    } else {
      return `${actualNumber} ${prefix}${word}s`;
    }
  }
};

export const getPersonPlural = (number) => {
  const actualNumber = number == null ? 0 : number;

  if (actualNumber === 1) return "1 Person";
  return `${actualNumber} People`;
};

export const getImageFileURL = (file) => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = (e) => res(e.target.result);
    reader.onerror = (e) => rej(e);
    reader.readAsDataURL(file);
  });
};
export const getUserDefaultNumber = (numbers = []) => {
  let assignedNumber = null;
  if (numbers) {
    for (let i = 0; i < numbers.length; i++) {
      if (numbers[i].is_default) {
        assignedNumber = numbers[i].number;
        break;
      }
    }
  }
  return assignedNumber;
};

export const getUserFullname = (userDetails = {}) => {
  if (userDetails == null) return "";
  const fullName = `${userDetails.first_name} ${userDetails.last_name}`;
  return fullName?.trim();
};

export const getCreditsInputLabelByPackageDuration = (
  companyPackageDuration
) => {
  return companyPackageDuration === "year"
    ? "Credits per Year:"
    : "Credits per Month:";
};
export const findValueRecursively = (array, property, value) => {
  const result = array.reduce((option, item) => {
    if (option) return option;
    if (item[property] === value) return item;
    if (item.children)
      return findValueRecursively(item.children, property, value);
    return null;
  }, null);
  return result;
};
export const groupDuplicatesByApp = (contactIds = [], contactsData) => {
  let contactIdsGrouped = [];
  let membersKeys = [];
  for (let i = 0; i < contactIds?.length; i += 1) {
    const contact = contactsData[contactIds[i]];
    const phonecode = contact.country ? contact.country.phonecode : "";
    const phone = `${phonecode}${contact.phone_number}`;
    let add = false;

    if (
      phone === "" ||
      phone === "1" ||
      phone === "10" ||
      phone === "-" ||
      phone === "1-"
    ) {
      add = true;
    } else {
      let app = "pl";
      if (contact.is_breeze) {
        app = "breeze";
      } else if (contact.is_ccb) {
        app = "ccb";
      } else if (contact.is_elvanto) {
        app = "elvanto";
      } else if (contact.is_mc) {
        app = "mc";
      } else if (contact.is_pco) {
        app = "pco";
      } else if (contact.is_rock) {
        app = "rock";
      }
      const key = `${app}-${phonecode}${contact.phone_number}`;
      add = typeof membersKeys[key] === "undefined";
      membersKeys[key] = true;
    }
    if (add) {
      contactIdsGrouped.push(contactIds[i]);
    }
  }
  return contactIdsGrouped;
};

export const addNewItemAboveScheduledMessage = (
  allThreadItems = [],
  newItem
) => {
  for (let i = 0; i < allThreadItems.length; i++) {
    if (allThreadItems[i].schedule_message) {
      allThreadItems.splice(i, 0, newItem);
      break;
    }
  }
};

export const addEditScheduledMessage = (
  allThreadItems = [],
  newItem,
  isOnEditScheduledMessage = false
) => {
  const firstScheduledMessageIndex = allThreadItems.findIndex(
    (value) => value.schedule_message
  );

  let scheduledMessages = allThreadItems.splice(firstScheduledMessageIndex);

  if (isOnEditScheduledMessage) {
    scheduledMessages = scheduledMessages.filter(
      (item) => item?.schedule_message?.id !== newItem?.schedule_message?.id
    );
  }
  scheduledMessages.push(newItem);
  scheduledMessages.sort(
    (a, b) =>
      Date.parse(a?.schedule_message?.send_on_utc) -
      Date.parse(b?.schedule_message?.send_on_utc)
  );
  allThreadItems.push(...scheduledMessages);
};

export const hasScheduledMessages = (threadItems = []) => {
  return threadItems?.some((item) => item.schedule_message);
};

export const filterSignaturesAccToThreadType = (
  signatures = [],
  threadType = ""
) => {
  let filteredSignatures = [];
  if (threadType === CONTACT) {
    filteredSignatures = signatures.filter(
      (signature) => signature.type === STANDARD_TEXT_SIGNATURE
    );
  } else if (threadType === GROUP) {
    filteredSignatures = signatures.filter(
      (signature) => signature.type === GROUP_TEXT_SIGNATURE
    );
  }
  return filteredSignatures;
};

export const getSignatureToInsert = ({
  signatures,
  chosenNumber,
  loggedUser,
  users,
  numbers,
  currentCompany,
  threadType,
}) => {
  const signatureNumber = signatures.find(
    (signature) => signature.number === chosenNumber
  );
  let signatureIndex = -1;

  if (Array.isArray(signatureNumber?.signatures)) {
    let signatures = signatureNumber.signatures;
    if (signatureNumber?.type === "local") {
      signatures = filterSignaturesAccToThreadType(
        signatureNumber?.signatures,
        threadType
      );
    }

    const activeSignature = signatures.find(
      (signature) => signature.is_default === 1
    );

    signatureIndex = signatureNumber?.signatures?.findIndex(
      (item) => item?.id === activeSignature?.id
    );
  }
  const numberIndex = numbers?.findIndex(
    (number) => number.number === chosenNumber
  );

  const isAdminOrOwner =
    currentCompany.is_admin_user ||
    currentCompany.is_admin_plus ||
    currentCompany.is_main_owner;

  if (signatureIndex === -1) return {};

  return {
    // for text area
    signature: signatureNumber.signatures[signatureIndex].signature,
    // for API
    signatureId: signatureNumber.signatures[signatureIndex].id,
    signatureUserId: loggedUser.id,
    signatureUserNumber: chosenNumber,
    // the part below is for signature modal
    selectedUser: isAdminOrOwner
      ? users?.findIndex((user) => user.user_id === loggedUser.id)
      : -1,
    selectedSignature: signatureIndex,
    selectedNumber: numberIndex,
  };
};

const checkTimeType = (time) => {
  switch (typeof time) {
    case "number":
      break;
    case "string":
      time = +new Date(time);
      break;
    case "object":
      if (time.constructor === Date) time = time.getTime();
      break;
    default:
      time = +new Date();
  }
};

/* This function will show the past and previous time formats like '2 days ago'
 '10 minutes from now' and you can pass it either a Date object,
  a numeric timestamp or a date string */

export const formatTime = (time) => {
  checkTimeType(time);
  let seconds = (+new Date() - time) / 1000;
  let token = "ago";
  let lastChoice = 1;
  let i = 0;
  let format;
  if (seconds === 0) {
    return "Just now";
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds);
    token = "from now";
    lastChoice = 2;
  }
  while ((format = timeFormats[i++])) {
    if (seconds < format[0]) {
      if (typeof format[2] === "string") {
        return format[lastChoice];
      } else {
        return Math.floor(seconds / format[2]) + " " + format[1] + " " + token;
      }
    }
  }
  return time;
};

export const getContactInfo = (contactData) => {
  let contactFullName = null;
  let contactFullNumber = null;
  let contactFullNameWithoutIcons = null;
  let contactFirstName = null;
  if (contactData && contactData.length === 1) {
    contactFullNumber =
      "" +
      (contactData[0].country ? contactData[0].country.phonecode : "") +
      contactData[0].phone_number;
    const contactNameStr = contactName(
      contactData[0].first_name,
      contactData[0].last_name,
      contactFullNumber
    );
    const contactFirstNameStr = contactName(contactData[0].first_name);
    contactFullName = contactNameStr;
    contactFirstName = contactFirstNameStr;
  } else if (contactData && contactData.length > 1) {
    const { contactsByIntegration } = getContactsByIntegration(contactData);
    let names = [];
    for (const key in contactsByIntegration) {
      if (contactsByIntegration[key].length > 0) {
        const contact = contactsByIntegration[key][0];
        const icon = (
          <IntegrationIcon {...contact} className="icon-grey-dark" />
        );

        const phonecode = getPhoneCode(contact);
        const phoneNumber = "" + phonecode + contact.phone_number;
        contactFullNameWithoutIcons = contactName(
          contact.first_name,
          contact.last_name,
          phoneNumber,
          { firstLetterLastName: true }
        );
        contactFullNumber = phoneNumber;
        names.push(
          <React.Fragment>
            <span className="mr-1">{contactFullNameWithoutIcons}</span>
            {icon}
          </React.Fragment>
        );
      }
      contactFullName = names;
    }
  }

  return {
    contactFullName,
    contactFullNumber,
    contactFullNameWithoutIcons,
    contactFirstName,
  };
};

export const getContactDataReduxForMapToProps = (
  store,
  threadType,
  interlocutorId
) => {
  let contactSwitchedCompanyId = null;
  let contactData = null;
  let contactStatus = null;
  if (threadType === CONTACT) {
    let contact =
      typeof store.contacts.data[interlocutorId] === "undefined"
        ? null
        : store.contacts.data[interlocutorId];
    contactStatus =
      typeof store.contacts.dataStatus[interlocutorId] === "undefined"
        ? null
        : store.contacts.dataStatus[interlocutorId];
    if (contact) {
      contactData = [];
      contactSwitchedCompanyId = contact.__switchedCompanyId;
      contactData.push(store.contacts.data[contact.id]);
      if (contact.contacts_ids && contact.contacts_ids.length > 1) {
        for (let i = 0; i < contact.contacts_ids.length; i++) {
          if (
            typeof store.contacts.data[contact.contacts_ids[i]] !== "undefined"
          ) {
            contactData.push(store.contacts.data[contact.contacts_ids[i]]);
          }
        }
      }
    }
  }

  return { contactData, contactSwitchedCompanyId, contactStatus };
};

export const getGroupDataReduxForMapToProps = (
  store,
  threadType,
  interlocutorId
) => {
  let groupData = null;
  let groupSwitchedCompanyId = null;
  let groupStatus = null;
  let groupErrorStatus = null;
  if (threadType === GROUP) {
    groupData =
      typeof store.groups.data[interlocutorId] === "undefined"
        ? null
        : store.groups.data[interlocutorId];
    groupSwitchedCompanyId = groupData ? groupData.__switchedCompanyId : null;
    groupStatus =
      typeof store.groups.dataStatus[interlocutorId] === "undefined"
        ? null
        : store.groups.dataStatus[interlocutorId];
    groupErrorStatus = store.groups.dataErrorStatus[interlocutorId];
  }

  return {
    groupData,
    groupSwitchedCompanyId,
    groupStatus,
    groupErrorStatus,
  };
};

export const getIntegrationPropsForGroups = (groupData) => {
  return {
    is_ccb: groupData?.ccb_status === 1,
    is_pco: groupData?.pco_status === 1,
    is_mc: groupData?.mc_status === 1,
    is_elvanto: groupData?.is_elvanto === 1,
    is_breeze: groupData?.is_breeze === 1,
    is_rock: groupData?.is_rock === 1,
  };
};

export const updatePaginationCount = (pagination, increase) => {
  return {
    ...pagination,
    count: increase ? pagination.count + 1 : pagination.count - 1,
  };
};

export const getCorrectId = (id) => {
  if (typeof id === "object") return id.id;
  else return id;
};

export const isAccountClosed = (company) => {
  if (!company) return false;
  return (
    company.is_billing_issue === 4 &&
    company.is_suspended === 2 &&
    company.number_is_deleted
  );
};

export const isAccountNeedRenew = (company) => {
  if (!company) return false;
  return company.is_billing_issue === 3 && company.is_suspended === 2;
};

export const prepareImageObject = (image) => {
  const preparedImage = { ...image };
  preparedImage.thumbUrl = `https://cdn.filestackcontent.com/resize=width:100,height:100,fit:max/compress/${image.handle}`;
  return preparedImage;
};

export const getStringOrEmpty = (string, emptyString = "") =>
  string ? string : emptyString;

export const singleConflictSolvedSnackBarMsg = (contactData) => {
  return (
    <div>
      You have resolved the conflicted profile for{" "}
      <span className="font-weight">
        {contactName(
          contactData.first_name,
          contactData.last_name,
          contactData.phone_number
        )}
      </span>
    </div>
  );
};

export const relaceAllStringOccurence = (str, search, replacement) => {
  return str.split(search).join(replacement);
};

export const checkUserHasPermissionToRoute = (pathname, permissions) => {
  const adminSettingsMenu = getAdminSettingsMenu();

  if (
    pathname.includes("admin-settings") &&
    !hasAtLeastOneAllowedRoute(adminSettingsMenu.items, permissions)
  ) {
    return false;
  }
  if (pathname.includes("hub/calls") && !permissions.messages_calls) {
    return false;
  }
  if (
    pathname.includes("hub/groups") &&
    !getPermissionParsed(permissions.groups, "list")
  ) {
    return false;
  } else if (
    pathname.includes("hub/groups/group") &&
    !getPermissionParsed(permissions.groups, "view")
  ) {
    return false;
  }

  if (
    pathname.includes("hub/people") &&
    !getPermissionParsed(permissions.people, "list")
  ) {
    return false;
  } else if (
    pathname.includes("hub/people/contact") &&
    !getPermissionParsed(permissions.people, "view")
  ) {
    return false;
  } else if (
    pathname.match(/^\/hub\/people\/contact\/\d+\/tags$/) &&
    !getPermissionParsed(permissions.tags, "list")
  ) {
    return false;
  } else if (
    pathname.match(/^\/hub\/people\/contact\/\d+\/integrations$/) &&
    !getPermissionParsed(permissions.admin_settings_integrations, "configure")
  ) {
    return false;
  } else if (
    pathname.match(/^\/hub\/people\/contact\/\d+\/campaigns$/) &&
    !getPermissionParsed(permissions.campaigns, "all")
  ) {
    return false;
  }

  if (pathname.includes(TAGS_ROUTE) && !permissions.tags) {
    return false;
  }
  if (pathname.includes("legacy") && !permissions.legacy) {
    return false;
  }
  if (pathname.includes("analytics") && !permissions.analytics) {
    return false;
  }
  if (pathname.includes("billing") && !permissions.billings) {
    return false;
  }
  if (
    pathname.includes("admin-integrations") &&
    !permissions.admin_settings_integrations
  ) {
    return false;
  }
  if (
    pathname.includes("admin-numbers") &&
    !permissions.admin_settings_numbers
  ) {
    return false;
  }
  if (
    pathname.includes("admin-signatures") &&
    !permissions.admin_settings_signatures
  ) {
    return false;
  }
  if (pathname.includes("admin-users") && !permissions.admin_settings_users) {
    return false;
  }
  if (
    pathname.includes("voice-templates") &&
    !permissions.broadcast_templates
  ) {
    return false;
  }
  if (
    pathname.includes("campaign-templates") &&
    !permissions.campaign_templates
  ) {
    return false;
  }
  if (pathname.includes("email-templates") && !permissions.email_temlates) {
    return false;
  }
  if (pathname.includes("keywords") && !permissions.keywords) {
    return false;
  }
  if (pathname.includes("user-notifications") && !permissions.notifications) {
    return false;
  }
  if (
    pathname.includes("user-organizations") &&
    !getPermissionParsed(permissions?.organizations, "list")
  ) {
    return false;
  }
  if (pathname.includes("shortened-links") && !permissions.shortlinks) {
    return false;
  }
  if (pathname.includes("sms-templates") && !permissions.sms_templates) {
    return false;
  }
  if (
    pathname.includes("user-greetings") &&
    !permissions.user_settings_greetings
  ) {
    return false;
  }
  if (
    pathname.includes("user-voicemail") &&
    !permissions.user_settings_voicemails
  ) {
    return false;
  }
  if (
    pathname.includes("user-number") &&
    !permissions.user_settings_my_numbers
  ) {
    return false;
  }
  if (
    pathname.includes("user-signatures") &&
    !permissions.user_settings_my_signatures
  ) {
    return false;
  }
  if (pathname.includes("number-lookup") && !permissions.number_lookups) {
    return false;
  }
  if (
    pathname.includes("hold") &&
    !getPermissionParsed(permissions.smart_syncing, "list")
  )
    return false;
  if (pathname.includes("security") && !permissions.security) {
    return false;
  }
  if (
    pathname.includes("menus/filter/campaigns") &&
    !getPermissionParsed(permissions.campaigns, "all")
  ) {
    return false;
  }
  if (
    pathname.includes("menus/filter/tags") &&
    !getPermissionParsed(permissions.tags, "view")
  ) {
    return false;
  }
  if (
    pathname.includes("menus/filter/web-widgets") &&
    !getPermissionParsed(permissions.web_widgets, "all")
  ) {
    return false;
  }
  if (
    pathname.includes(
      "menus/filter/settings/admin-settings/submenu/text-sms"
    ) &&
    !getPermissionParsed(permissions?.dashboard, "send_test_message")
  ) {
    return false;
  }
  return true;
};

export const hasAtLeastOneAllowedRoute = (items, userPermissions) => {
  return items.some(
    (item) =>
      userHasPermissionForMenuItem(item, userPermissions) &&
      !item.withoutPermission
  );
};

export const userHasPermissionForMenuItem = (item, userPermissions) => {
  return item.permissionType
    ? getPermissionParsed(
        userPermissions?.[item.userPermission],
        item.permissionType
      )
    : (!!item.userPermission && !!userPermissions[item.userPermission]) ||
        !item.userPermission;
};

export const arraysEqual = (firstArr, secondArr) => {
  return JSON.stringify(firstArr) === JSON.stringify(secondArr);
};

export const hasNumberAssigned = (numbers) => {
  if (!numbers) return false;

  for (let i = 0; i < numbers.length; i++) {
    if (numbers[i].is_default) {
      return true;
    }
  }

  return false;
};

export const checkUserPermissions = (tab, permission, type) => {
  if (tab && !getPermissionParsed(permission, type)) {
    return false;
  }
  return true;
};

export const getURLQueryParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
};

export const cancelCountsStatsRequest = () => {
  cancelFetchCounts();
  cancelFetchVoiceCounts();
  cancelFetchCountsContacts();
  cancelFetchCountsGroups();
};

export const mapProcessQueuesToCustomSelectOptions = (ccbProcesses) => {
  let processQueueOptions = ccbProcesses?.map((process) => ({
    title: process.name,
    options: process.queues?.map((queue) => ({
      title: process.name,
      value: queue?.queue_id,
      name: queue?.name,
    })),
  }));
  return processQueueOptions;
};

export const isEmptyObject = (object = {}) => {
  if (!object) return true;
  return Object.keys(object).length === 0;
};

export const checkIfSyncWayIsOneWay = (group) => {
  if (
    (group?.sync_way && !group.sync_way.includes("2-way")) ||
    (group?.ccb_pq_sync_way && !group.ccb_pq_sync_way.includes("2-way"))
  ) {
    return true;
  }
  return false;
};

export const notAllowedButEligibleContactId = (notAllowedContacts = []) => {
  let eligibleContactId = null;
  if (isNotAnEmptyArray(notAllowedContacts)) {
    for (let i = 0; i < notAllowedContacts.length; i++) {
      if (!notAllowedContacts[i].is_archived) {
        eligibleContactId = notAllowedContacts[i].id;
        break;
      } else if (notAllowedContacts[i].status === 3) {
        eligibleContactId = notAllowedContacts[i].id;
        break;
      } else if (isPastorsLineContact(notAllowedContacts[i])) {
        eligibleContactId = notAllowedContacts[i].id;
        break;
      }
    }
  }
  return eligibleContactId;
};

export const userHasNotAllowedContacts = (notAllowedContacts = []) => {
  return (
    isNotAnEmptyArray(notAllowedContacts) &&
    !notAllowedButEligibleContactId(notAllowedContacts)
  );
};

export const isAdminOrOwner = (company) => {
  return (
    company.is_admin_user || company.is_admin_plus || company.is_main_owner
  );
};

export const getV2Url = (url) => {
  return `${process.env.REACT_APP_V2_APP_URL}${url}`;
};

export const appendAddNewToDataObject = (oldData, newData) => {
  const groups = newData || {};

  for (let key in groups) {
    oldData[key] = oldData[key]
      ? {
          ...oldData[key],
          ...groups[key],
        }
      : groups[key];
  }

  return oldData;
};

export const isOnLocallyRemovedMember = (
  activeInterlocutorId = "",
  contactGroups = []
) => {
  if (!activeInterlocutorId || !contactGroups?.length) return false;
  return contactGroups.some(
    (item) =>
      item.group &&
      item.group?.id === Number(activeInterlocutorId) &&
      item.is_contact_moved &&
      item.sync_state === "locally-removed"
  );
};

export const hasGroupExternalResults = (externalResult = {}) => {
  return Object.keys(externalResult).some(
    (item) =>
      externalResult[item] &&
      Array.isArray(externalResult[item]) &&
      externalResult[item].length
  );
};

export const hasGroupInternalResults = (internalResult = {}) => {
  return (
    internalResult && Array.isArray(internalResult) && internalResult.length
  );
};

export const selectedContactsContainLocallyRemovedContact = (
  selectedContact = {},
  activeInterlocutorId = ""
) => {
  if (isEmptyObject(selectedContact) || !activeInterlocutorId) return false;
  return Object.values(selectedContact).some(
    (item) =>
      item.contact_groups &&
      Array.isArray(item.contact_groups) &&
      item.contact_groups.some(
        (contact) =>
          contact.group &&
          contact.group?.id === Number(activeInterlocutorId) &&
          contact.is_contact_moved &&
          contact.sync_state === "locally-removed"
      )
  );
};

export const getLocallyRemovedContactIds = (
  selectedContact = {},
  activeInterlocutorId = ""
) => {
  if (isEmptyObject(selectedContact) || !activeInterlocutorId) return [];
  return Object.values(selectedContact)
    .filter(
      (item) =>
        item.contact_groups &&
        Array.isArray(item.contact_groups) &&
        item.contact_groups.some(
          (contact) =>
            contact.group &&
            contact.group?.id === Number(activeInterlocutorId) &&
            contact.is_contact_moved &&
            contact.sync_state === "locally-removed"
        )
    )
    .map((el) => el.id);
};

export const isReactNativeApp = () => {
  if (
    navigator.userAgent &&
    navigator.userAgent.includes("pastorsline-react-native") &&
    window.ReactNativeWebView
  ) {
    return true;
  }
  return false;
};

export const isIntegrationGroup = (groupData = {}) => {
  if (groupData && !isEmptyObject(groupData)) {
    if (
      groupData?.ccb_status === 1 ||
      groupData?.is_elvanto === 1 ||
      groupData?.mc_status === 1 ||
      groupData?.pco_status === 1 ||
      groupData?.is_breeze === 1 ||
      groupData?.is_rock === 1
    )
      return true;
  }
  return false;
};

export const hasAtleastOneActiveIntegration = (currentCompany = {}) => {
  const {
    mc_status,
    is_ccb_status,
    breeze_status,
    elvanto_status,
    pco_status,
  } = currentCompany;
  if (
    currentCompany &&
    !isEmptyObject(currentCompany) &&
    [
      mc_status,
      is_ccb_status,
      breeze_status,
      elvanto_status,
      pco_status,
    ].includes(1)
  ) {
    return true;
  }
  return false;
};

export const getReferralId = () => {
  return (
    (window.Rewardful && window.Rewardful.referral) ||
    "checkout_" + new Date().getTime()
  );
};
