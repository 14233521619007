//new icons
import { ReactComponent as TemplatesIcon } from "../../../assets/img/icons-new/main-nav-v2/templates/templates-purple.svg";
import { ReactComponent as TagPurpleIcon } from "../../../assets/img/icons-new/main-nav-v2/tag/tag-purple.svg";
import { ReactComponent as AnalyticsIcon } from "../../../assets/img/icons-new/general/analytics/analytics-purple.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/img/icons-new/settings/setting/setting-purple.svg";
// import { ReactComponent as KeywordsIcon } from "../../../assets/img/icons-new/general/keywords/keywords-purple.svg";
import { ReactComponent as CampaignIcon } from "../../../assets/img/icons-new/main-nav/campaigns/campaigns-purple.svg";
import { ReactComponent as WebWidgetsIcon } from "../../../assets/img/icons-new/main-nav-v2/web-widgets/web-widgets-purple.svg";
import { ReactComponent as WebWidgetsMobileIcon } from "../../../assets/img/icons-new/general/web-widgets/web-widgets-purple.svg";
import { ReactComponent as ShortCodeKeywordsIcon } from "../../../assets/img/icons-new/main-nav-v2/short-code/short-code-purple.svg";
import { ReactComponent as QRCodeIcon } from "../../../assets/img/icons-new/main-nav-v2/qr-code/qr-code-purple.svg";
import { ReactComponent as AllTextIcon } from "../../../assets/img/icons-new/all-text/all-text.svg";
import {
  CAMPAIGNS_ROUTE,
  QR_CODE_ROUTE,
  SHORTCODE_KEYWORDS_ROUTE,
  TAGS_ROUTE,
  TEMPLATES_ROUTE,
  MESSAGE_TEMPLATES_ROUTE,
  MESSAGE_TEMPLATES_SMS_ROUTE,
  WEB_WIDGETS_ROUTE,
} from "../routes";

export const mainMenus = [
  // {
  //   icon: KeywordsIcon,
  //   label: "Keywords",
  //   key: "keywords",
  // },
  {
    icon: TagPurpleIcon,
    label: "Tags",
    key: TAGS_ROUTE,
    userPermission: "tags",
    permissionType: "view",
  },
  {
    icon: WebWidgetsIcon,
    label: "Web Widgets",
    key: WEB_WIDGETS_ROUTE,
    userPermission: "web_widgets",
    permissionType: "all",
  },
  {
    icon: ShortCodeKeywordsIcon,
    label: "Shortcode Keywords",
    key: SHORTCODE_KEYWORDS_ROUTE,
  },

  {
    icon: TemplatesIcon,
    label: "Templates",
    key: `${TEMPLATES_ROUTE}/${MESSAGE_TEMPLATES_ROUTE}/submenu/${MESSAGE_TEMPLATES_SMS_ROUTE}`,
  },
  {
    icon: QRCodeIcon,
    label: "QR Code",
    key: QR_CODE_ROUTE,
  },
];

export const mainMenusForMobileView = [
  {
    icon: CampaignIcon,
    label: "Campaigns",
    key: CAMPAIGNS_ROUTE,
    userPermission: "campaigns",
    permissionType: "all",
  },
  {
    icon: TagPurpleIcon,
    label: "Tags",
    key: TAGS_ROUTE,
    userPermission: "tags",
    permissionType: "view",
  },
  {
    icon: WebWidgetsMobileIcon,
    label: "Web Widgets",
    key: WEB_WIDGETS_ROUTE,
    userPermission: "web_widgets",
    permissionType: "all",
  },
  {
    icon: AllTextIcon,
    label: "All Text",
    key: "messages",
  },
  {
    icon: ShortCodeKeywordsIcon,
    label: "Shortcode Keywords",
    key: SHORTCODE_KEYWORDS_ROUTE,
  },
  {
    icon: AnalyticsIcon,
    label: "Analytics",
    key: "analytics",
  },
  {
    icon: TemplatesIcon,
    label: "Templates",
    key: TEMPLATES_ROUTE,
  },
  {
    icon: QRCodeIcon,
    label: "QR Code",
    key: QR_CODE_ROUTE,
  },
  {
    icon: SettingsIcon,
    label: "Settings",
    key: "settings",
  },
];
