import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as menuActions from "../../../actions/menus";

export const useMenusStore = () => {
  const dispatch = useDispatch();
  const _menus = useSelector((store) => store.menus);

  const resetMoreMenuExpand = useCallback(
    () => dispatch(menuActions.resetMoreMenuExpand()),
    [dispatch]
  );

  const setRedirectUrl = useCallback(
    (path) => dispatch(menuActions.setRedirectUrl(path)),
    [dispatch]
  );

  const toggleExpandedView = useCallback(
    () => dispatch(menuActions.toggleExpandedView()),
    [dispatch]
  );

  return {
    menus: _menus,
    resetMoreMenuExpand,
    setRedirectUrl,
    toggleExpandedView,
  };
};
