import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";

import { ReactComponent as AllNotificationOffIcon } from "../../../assets/img/icons-new/settings/user-settings/notifications/all-notifications-off/all-notifications-off-purple.svg";
import { ReactComponent as CustomNotificationsIcon } from "../../../assets/img/icons-new/settings/user-settings/notifications/custom-notifications/custom-notifications-purple.svg";
import IntegrationIcon from "../../../assets/img/integrations/integration.png";
import { ReactComponent as SettingsIcon } from "../../../assets/img/icons-new/settings/setting/setting-grey.svg";
import { ReactComponent as GetGiftIcon } from "../../../assets/img/icons-new/sidebar-dropright/gift-icon.svg";
import { ReactComponent as DropDownArrow } from "../../../assets/img/icons-new/general/arrow/select-drop-down-gray.svg";
import { ReactComponent as DollarIcon } from "../../../assets/img/icons-new/dollar/dollar-black.svg";

import { PopoverBody, UncontrolledPopover } from "reactstrap";
import { connect } from "react-redux";
import { notificationTypes } from "../../../utils/settingsHelpers";
import {
  clearUpdateNotificationsMode,
  updateNotificationsMode,
} from "../../../actions/menus/userSettings";

import UpgradePlanModal from "../../modals/Gifts/UpgradePlanModal";
import SideBarIconWrapper from "../SideBarIconWrapper";
import { useHistory, useParams } from "react-router-dom";
import SideBarIntegrationsPopover from "./SideBarIntegrationsPopover";
import SidebarMenuInfo from "./SidebarMenuInfo";
import { LightTooltip } from "../../../helpers";
import { USER_AFFILIATE_ROUTE } from "../../Menus/routes";

function mapStateToProps(store) {
  return {
    loggedUser: store.users.loggedUser,
    currentCompanyId: store.companies.currentCompany.id,
    updateNotificationsModeStatus:
      store.userSettings.updateNotificationsModeStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateNotificationsMode: (data) => dispatch(updateNotificationsMode(data)),
    clearUpdateNotificationsMode: () =>
      dispatch(clearUpdateNotificationsMode()),
  };
}

const SideBarSettingsNotifications = (props) => {
  const {
    // Component props
    isSidebarExpanded,

    // Redux props
    loggedUser,
    currentCompanyId,
    updateNotificationsModeStatus,

    // Redux func
    updateNotificationsMode,
    clearUpdateNotificationsMode,
  } = props;

  // ROUTERS
  const history = useHistory();
  const params = useParams();

  const [activeNotification, setActiveNotification] = useState("off");
  const [upgradePlanModalIsOpen, setUpgradePlanModalIsOpen] = useState(false);
  const [isSettingsActive, setIsSettingsActive] = useState(false);

  const displayGetGiftNotification = loggedUser.new_gifts > 0;
  // const displayGetBonusNotification = false;

  useEffect(() => {
    setActiveNotification(loggedUser.notifications_mode[currentCompanyId]);
    // to be deleted when smart/focus notifications are available on the API
    if (
      loggedUser.notifications_mode[currentCompanyId] !== "off" &&
      loggedUser.notifications_mode[currentCompanyId] !== "custom"
    ) {
      updateNotificationsMode({
        mode: "off",
        company_id: currentCompanyId,
      });
    }
  }, [currentCompanyId, loggedUser, updateNotificationsMode]);

  // to be deleted when smart/focus notifications are available on the API
  useEffect(() => {
    if (updateNotificationsModeStatus === "success") {
      clearUpdateNotificationsMode();
    }
  }, [clearUpdateNotificationsMode, updateNotificationsModeStatus]);

  const getActiveIcon = (type) => {
    switch (type) {
      // case notificationTypes.smart.mode:
      //   return <SmartNotificationIcon />;
      case notificationTypes.off.mode:
        return <AllNotificationOffIcon height={17} width={17} />;
      // case notificationTypes.focus.mode:
      //   return <FocusModeIcon />;
      case notificationTypes.custom.mode:
        return <CustomNotificationsIcon height={17} width={17} />;

      default:
        return <AllNotificationOffIcon height={17} width={17} />;
    }
  };

  const getDisplayText = (type) => {
    if (!type || type === notificationTypes.off.mode) {
      return `${notificationTypes.off.displayText}.`;
    } else {
      return `${notificationTypes[type].displayText} is turned on.`;
    }
  };

  const onRedirect = useCallback(
    (url) => () => {
      history.push(url);
    },
    [history]
  );

  const toolTipWrapper = useCallback(
    (content, label) => {
      if (isSidebarExpanded) return content;
      return (
        <LightTooltip title={label} placement="right">
          {content}
        </LightTooltip>
      );
    },
    [isSidebarExpanded]
  );

  useEffect(() => {
    setIsSettingsActive(
      history.location.pathname.includes("/hub/menus/filter/settings")
    );

    // On specific changes useEffect will not run so history.listen is required
    history.listen((location) => {
      setIsSettingsActive(
        location.pathname.includes("/hub/menus/filter/settings")
      );
    });
  }, [history]);

  return (
    <>
      {toolTipWrapper(
        <div
          className={clsx("cursor-pointer sidebar-notifications-bonus-icon ", {
            "ml-3": isSidebarExpanded,
            minimized: !isSidebarExpanded,
          })}
          onClick={onRedirect(
            "/hub/menus/filter/settings/user-settings/submenu/user-profile"
          )}
        >
          <SideBarIconWrapper id={"settings-sidebar"}>
            <SidebarMenuInfo
              isSidebarExpanded={isSidebarExpanded}
              label="Settings"
              iconWrapperClassName="ml-0 mr-0"
              labelClassName="ml-2 mb-0"
              icon={<SettingsIcon height={17} width={17} />}
              isActive={isSettingsActive}
            />
          </SideBarIconWrapper>
        </div>,
        "Settings"
      )}

      <div
        className={clsx("cursor-pointer sidebar-notifications-bonus-icon", {
          "ml-3": isSidebarExpanded,
          minimized: !isSidebarExpanded,
        })}
        id="sidebar-integrations-popover"
      >
        <SideBarIconWrapper id={"integration-sidebar"}>
          <div
            className={clsx({
              "sidebar-expanded-list": isSidebarExpanded,
            })}
          >
            <div className="d-flex align-items-center">
              <img
                className="sidebar-expanded-list-image"
                src={IntegrationIcon}
                alt="integration"
              />
              {isSidebarExpanded && (
                <label className="ml-2 mb-0">Integrations</label>
              )}
              <DropDownArrow
                className={clsx("expand-integrations-icon", {
                  minimized: !isSidebarExpanded,
                })}
              />
            </div>
          </div>
        </SideBarIconWrapper>
      </div>
      <SideBarIntegrationsPopover />
      {displayGetGiftNotification &&
        toolTipWrapper(
          <div
            className="cursor-pointer"
            id="bonus-notification"
            onClick={onRedirect("#modal-gift-claim")}
          >
            <SideBarIconWrapper
              className={clsx("sidebar-notifications-bonus-icon", {
                "ml-3": isSidebarExpanded,
                minimized: !isSidebarExpanded,
              })}
              id={"gift-sidebar"}
              showBlueStatus
              notificationBadgeBlueClassName={clsx("gift-notification-bullet", {
                "sidebar-expanded-notification-bullet": isSidebarExpanded,
              })}
            >
              <SidebarMenuInfo
                isSidebarExpanded={isSidebarExpanded}
                label="Gifts"
                labelClassName="ml-2 mb-0"
                icon={<GetGiftIcon height={17} width={17} />}
                iconWrapperClassName="ml-0 mr-0"
              />
            </SideBarIconWrapper>
          </div>,
          "Gifts"
        )}
      {/* {displayGetBonusNotification && (
        <div
          className="cursor-pointer"
          id="bonus-notification"
          onClick={() => {}}
        >
          <div className="sidebar-notifications-bonus-text">3 days</div>
          <div className="sidebar-notifications-bonus-icon">
            <GetBonusIcon />
          </div>
        </div>
      )} */}
      <div
        className={clsx("sidebar-notifications-settings", {
          "ml-0 pl-3": isSidebarExpanded,
          minimized: !isSidebarExpanded,
        })}
        onClick={onRedirect(notificationUrl)}
      >
        <SideBarIconWrapper
          className={`sidebar-notifications-settings-icon`}
          id="notification-setting"
        >
          <SidebarMenuInfo
            isSidebarExpanded={isSidebarExpanded}
            label="Notification"
            iconWrapperClassName="ml-0 mr-0"
            labelClassName="ml-2 mb-0"
            icon={getActiveIcon(activeNotification)}
          />
        </SideBarIconWrapper>
        {/* <UncontrolledPopover
          target="bonus-notification"
          trigger="hover"
          placement="right"
          className="bonus-notification-popover"
        >
          <CloseSvg className="close-icon" />
          <PopoverBody className="bonus-notification-body">
            <BonusNotificationCountdown />
            <div className="bonus-text">
              Upgrade now to get <b>x2 credits</b> for your first month! You
              have 3 Days left for this offer!
            </div>
            <button
              className="btn btn-primary"
              onClick={() => setUpgradePlanModalIsOpen(true)}
            >
              Upgrade Plan
            </button>
          </PopoverBody>
        </UncontrolledPopover> */}
        <UncontrolledPopover
          trigger="hover"
          placement="left-start"
          target="notification-setting"
          className={clsx("sidebar-notifications-settings-popover", {
            minimized: !isSidebarExpanded,
          })}
          delay={500}
        >
          <PopoverBody className="sidebar-notifications-settings-popover-body">
            <span className="sidebar-notifications-settings-popover-text">
              <div>{getDisplayText(activeNotification)}</div>
              <span
                className="sidebar-notifications-settings-popover-link-text"
                onClick={onRedirect(notificationUrl)}
              >
                View Notification Settings.
              </span>
            </span>
          </PopoverBody>
        </UncontrolledPopover>
        {upgradePlanModalIsOpen && (
          <UpgradePlanModal
            show={upgradePlanModalIsOpen}
            closeModal={() => setUpgradePlanModalIsOpen(false)}
          />
        )}
      </div>

      {toolTipWrapper(
        <div
          className={clsx(
            "cursor-pointer sidebar-notifications-bonus-icon mb-0 mt-3",
            {
              "ml-3": isSidebarExpanded,
              minimized: !isSidebarExpanded,
            }
          )}
          onClick={onRedirect(
            "/hub/menus/filter/settings/user-settings/submenu/user-affilate"
          )}
        >
          <SideBarIconWrapper id={"settings-sidebar"}>
            <SidebarMenuInfo
              isSidebarExpanded={isSidebarExpanded}
              label="Earn Money"
              iconWrapperClassName="ml-0 mr-0"
              labelClassName="ml-2 mb-0"
              icon={<DollarIcon height={17} width={17} />}
              isActive={params?.submenu === USER_AFFILIATE_ROUTE}
              isNewMenu={true}
            />
          </SideBarIconWrapper>
        </div>,
        "Earn Money"
      )}
    </>
  );
};

const notificationUrl =
  "/hub/menus/filter/settings/user-settings/submenu/user-notifications/all";

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideBarSettingsNotifications);
