import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as mainActions from "../../../actions/main";

export function useMainStore() {
  const dispatch = useDispatch();
  const _main = useSelector((store) => store.main);

  const clearErrors = useCallback(
    async () => await dispatch(mainActions.clearErrors()),
    [dispatch]
  );

  const toggleSidebar = useCallback(
    async () => await dispatch(mainActions.toggleSidebar()),
    [dispatch]
  );

  const setTopBarsHeight = useCallback(
    async (height) => await dispatch(mainActions.setTopBarsHeight(height)),
    [dispatch]
  );

  const setIsLeftPanelScrollingDown = useCallback(
    async (isScrolling) =>
      await dispatch(mainActions.setIsLeftPanelScrollingDown(isScrolling)),
    [dispatch]
  );

  const setIsMainPanelScrollingUp = useCallback(
    async (isScrolling) =>
      await dispatch(mainActions.setIsMainPanelScrollingUp(isScrolling)),
    [dispatch]
  );

  const showLoading = useCallback(
    async () => await dispatch(mainActions.showLoading()),
    [dispatch]
  );

  const toggleArchiveSwitch = useCallback(
    async (id, value) =>
      await dispatch(mainActions.toggleArchiveSwitch(id, value)),
    [dispatch]
  );

  const toggleSearch = useCallback(
    async (val) => await dispatch(mainActions.toggleSearch(val)),
    [dispatch]
  );

  const addInfoSnackBar = useCallback(
    async (data) => await dispatch(mainActions.addInfoSnackBar(data)),
    [dispatch]
  );

  const removeInfoSnackBar = useCallback(
    async (id) => await dispatch(mainActions.removeInfoSnackBar(id)),
    [dispatch]
  );

  const addDataForModal = useCallback(
    async (dataForModal) =>
      await dispatch(mainActions.addDataForModal(dataForModal)),
    [dispatch]
  );

  const setIsOnPeopleTableView = useCallback(
    async (isPeopleOnTableView) =>
      await dispatch(mainActions.setIsOnPeopleTableView(isPeopleOnTableView)),
    [dispatch]
  );

  const changeIframeKey = useCallback(
    async () => await dispatch(mainActions.changeIframeKey()),
    [dispatch]
  );

  const extendLegacySession = useCallback(
    async (show) => await dispatch(mainActions.extendLegacySession(show)),
    [dispatch]
  );

  const toggleSessionExpired = useCallback(
    async (show) => await dispatch(mainActions.toggleSessionExpired(show)),
    [dispatch]
  );

  const toggleSessionReplacedModal = useCallback(
    async (show) =>
      await dispatch(mainActions.toggleSessionReplacedModal(show)),
    [dispatch]
  );

  const setRedirectedToDashboardWhenNoAssignedNumber = useCallback(
    async (isRedirected) =>
      await dispatch(
        mainActions.setRedirectedToDashboardWhenNoAssignedNumber(isRedirected)
      ),
    [dispatch]
  );

  const toggleShowErrorModal = useCallback(
    async (show) => await dispatch(mainActions.toggleShowErrorModal(show)),
    [dispatch]
  );

  const togggleShowEmbededScreemOnFullMode = useCallback(
    async (show) =>
      await dispatch(mainActions.togggleShowEmbededScreemOnFullMode(show)),
    [dispatch]
  );

  const storeOldRequestInQueueForCFChallenge = useCallback(
    async (orignalRequests) =>
      await dispatch(
        mainActions.storeOldRequestInQueueForCFChallenge(orignalRequests)
      ),
    [dispatch]
  );

  const setEmbeddedScreenLoading = useCallback(
    (isLoading) => dispatch(mainActions.setEmbeddedScreenLoading(isLoading)),
    [dispatch]
  );

  return {
    main: _main,
    clearErrors,
    toggleSidebar,
    setTopBarsHeight,
    setIsLeftPanelScrollingDown,
    setIsMainPanelScrollingUp,
    showLoading,
    toggleArchiveSwitch,
    toggleSearch,
    addInfoSnackBar,
    removeInfoSnackBar,
    addDataForModal,
    setIsOnPeopleTableView,
    changeIframeKey,
    extendLegacySession,
    toggleSessionExpired,
    setRedirectedToDashboardWhenNoAssignedNumber,
    toggleSessionReplacedModal,
    toggleShowErrorModal,
    togggleShowEmbededScreemOnFullMode,
    storeOldRequestInQueueForCFChallenge,
    setEmbeddedScreenLoading,
  };
}

export default useMainStore;
