/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/09/2018.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";
import { getPrimaryUrl } from "../helpers";
import { getRequest, postRequest } from "../utils/requests";
import { logoutAuth } from "../utils/auth";
import { triggerTagEvent } from "../utils/tagManagerHelpers";
import { UserRedirectEnums } from "../utils/enums/usersEnums";
import { cookies } from "../store";

export function v1Login() {
  return function (dispatch) {
    dispatch({ type: "USERS/V1_LOGIN" });

    const url = getPrimaryUrl("users/v2Login");
    return axios
      .get(url, AxiosConfig.getConfig())
      .then((response) => {
        AxiosConfig.setAuthToken(response.data.jwt_token);
        dispatch({
          type: "USERS/V1_LOGIN.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USERS/V1_LOGIN.ERROR",
          payload: error,
        });
      });
  };
}

export function v3Login(data) {
  return function (dispatch) {
    dispatch({
      type: "USERS/V3_LOGIN",
    });
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + `users/login.json`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "USERS/V3_LOGIN.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USERS/V3_LOGIN.ERROR",
          payload: error,
          error: error.response,
        });
      });
  };
}

export function forgotPassword(data) {
  return (dispatch) => {
    postRequest(
      dispatch,
      "USERS/FORGOT_PASSWORD",
      "users/reset-password-init.json",
      data
    );
  };
}

export function clearV3LoginUserStatusAndError() {
  return function (dispatch) {
    dispatch({ type: "USERS/V3LOGIN.CLEAR" });
  };
}

export function clearForgoPasswordStatusAndError() {
  return function (dispatch) {
    dispatch({ type: "USERS/FORGOT_PASSWORD.CLEAR" });
  };
}

export function logout(redirectHash) {
  return function (dispatch) {
    logoutAuth(redirectHash);
    return dispatch({ type: "USER_LOGOUT", redirectingToLegacy: true });
  };
}

export function fetchUserData() {
  return function (dispatch) {
    dispatch({ type: "FETCH_USER_DATA" });
    const url = "users/view.json";
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        const { is_review, is_spam } = response?.data?.user;
        // if user is not admin and is_review or is_spam is true, logout user
        if (!cookies.get("is_admin")) {
          if (is_review === 1 || is_spam === 1) {
            const redirectHash =
              is_review === 1
                ? UserRedirectEnums.REVIEW
                : UserRedirectEnums.SPAM;
            dispatch(logout(redirectHash));
          }
        }

        dispatch({
          type: "FETCH_USER_DATA_SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "FETCH_USER_DATA_ERROR",
          payload: error,
        });
      });
  };
}

export function fetchCredits(companyId) {
  return function (dispatch) {
    dispatch({ type: "FETCH_CREDITS" });

    const url = "users/credits.json?companyId=" + companyId;
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "FETCH_CREDITS_SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "FETCH_CREDITS_ERROR",
          payload: error,
        });
      });
  };
}

export function clearSkipStep5() {
  return function (dispatch) {
    dispatch({ type: "USERS/CLEAR_SKIP_STEP5" });

    const url = "users/clear-skip-step5.json";
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + url,
        {},
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "USERS/CLEAR_SKIP_STEP5.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USERS/CLEAR_SKIP_STEP5.ERROR",
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function fetchUserSettings() {
  return (dispatch) => {
    dispatch({ type: "USERS/FETCH_SETTINGS" });

    const url = "users/settings.json";
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "USERS/FETCH_SETTINGS.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.url &&
          error?.response?.data?.url?.includes("/refresh-tokens.json")
        )
          return;
        dispatch({
          type: "USERS/FETCH_SETTINGS.ERROR",
          payload: error,
        });
      });
  };
}

export function updateUserSettings(params, requestId) {
  return (dispatch) => {
    postRequest(
      dispatch,
      "USERS/UPDATE_SETTINGS",
      "users/settings.json",
      params,
      {
        requestDispatchData: {
          requestId,
        },
        successDispatchData: {
          requestId,
        },
      }
    );
  };
}

export function logoutAction() {
  return function (dispatch) {
    return dispatch({ type: "USER_LOGOUT", redirectingToLegacy: false });
  };
}

export function clearUpdateUserSettingsStatus() {
  return function (dispatch) {
    dispatch({ type: "USERS/UPDATE_SETTINGS.CLEAR" });
  };
}

export function fetchUserPermissions(params) {
  return (dispatch) => {
    getRequest(
      dispatch,
      "USERS/FETCH_PERMISSIONS",
      `users/permissions.json?companyId=${params.companyId}`
    );
  };
}

export function registerUser(params) {
  return (dispatch) => {
    postRequest(dispatch, "USERS/REGISTER", "users/register.json", params, {
      successDispatchData: { email: params.email },
      thenFunction: triggerTagEvent("new-account-registered", params),
    });
  };
}
export function clearRegisterUserStatusAndError() {
  return function (dispatch) {
    dispatch({ type: "USERS/REGISTER.CLEAR" });
  };
}
export function clearRegisteredUserEmail() {
  return function (dispatch) {
    dispatch({ type: "USERS/REGISTERED_EMAIL.CLEAR" });
  };
}

export function clearLogoutStatus() {
  return function (dispatch) {
    dispatch({ type: "USER_LOGOUT.CLEAR" });
  };
}

export function updateMainMenuLook(mainMenuLook) {
  return function (dispatch) {
    dispatch({ type: "USERS/UPDATE_MAIN_MENU_LOOK", mainMenuLook });
  };
}

export function updateBiometricOptInState(status) {
  return function (dispatch) {
    dispatch({ type: "USERS/UPDATE_BIOMETRIC_STATE", status });
  };
}

export function startLegacySession(show) {
  return function (dispatch) {
    dispatch({
      type: "USERS/START_LEGACY_SESSION",
      show,
    });
  };
}

export function updateMainMenuLookOnMessages(mainMenuLookOnMessages) {
  return function (dispatch) {
    dispatch({
      type: "USERS/UPDATE_MAIN_MENU_LOOK_ON_MESSAGES",
      mainMenuLookOnMessages,
    });
  };
}

export function setShowCfChallengeOverlay(show) {
  return function (dispatch) {
    dispatch({
      type: "USERS/SHOW_CF_CHALLENGE_OVERLAY",
      show,
    });
  };
}
