/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/09/2018.
 */

const initialState = {
  pathname: "/",
  previousPathname: null,
  isSidebarOpen: false,
  topBarsHeight: 0,
  isLeftPanelScrollingDown: false,
  isMainPanelScrollingUp: false,
  isLoading: false,
  checkedArchivedButton: {},
  showSearch: null,

  infoSnackbarData: {},

  dataForModal: {},

  isPeopleOnTableView: false,

  iFrameKey: `${Math.random()}`,

  showLegacyExtendSession: false,

  showSessionExpiredModal: false,

  redirectedToDashboardWhenNoAssignedNumber: false,

  // Session replaced modal
  showSessionReplaced: false,

  // Show error modal
  showErrorModal: false,

  // Show embeded pages on full mode
  showEmbededScreenOnFullMode: true,

  // CF REQUEST STORE IN QUEUE
  cfRequestQueue: null,

  // Embedded screen loading
  isEmbeddedScreenLoading: true,
};

export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      return {
        ...state,
        previousPathname: state.pathname,
        pathname: action.payload.pathname,
      };

    case "MAIN/TOGGLE_SIDEBAR":
      return {
        ...state,
        isSidebarOpen: !state.isSidebarOpen,
      };

    case "MAIN/SET_TOP_BARS_HEIGHT":
      return {
        ...state,
        topBarsHeight: action.height,
      };

    case "MAIN/SET_IS_LEFT_PANEL_SCROLLING_DOWN":
      return {
        ...state,
        isLeftPanelScrollingDown: action.isScrolling,
      };

    case "MAIN/SET_IS_MAIN_PANEL_SCROLLING_UP":
      return {
        ...state,
        isMainPanelScrollingUp: action.isScrolling,
      };

    case "MAIN/SHOW_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "TOGGLE_SEARCH":
      return {
        ...state,
        showSearch: action.val,
      };

    case "MAIN/TOGGLE_ARCHIVE_SWITCH":
      let newCheck;
      if (action.value != null) {
        newCheck = action.value;
      } else {
        newCheck = state.checkedArchivedButton[action.id] ? false : true;
      }

      return {
        ...state,
        checkedArchivedButton: {
          ...state.checkedArchivedButton,
          [action.id]: newCheck,
        },
      };

    case "MAIN/ADD_INFO_SNACK_BAR": {
      const { msg, err, undoPayload } = action.data;
      const id = `info-${Math.floor(Math.random() * 50)}`;

      return {
        ...state,
        infoSnackbarData: {
          ...state.infoSnackbarData,
          [id]: {
            id,
            msg: msg,
            err: err,
            undoPayload,
          },
        },
      };
    }

    case "MAIN/REMOVE_INFO_SNACK_BAR": {
      const { id } = action;
      const infoSnackbarDataCopy = { ...state.infoSnackbarData };
      delete infoSnackbarDataCopy[id];
      return {
        ...state,
        infoSnackbarData: infoSnackbarDataCopy,
      };
    }

    case "MAIN/ADD_DATA_FOR_MODAL": {
      return {
        ...state,
        dataForModal: action.dataForModal,
      };
    }

    case "MAIN/SET_IS_ON_PEOPLE_TABLE_VIEW": {
      return {
        ...state,
        isPeopleOnTableView: action.isPeopleOnTableView,
      };
    }

    case "MAIN/CHANGE_IFRAME_KEY": {
      return {
        ...state,
        iFrameKey: `${Math.random()}`,
      };
    }

    case "MAIN/EXTEND_LEGACY_SESSION_OPENER": {
      return {
        ...state,
        showLegacyExtendSession: action.show,
      };
    }

    case "MAIN/TOGGLE_SESSION_EXPIRED_MODAL": {
      return {
        ...state,
        showSessionExpiredModal: action.show,
      };
    }

    case "MAIN/TOGGLE_SESSION_STARTED_MODAL": {
      return {
        ...state,
        showSessionReplaced: action.show,
      };
    }

    case "MAIN/TOGGLE_SHOW_ERROR_MODAL": {
      return {
        ...state,
        showErrorModal: action.show,
      };
    }

    case "MAIN/SET_REDIRECTED_DASHBOARD_WHEN_NO_ASSIGNED_NUMBER":
      return {
        ...state,
        redirectedToDashboardWhenNoAssignedNumber: action.isRedirected,
      };

    case "MAIN/TOGGLE_SHOW_EMBEDED_SCREEN_ON_FULL_MODE": {
      return {
        ...state,
        showEmbededScreenOnFullMode: action.show,
      };
    }

    case "MAIN/STORE_OLD_REQUEST_IN_QUEUE_FOR_CF_CHALLENGE": {
      return {
        ...state,
        cfRequestQueue: action.orignalRequests,
      };
    }

    case "MAIN/SET_EMBEDDED_SCREEN_LOADING": {
      return {
        ...state,
        isEmbeddedScreenLoading: action.isLoading,
      };
    }

    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
}
