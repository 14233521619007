import { isEmptyObject } from "../helpers";

export const getIntegrationLabelFromGroup = (groupData = {}) => {
  let label = "";
  if (!!groupData?.ccb_status) {
    label = "CCB";
  } else if (!!groupData?.pco_status) {
    label = "PCO";
  } else if (!!groupData?.mc_status) {
    label = "MailChimp";
  } else if (!!groupData?.is_elvanto) {
    label = "Elvanto";
  } else if (!!groupData?.is_breeze) {
    label = "Breeze";
  } else if (!!groupData?.is_rock) {
    label = "Rock";
  }
  return label;
};

export const isSyncGroupInProgress = (group) => {
  return !!group?.sync_in_progress;
};

export const getPatchGroupErrorMessage = (patchQueue = {}, requestId = {}) => {
  let errorMessage = "";
  if (patchQueue && patchQueue[requestId] && patchQueue[requestId].error) {
    if (
      typeof patchQueue[requestId].error &&
      !isEmptyObject(patchQueue[requestId].error)
    ) {
      const error = Object.values(patchQueue[requestId].error);
      errorMessage = error[0];
    }
  }
  return errorMessage;
};

export const getRandomNumber = () => {
  return Math.floor(Math.random() * 1000) + 1;
};

export const isAddhocGroup = (groupData = {}) => {
  if (!groupData || isEmptyObject(groupData)) return false;
  return groupData?.addhoc_id > 0;
};

export const getRemoveFromGroupLabel = (groupData = {}) => {
  if (isAddhocGroup(groupData)) {
    return "Remove From Broadcast Group";
  }
  return "Remove From Group";
};

export const groupHasEligibleSyncWay = (groupData = {}) => {
  return (
    groupData.sync_way === "2-way" ||
    groupData.sync_way === "1-way-in" ||
    groupData.sync_way === "2-way-and-1-way-out"
  );
};
