/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/09/2018.
 */
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import * as serviceWorker from "./serviceWorker";
import { store, persistor } from "./store";
import { CookiesProvider } from "react-cookie";
import ErrorBoundary from "./components/ErrorBoundary";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import { generalTheme } from "./assets/base/theme";
import "animate.css";
import "./utils/extendFuncs";
import RootRouter from "./rootRouter";
import ThemeProvider from "./ThemeContext/ThemeProvider";

function launchApp(swObject) {
  ReactDOM.render(
    <Provider store={store}>
      <ErrorBoundary>
        <PersistGate loading={null} persistor={persistor}>
          <CookiesProvider>
            <MuiThemeProvider theme={generalTheme}>
              <ThemeProvider>
                <RootRouter swObject={swObject} />
              </ThemeProvider>
            </MuiThemeProvider>
          </CookiesProvider>
        </PersistGate>
      </ErrorBoundary>
    </Provider>,
    document.getElementById("root")
  );
}

launchApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
