import {
  ready,
  unsupportedBrowser,
  call,
  callStatus,
  callTtv,
  callTtvStatus,
} from "./actions/twilio";
const fromEntries = require("fromentries");

const Device = require("twilio-client").Device;

export default class TwilioConfig {
  static _initialized = false;
  static _company = null;
  static _user = null;
  static _dispatchProxy = null;

  static isInitialized() {
    return TwilioConfig._initialized;
  }

  static init(token, company, user, dispatchProxy) {
    if (TwilioConfig._initialized) {
      Device.disconnectAll();
    }

    TwilioConfig._company = company;
    TwilioConfig._user = user;
    TwilioConfig._dispatchProxy = dispatchProxy;

    try {
      Device.setup(token, { debug: false });
    } catch (exc) {
      if (exc && exc.message && exc.message.indexOf("WebRTC") !== -1) {
        console.log("TWILIO_CONFIG: unsupported browser (no WebRTC)");
        TwilioConfig._dispatchProxy(unsupportedBrowser());
      }
    }

    Device.ready(function (Device) {
      TwilioConfig._dispatchProxy(ready(true));
    });
    Device.error(function (error) {
      console.log("Twilio Client initialization error", error);
    });
    Device.connect(function (conn) {
      const params = fromEntries(conn.customParameters);
      if (params.mode === "call") {
        TwilioConfig._dispatchProxy(callStatus("connected"));
      } else if (params.mode === "ttv") {
        TwilioConfig._dispatchProxy(callTtvStatus("connected"));
      }
    });
    Device.disconnect(function (conn) {
      const params = fromEntries(conn.customParameters);
      if (params.mode === "call") {
        TwilioConfig._dispatchProxy(call(null, null, null, null));
      } else if (params.mode === "ttv") {
        TwilioConfig._dispatchProxy(callTtv(null, null, null, null, null));
      }
      //todo: update credits count
    });
    Device.incoming(function (conn) {
      console.log("TWILIO_CONFIG: incoming");
      console.log("Incoming connection from " + conn.parameters.From);
      // Uncomment below to accept the incoming connection and start two-way audio
      // conn.accept();
    });

    TwilioConfig._initialized = true;
  }

  static callContact(fromNumber, contactId, contactPhoneNumber, minutePrice) {
    const params = {
      mode: "call",
      from: "+" + fromNumber,
      con_id: contactId,
      to: "" + contactPhoneNumber,
      uid: TwilioConfig._user.id,
      cid: TwilioConfig._company.id,
      CallerCountry: "US",
    };
    TwilioConfig._dispatchProxy(
      call(fromNumber, contactId, minutePrice, "connecting")
    );
    Device.connect(params);
  }

  static callTtvPreview(fromNumber, text, language = "en-US", voice = "man") {
    const params = {
      mode: "ttv",
      from: "+" + fromNumber,
      text,
      language,
      voice,
      uid:
        TwilioConfig._company.role === 2
          ? TwilioConfig._company.id
          : TwilioConfig._user.id,
      cid: TwilioConfig._company.id,
      CallerCountry: "US",
    };
    TwilioConfig._dispatchProxy(
      callTtv(fromNumber, text, language, voice, "connecting")
    );
    Device.connect(params);
  }

  static disconnect() {
    Device.disconnectAll();
    TwilioConfig._dispatchProxy(call(null, null, null, null));
  }
}
