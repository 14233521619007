import { resetFetchGroupsRequests } from "../actions/groups";
import {
  toggleMarkAsFavoriteGroup,
  softDeleteContactsMapper,
  restoreDeletedContactsMapper,
  appendAddNewToDataObject,
} from "../helpers";
import { history } from "../store";

const fromEntries = require("fromentries");

const getUpdatedMembersCounts = (state, propCounter, isAdding, count) => {
  const matcher = history.location.pathname.match(/\/group\/([\w-]+)/);
  let dataGroup = {};
  if (matcher && matcher.length === 2) {
    const groupId = Number(matcher[1]);
    const newCount = isAdding
      ? state.data[groupId][propCounter] + (count || 1)
      : state.data[groupId][propCounter] - (count || 1);

    dataGroup = {
      [groupId]: {
        ...state.data[groupId],
        [propCounter]: newCount < 0 ? 0 : newCount,
      },
    };
  }

  return { ...state.data, ...dataGroup };
};

const getUpToDateGroups = (state, groups) => {
  return Object.keys(groups).reduce((prev, cur) => {
    if (state.data[cur] && state.data[cur].__full) {
      return {
        ...prev,
        [cur]: state.data[cur],
      };
    } else {
      return {
        ...prev,
        [cur]: groups[cur],
      };
    }
  }, {});
};

const deleteRestoreBasedOnAction = (
  state,
  actionContactIds,
  isUndo,
  actionName
) => {
  const matcher = history.location.pathname.match(/hub\/[\w/]+\/group\/(\d+)/);

  let members = state.members;
  let filteredMembers = state.filteredMembers;

  if (matcher && matcher[1] && isUndo) {
    filteredMembers = state.filteredMembers.map(
      restoreDeletedContactsMapper(actionName)
    );
  } else if (matcher && matcher[1]) {
    filteredMembers = state.filteredMembers.map(
      softDeleteContactsMapper(actionContactIds, actionName)
    );
  }

  return {
    members,
    filteredMembers,
  };
};

const deleteRestoreGroupsBasedOnAction = (
  state,
  actionGroupIds,
  isUndo,
  actionName
) => {
  const isOnTags = history.location.pathname.match(
    /\/hub\/[\w/]+\/tags(\/(\d+))?/
  );

  let groupIds = state.groups;
  let filteredGroupIds = state.filteredGroups;
  if (isOnTags) {
    if (state.filter && isUndo) {
      filteredGroupIds = actionGroupIds.concat(state.filteredGroups);
    } else {
      filteredGroupIds = state.filteredGroups.filter(
        (id) => !actionGroupIds.includes(id)
      );
    }
  } else if (state.filter == null && !isOnTags && isUndo) {
    groupIds = state.groups.map(restoreDeletedContactsMapper(actionName));
  } else if (state.filter == null && !isOnTags) {
    if (state.groups?.length) {
      groupIds = state.groups.map(
        softDeleteContactsMapper(actionGroupIds, actionName)
      );
    }
  } else {
    if (state.filteredGroups?.length) {
      filteredGroupIds = state.filteredGroups.map(
        softDeleteContactsMapper(actionGroupIds, actionName)
      );
    }
  }
  return {
    groupIds,
    filteredGroupIds,
  };
};

const setLocallyRemovedMembers = (
  contacts = {},
  membersLocallyRemoved,
  members,
  groupId
) => {
  Object.keys(contacts).forEach((contactId) => {
    const contact = contacts[contactId];
    if (contact) {
      const group = contact.contact_groups?.find(
        (contactGroup) => contactGroup.group.id === parseInt(groupId)
      );
      if (group?.sync_state === "locally-removed") {
        membersLocallyRemoved.push(contactId);
        if (
          members &&
          Array.isArray(members) &&
          members.includes(Number(contactId))
        ) {
          const duplicateValueIndex = members.indexOf(Number(contactId));
          if (duplicateValueIndex > -1) {
            members.splice(duplicateValueIndex, 1);
          }
        }
      }
    }
  });
};

const initialState = {
  // Groups data
  data: {},
  dataStatus: {},
  dataMembersStatus: {},
  dataErrorStatus: {},

  // List
  groups: null,
  groupsLoadingMoreStatus: null,
  groupsStatus: null,
  groupsPage: null,
  groupsLoadedAll: null,
  groupsTotal: null,
  groupsIsNewGroup: null,

  // Filtered list
  filteredGroups: null,
  filteredGroupsLoadingMoreStatus: null,
  filteredGroupsStatus: null,
  filteredGroupsPage: null,
  filteredGroupsLoadedAll: null,
  filteredGroupsTotal: null,
  externalResults: {},

  // Members
  members: {},
  membersNotMembersIds: [],
  membersStatus: {},
  membersLoadingMoreStatus: {},
  membersPage: {},
  membersLoadedAll: {},
  membersHasArchived: false,
  membersHasBlocked: false,
  membersCount: 0,
  currentMemberFilters: null,

  // Filtered members
  filteredMembers: null,
  filteredMembersNotMembersIds: [],
  filteredMembersStatus: null,
  filteredMembersLoadingMoreStatus: null,
  filteredMembersPage: null,
  filteredMembersLoadedAll: null,
  filteredMembersOrderBy: null,
  filteredMembersCount: null,
  filteredMembersAdvFilters: [],
  membersLocallyRemoved: [],
  filteredMemberGroupId: null,
  filteredMemberIdsForMobileMode: [],

  // Patch group
  patchQueue: {},
  patchQueueUpdated: 0,

  // Add group
  addId: null,
  addStatus: null,
  addErrors: null,

  // Ext apps data
  extAppsDataStatus: null,
  ccbCampuses: null,
  ccbGroups: null,
  pcoLists: null,
  pcoWorkflows: null,
  elvantoGroups: null,
  mcLists: null,
  breezeTags: null,
  breezeEvents: null,
  rockRmsGroups: null,

  // Patch members
  patchMembersStatus: null,

  // Check keywords
  checkKeywordsResults: null,
  checkKeywordsStatus: null,
  checkKeywordsUid: null,

  unclassifiedGroupId: null,
  myContactGroupId: null,

  filter: null,

  // Group Automated Messages Campaign
  amCampaigns: null,
  amCampaignsStatus: null,
  amCampaignsPage: null,
  amCampaignsQuery: "",
  amCampaignsLoadedAll: null,
  amCampaignsGroupId: null,

  // Edit group Automated Messages Campaign
  patchAmCampaignsStatus: null,

  toggleGroupModalFromList: false,
  groupModalListItems: {
    contactIds: [],
    groupIds: [],
    contactIdsToCount: [],
    options: {},
    source_group_id: null,
  },

  // Skip Sending RHS
  skipSendingTime: "",
  actionGroupMembersData: {},
  acionGroupData: {},
  subMembersData: {},
  typeOfMembersAction: "",

  // archive/unarchive
  archiveGroupsStatus: "",
  archiveNeedRouting: false,
  archivingIds: [],
  archivedSuccessGroups: [],
  archivedContactsInGroups: [],
  archivedDuplicatesInGroups: [],
  archivedDuplicatesData: [],
  primaryAssignees: [],
  archivalGroupSnackbarData: {},

  unarchiveGroupStatus: "",
  unarchivingIds: [],
  unarchivedSuccessGroups: [],
  unarchivedContactsInGroups: [],
  unarchivedDuplicatesInGroups: [],
  unarchivedDuplicatesData: [],
  unarchivalSnackbarData: {},
  infoSnackbarStatus: "",

  // deletion
  deleteGroupsStatus: "",
  deleteNeedRouting: false,
  deletingIds: [],
  deletedSuccessGroups: [],
  deletedSuccessGroupMembers: [],
  deletionMembersStats: {},
  deletionMembersArchivedPl: {},
  deletionMembersUnarchivedPl: {},
  deletionMembersNonPl: {},
  deletionSnackbarData: {},

  // bulk edit
  bulkEditFieldQueue: {},
  markAsFavoriteSnackbarData: {},
  markAsUnFavoriteSnackbarData: {},

  // select group as active (for copy to group modal)
  onSelectedId: null,
  copyToGroupSnackbarData: {},

  // sync group
  syncGroupStatus: null,
  syncNowGroupsSnackbarData: {},

  /* filter count */
  countStatus: null,
  counts: {
    all: null,
    favorites: null,
    archived: null,
    archivedBlocked: null,
    archivedNoIntegration: null,
    archivedCCB: null,
    archivedPCO: null,
    archivedRock: null,
    archivedElvanto: null,
    archivedMailchimp: null,
    archivedBreeze: null,
  },

  // members filter
  membersFilter: "",
  membersAdvanceFilter: [],

  // delete group
  deleteGroupStatus: "",

  // export all members from group
  exportAllMembers: {},
  exportAllMembersGroupIds: [],

  // copy to, groups
  copyToGroupsStatus: "",
  copyToGroupsLoadingMoreStatus: "",
  copyToGroups: [],
  copyToGroupsPage: null,
  copyToGroupsTotal: null,

  groupThreadFirstTimeClicked: false,
  groupTableFirstTimeClicked: false,
  groupViewExpanded: false,

  isGroupAllMembersSelected: false,

  //detail logs
  details: null,
  detailsStatus: null,

  // initial groups fetch flag
  initialFetch: false,

  // ccb integrations
  ccbProcesses: {
    pagination: {},
    processes: [],
  },
  fetchCCBProcessQueuesStatus: "",
  resyncFetchCCBProcessQueuesStatus: "",
  fetchCCBProcessQueuesMoreStatus: "",

  // SAVED SEARCHES
  ccbSavedSearches: {},
  fetchCCBSavedSearchesStatus: "",
  fetchCCBSavedSearchesMoreStatus: "",
  resyncFetchCCBSavedSearchStatus: "",
  groupSearch: "",

  // CCB GROUPS
  fetchCCBGroupsStatus: "",
  fetchCCBGroupsMoreStatus: "",
  resyncFetchCCBGroupstatus: "",

  // PCO
  fetchPcoListsStatus: "",
  resyncPcoListsStatus: "",
  fetchPcoListsMoreStatus: "",

  // PCO WORKFLOW
  fetchPcoWorkflowsStatus: "",
  fetchPcoWorkflowsMoreStatus: "",
  resyncPcoWorkflowStatus: "",

  // Elvanto
  fetchElvantoGroupsStatus: "",
  resyncElvantoGroupStatus: "",
  fetchElvantoGroupMoreStatus: "",

  // Breeze
  fetchBreezeEventsStatus: "",
  resyncBreezeEventStatus: "",
  fetchBreezeEventMoreStatus: "",

  // Breeze tags
  fetchBreezeTagsStatus: "",
  resyncBreezeTagsStatus: "",
  fetchBreezeTagsMoreStatus: "",

  // Mailchimp list
  fetchMailChimpListsStatus: "",
  resyncMailChimpListsStatus: "",
  fetchMailChimpListsMoreStatus: "",

  // Mailchimp group
  fetchMailChimpGroupsStatus: "",
  resyncMailChimpGroupsStatus: "",
  fetchMailChimpGroupsMoreStatus: "",

  // Rock rms group
  fetchRockrmsGroupsStatus: "",
  resyncRockrmsGroupStatus: "",
  fetchRockrmsGroupMoreStatus: "",

  // tags to apply on people
  tagsToApply: {},
  fetchTagsToApplyStatus: "",

  // Prefill integration group
  prefillIntegrationGroup: {},

  // table view for groups
  allGroupsSelected: false,

  // Never synced in external apps list
  neverSyncedLoadingMoreStatus: null,
  neverSyncedLoadedAll: null,
  neverSyncedStatus: null,
  neverSyncedPage: null,
  neverSyncedExternal: [],
  internalResults: {},

  // Refresh Never Synced
  refreshNeverSyncedStatus: "",

  // Archive then delete group
  archiveThenDeleteStatus: "",

  // Shareable groups
  shareableGroups: [],
  fetchShareableGroupsStatus: "",
};

export default function reducer(state = initialState, action) {
  let data = null;
  let dataStatus = null;
  let dataMembersStatus = null;
  let groups = null;
  let members = null;
  let membersStatus = null;
  let membersLoadingMoreStatus = null;
  let membersNotMembersIds = null;
  let membersLocallyRemoved = [];
  let membersPage = null;
  let membersLoadedAll = null;
  let patchQueue = null;
  let patchQueueUpdated = null;
  let amCampaigns = null;
  let bulkEditFieldQueue = null;

  switch (action.type) {
    case "GROUPS/FETCH.INITIAL_FETCH_FLAG":
      return {
        ...state,
        initialFetch: action.fetched,
      };
    // List
    case "GROUPS/FETCH":
      return {
        ...state,
        groupsLoadingMoreStatus: action.page > 1 ? "loading" : null,
        groupsStatus: "loading",
      };
    case "GROUPS/FETCH.SUCCESS":
      const newData = getUpToDateGroups(state, action.payload.groups);

      data = { ...state.data, ...newData };
      dataStatus = {
        ...state.dataStatus,
        ...fromEntries(
          new Map(
            Object.keys(action.payload.groups).map((item, index) => {
              return [index, "success"];
            })
          )
        ),
      };

      groups = action.payload.groups_ids;
      if (state.groups && state.groupsPage + 1 === action.page) {
        let prevGroups = [];
        for (let i = 0; i < state.groups.length; i++) {
          if (groups.indexOf(state.groups[i]) === -1) {
            prevGroups.push(state.groups[i]);
          }
        }
        groups = prevGroups.concat(groups);
      }

      return {
        ...state,
        data: appendAddNewToDataObject(
          { ...state.data },
          action.payload.groups
        ),
        dataStatus: dataStatus,
        groups: groups,
        groupsLoadingMoreStatus: action.page > 1 ? "success" : null,
        groupsStatus: "success",
        groupsPage: action.page,
        groupsLoadedAll: action.payload.groups_ids.length === 0,
        groupsTotal: action.payload.total,
        filteredGroupsTotal: action.payload.total,
      };
    case "GROUPS/FETCH.ERROR":
      return {
        ...state,
        groups: null,
        groupsLoadingMoreStatus: action.page > 1 ? "error" : null,
        groupsStatus: "error",
        groupsPage: null,
        groupsLoadedAll: null,
        groupsTotal: null,
      };

    // Filtered list
    case "GROUPS/FETCH_TAGS":
    case "GROUPS/FETCH_FILTERED":
      return {
        ...state,
        filteredGroupsLoadingMoreStatus: action.page > 1 ? "loading" : null,
        filteredGroupsStatus: "loading",
      };
    case "GROUPS/FETCH_TAGS.SUCCESS":
    case "GROUPS/FETCH_FILTERED.SUCCESS":
      const newDataFiltered = getUpToDateGroups(state, action.payload.groups);

      data = { ...state.data, ...newDataFiltered };
      dataStatus = {
        ...state.dataStatus,
        ...fromEntries(
          new Map(
            Object.keys(action.payload.groups).map((item, index) => {
              return [index, "success"];
            })
          )
        ),
      };
      if (action.page === 1 || !state.filteredGroups) {
        groups = action.payload.groups_ids;
      } else {
        groups = state.filteredGroups.concat(action.payload.groups_ids);
      }
      return {
        ...state,
        data: appendAddNewToDataObject(
          { ...state.data },
          action.payload.groups
        ),
        dataStatus: dataStatus,
        filteredGroups: groups,
        filteredGroupsLoadingMoreStatus: action.page > 1 ? "success" : null,
        filteredGroupsStatus: "success",
        filteredGroupsPage: action.page,
        filteredGroupsLoadedAll: action.payload.groups_ids.length === 0,
        filteredGroupsTotal: action.payload.total,
        externalResults:
          action.page === 1 && action.query && action.payload?.external_results
            ? action.payload?.external_results
            : state.externalResults,
      };

    case "GROUPS/FETCH_TAGS.ERROR":
    case "GROUPS/FETCH_FILTERED.ERROR":
      return {
        ...state,
        filteredGroups: null,
        filteredGroupsLoadingMoreStatus: action.page > 1 ? "error" : null,
        filteredGroupsStatus: "error",
        filteredGroupsPage: null,
        filteredGroupsLoadedAll: null,
        filteredGroupsTotal: null,
      };

    // Fetch group data
    case "GROUPS/FETCH_DATA":
      dataStatus = state.dataStatus;
      dataStatus[action.groupId] = "loading";
      return {
        ...state,
        dataStatus: dataStatus,
      };
    case "GROUPS/FETCH_DATA.SUCCESS":
      data = state.data;
      let tagName = "";
      if (!!state.data[action.groupId]?.tag) {
        tagName = state.data[action.groupId].tag.tag_name;
      }
      if (!!state.data[action.groupId]?.tag_name) {
        tagName = state.data[action.groupId].tag_name;
      }
      dataStatus = state.dataStatus;
      data[action.groupId] = data[action.groupId]
        ? { ...data[action.groupId], ...action.payload.group }
        : action.payload.group;
      data[action.groupId]["__full"] = true;
      data[action.groupId]["__switchedCompanyId"] =
        action.payload.switchedCompanyId;
      dataStatus[action.groupId] = "success";
      data[action.groupId]["tag_name"] = tagName;

      groups = state.groups;
      if (groups) {
        const groupFromList = groups.find(
          (g) =>
            g?.id === Number(action.groupId) || g === Number(action.groupId)
        );
        if (groups && !groupFromList && !groupFromList?.actionName) {
          groups.unshift(action.groupId);
        }
      }

      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        groups: groups,
      };
    case "GROUPS/FETCH_DATA.ERROR":
      dataStatus = state.dataStatus;
      dataStatus[action.groupId] = "error";
      return {
        ...state,
        dataStatus: dataStatus,
        dataErrorStatus: {
          ...state.dataErrorStatus,
          [action.groupId]: action.payload.request.status,
        },
      };

    case "GROUPS/FETCH_DATA.CLEAR":
      dataStatus = state.dataStatus;
      dataStatus[action.groupId] = "";
      return {
        ...state,
        dataStatus: dataStatus,
        dataErrorStatus: {
          ...state.dataErrorStatus,
          [action.groupId]: 0,
        },
      };

    case "GROUPS/FETCH_DATA_MEMBERS_STATUSES":
      return {
        ...state,
        dataMembersStatus: { [action.groupId]: "loading" },
      };

    case "GROUPS/FETCH_DATA_MEMBERS_STATUSES.SUCCESS":
      return {
        ...state,
        data: state.data[action.groupId]
          ? {
              ...state.data,
              [action.groupId]: {
                ...state.data[action.groupId],
                ...action.payload.data,
              },
            }
          : {
              ...state.data,
              [action.groupId]: action.payload.data,
            },
        dataMembersStatus: { [action.groupId]: "success" },
      };

    case "GROUPS/FETCH_DATA_MEMBERS_STATUSES.ERROR":
      return {
        ...state,
        dataMembersStatus: { [action.groupId]: "error" },
      };

    // Members
    case "GROUPS/MEMBERS": {
      const cloneMemberStatuses = { ...state.membersStatus };
      const cloneMembersLoadingMoreStatuses = {
        ...state.membersLoadingMoreStatus,
      };
      cloneMemberStatuses[action.groupId] = "loading";
      cloneMembersLoadingMoreStatuses[action.groupId] =
        action.page > 1 ? "loading" : undefined;
      return {
        ...state,
        membersStatus: cloneMemberStatuses,
        membersLoadingMoreStatus: cloneMembersLoadingMoreStatuses,
      };
    }

    case "GROUPS/MEMBERS.SUCCESS": {
      members = { ...state.members };
      membersNotMembersIds = [...state.membersNotMembersIds];
      membersStatus = { ...state.membersStatus };
      membersLoadingMoreStatus = { ...state.membersLoadingMoreStatus };
      membersPage = { ...state.membersPage };
      membersLoadedAll = { ...state.membersLoadedAll };
      if (
        action.page === 1 ||
        typeof members[action.groupId] === "undefined" ||
        !members[action.groupId]
      ) {
        members[action.groupId] = action.payload.contacts_ids;
        membersNotMembersIds = action.payload.not_members_contact_ids;
      } else {
        members[action.groupId] = members[action.groupId].concat(
          action.payload.contacts_ids
        );
        membersNotMembersIds = membersNotMembersIds?.concat(
          action.payload.not_members_contact_ids
        );
      }
      membersStatus[action.groupId] = "success";
      membersLoadingMoreStatus[action.groupId] =
        action.page > 1 ? "success" : undefined;
      membersPage[action.groupId] = action.page;
      membersLoadedAll[action.groupId] =
        action.limit === 999999 || action.payload.contacts_ids.length === 0;
      return {
        ...state,
        members,
        membersNotMembersIds,
        membersStatus,
        membersPage,
        membersLoadedAll,
        membersLoadingMoreStatus,
        membersHasArchived: action.payload.has_archived,
        membersHasBlocked: action.payload.has_blocked,
        membersCount: action.payload.count || 0,
        currentMemberFilters: Boolean(action.filters) || action?.filtersAdv,
      };
    }

    case "GROUPS/MEMBERS.ERROR":
      members = { ...state.members };
      membersStatus = { ...state.membersStatus };
      membersLoadingMoreStatus = { ...state.membersLoadingMoreStatus };
      membersPage = { ...state.membersPage };
      membersLoadedAll = { ...state.membersLoadedAll };

      members[action.groupId] = null;
      membersStatus[action.groupId] = "error";
      membersLoadingMoreStatus[action.groupId] =
        action.page > 1 ? "error" : undefined;
      membersPage[action.groupId] = null;
      membersLoadedAll[action.groupId] = false;

      return {
        ...state,
        members: members,
        membersStatus: membersStatus,
        membersPage: membersPage,
        membersLoadedAll: membersLoadedAll,
        membersLoadingMoreStatus,
      };

    case "GROUPS/SET_CURRENT_MEMBER_FITERS": {
      return {
        ...state,
        currentMemberFilters: action.filter,
      };
    }

    case "CONTACTS/UNSUBSCRIBE_CONTACTS.SUCCESS": {
      const dataCopy = getUpdatedMembersCounts(
        state,
        "members_unsubscribed_count",
        true,
        action?.payload?.contactIds?.length
      );

      for (let groupId of action.payload.groupIds) {
        dataCopy[groupId].all_unsubscribed = true;
      }

      return {
        ...state,
        data: dataCopy,
      };
    }

    case "CONTACTS/SUBSCRIBE_CONTACTS.SUCCESS": {
      const dataCopy = getUpdatedMembersCounts(
        state,
        "members_unsubscribed_count",
        false,
        action?.payload?.contactIds?.length
      );

      for (let groupId of action.payload.groupIds) {
        dataCopy[groupId].all_unsubscribed = false;
      }

      return {
        ...state,
        data: dataCopy,
      };
    }

    case "CONTACTS/BLOCK_CONTACTS.SUCCESS": {
      let dataCopy = getUpdatedMembersCounts(
        state,
        "members_blocked_count",
        true,
        action?.payload?.contactIds?.length
      );

      state.data = dataCopy;

      dataCopy = getUpdatedMembersCounts(
        state,
        "members_count",
        false,
        action?.payload?.contactIds?.length
      );

      for (let groupId of action.payload.groupIds) {
        dataCopy[groupId].all_blocked = true;
      }

      const { members, filteredMembers } = deleteRestoreBasedOnAction(
        state,
        action?.payload?.contactIds || [],
        action.isUndo,
        "block"
      );

      return {
        ...state,
        members,
        filteredMembers,
        data: dataCopy,
      };
    }

    case "CONTACTS/UNBLOCK_CONTACTS.SUCCESS": {
      let dataCopy = getUpdatedMembersCounts(
        state,
        "members_blocked_count",
        false,
        action?.payload?.contactIds?.length
      );

      state.data = dataCopy;

      dataCopy = getUpdatedMembersCounts(
        state,
        "members_count",
        true,
        action?.payload?.contactIds?.length
      );

      for (let groupId of action.payload.groupIds) {
        dataCopy[groupId].all_blocked = false;
      }

      const { members, filteredMembers } = deleteRestoreBasedOnAction(
        state,
        action?.payload?.contactIds || [],
        action.isUndo,
        "block"
      );

      return {
        ...state,
        members,
        filteredMembers,
        data: dataCopy,
      };
    }

    case "CONTACTS/DONOTCALL_CONTACTS.SUCCESS": {
      const dataCopy = getUpdatedMembersCounts(
        state,
        "members_donotcall_count",
        true,
        action?.payload?.contactIds?.length
      );

      return {
        ...state,
        data: dataCopy,
      };
    }

    case "CONTACTS/RESET_DONOTCALL_CONTACTS.SUCCESS": {
      const dataCopy = getUpdatedMembersCounts(
        state,
        "members_donotcall_count",
        false,
        action?.payload?.contactIds?.length
      );

      return {
        ...state,
        data: dataCopy,
      };
    }

    case "CONTACTS/ARCHIVE_CONTACTS.SUCCESS": {
      const { members, filteredMembers } = deleteRestoreBasedOnAction(
        state,
        action?.contactIds || [],
        action.isUndo,
        "archive"
      );

      let dataCopy = getUpdatedMembersCounts(
        state,
        "members_count",
        false,
        action?.allParams?.count
          ? action?.allParams?.count
          : action?.payload?.contactIds?.length
      );

      dataCopy = getUpdatedMembersCounts(
        { ...state, data: dataCopy },
        "members_archived_count",
        true,
        action?.allParams?.count
          ? action?.allParams?.count
          : action?.payload?.contactIds?.length
      );

      return {
        ...state,
        members,
        filteredMembers,
        data: dataCopy,
      };
    }

    case "CONTACTS/UNARCHIVE_CONTACTS.SUCCESS": {
      const { members, filteredMembers } = deleteRestoreBasedOnAction(
        state,
        action?.contactIds || [],
        action.isUndo,
        "archive"
      );

      let dataCopy = getUpdatedMembersCounts(
        state,
        "members_count",
        true,
        action?.allParams?.count
          ? action?.allParams?.count
          : action?.payload?.contactIds?.length
      );
      dataCopy = getUpdatedMembersCounts(
        { ...state, data: dataCopy },
        "members_archived_count",
        false,
        action?.allParams?.count
          ? action?.allParams?.count
          : action?.payload?.contactIds?.length
      );

      return {
        ...state,
        members,
        filteredMembers,
        data: dataCopy,
      };
    }

    // Filtered members
    case "GROUPS/FILTERED_MEMBERS":
      return {
        ...state,
        filteredMembersStatus: "loading",
        filteredMembersLoadingMoreStatus: action.page > 1 ? "loading" : null,
      };

    case "GROUPS/FILTERED_MEMBERS.SUCCESS":
      if (action.page === 1 || !state.filteredMembers) {
        if (
          (action.filtersAdv.length > 1 &&
            action.filtersAdv[1][1] !== "members-locally-removed") ||
          action.filtersAdv.length === 0
        ) {
          members = action.payload.contacts_ids;
        }
        membersNotMembersIds = action.payload.not_members_contact_ids;
        setLocallyRemovedMembers(
          action.payload.contacts,
          membersLocallyRemoved,
          members,
          action.groupId
        );
      } else {
        if (
          (action.filtersAdv.length > 1 &&
            action.filtersAdv[1][1] !== "members-locally-removed") ||
          action.filtersAdv.length === 0
        ) {
          members = state.filteredMembers.concat(action.payload.contacts_ids);
        }
        membersNotMembersIds = state.filteredMembersNotMembersIds?.concat(
          action.payload.not_members_contact_ids
        );
        membersLocallyRemoved = state.membersLocallyRemoved;
        setLocallyRemovedMembers(
          action.payload.contacts,
          membersLocallyRemoved,
          members,
          action.groupId
        );
      }

      return {
        ...state,
        filteredMembers: members,
        filteredMembersNotMembersIds: membersNotMembersIds,
        filteredMembersStatus: "success",
        filteredMembersLoadingMoreStatus: action.page > 1 ? "success" : null,
        filteredMembersPage: action.page,
        filteredMembersLoadedAll: action.payload.contacts_ids.length === 0,
        filteredMembersOrderBy: action.orderBy,
        membersHasArchived: action.payload.has_archived,
        membersHasBlocked: action.payload.has_blocked,
        filteredMembersCount: action.payload.count,
        filteredMembersAdvFilters: action.filtersAdv,
        membersLocallyRemoved: membersLocallyRemoved,
        filteredMemberGroupId: action.groupId,
        filteredMemberIdsForMobileMode: action?.requestForMobileMode
          ? members
          : state.filteredMemberIdsForMobileMode,
      };
    case "GROUPS/FILTERED_MEMBERS.ERROR":
      return {
        ...state,
        filteredMembers: null,
        filteredMembersStatus: "error",
        filteredMembersLoadingMoreStatus: action.page > 1 ? "error" : null,
        filteredMembersPage: null,
        filteredMembersLoadedAll: null,
      };

    case "GROUPS/FILTERED_MEMBERS.CLEAR":
      return {
        ...state,
        filteredMembers: null,
        filteredMembersStatus: null,
        filteredMembersLoadingMoreStatus: null,
        filteredMembersPage: null,
        filteredMembersLoadedAll: null,
      };

    // Patch group
    case "GROUPS/PATCH":
      patchQueue = state.patchQueue ? state.patchQueue : {};
      patchQueue[action.requestId] = {
        groupId: action.groupId,
        patchData: action.patchData,
        status: "loading",
        error: null,
      };
      return {
        ...state,
        patchQueue: { ...patchQueue },
        patchQueueUpdated: state.patchQueueUpdated + 1,
      };
    case "GROUPS/PATCH.SUCCESS":
      data = { ...state.data };
      dataStatus = { ...state.dataStatus };
      data[action.groupId] = action.payload.group;
      data[action.groupId]["__full"] = true;
      dataStatus[action.groupId] = "success";

      patchQueue = state.patchQueue ? state.patchQueue : {};
      if (typeof patchQueue[action.requestId] !== "undefined") {
        patchQueue[action.requestId].status = "success";
        patchQueue[action.requestId].error = null;
        patchQueueUpdated = state.patchQueueUpdated + 1;
      }

      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        patchQueue: { ...patchQueue },
        patchQueueUpdated: patchQueueUpdated,
      };
    case "GROUPS/PATCH.ERROR":
      patchQueue = state.patchQueue ? state.patchQueue : [];
      if (typeof patchQueue[action.requestId] !== "undefined") {
        patchQueue[action.requestId].status = "error";
        patchQueue[action.requestId].error =
          action.payload && action.payload.message
            ? action.payload.message
            : null;
        if (action.payload && action.payload.message) {
          if (typeof action.payload.message == "string") {
            patchQueue[action.requestId].error = action.payload.message;
          } else if (
            typeof action.payload.message == "object" &&
            action.payload?.message?.hasOwnProperty(action.requestId)
          ) {
            patchQueue[action.requestId].error =
              action.payload?.message[action.requestId];
          }
        }
        patchQueueUpdated = state.patchQueueUpdated + 1;
      }
      return {
        ...state,
        patchQueue: { ...patchQueue },
        patchQueueUpdated: patchQueueUpdated,
      };
    case "GROUPS/PATCH.CLEAR": {
      patchQueue = { ...state.patchQueue };
      patchQueue[action.requestId].status = "";

      return {
        ...state,
        patchQueue,
      };
    }

    case "GROUPS/RESET_GROUP_PATCH_QUEUE": {
      return {
        ...state,
        patchQueue: {},
      };
    }

    // Add group
    case "GROUPS/ADD":
      return {
        ...state,
        addId: null,
        addStatus: "loading",
        addErrors: null,
      };
    case "GROUPS/ADD.SUCCESS":
      data = state.data;
      dataStatus = state.dataStatus;
      dataMembersStatus = state.dataMembersStatus;
      data[action.payload.group.id] = action.payload.group;
      data[action.payload.group.id]["__full"] = true;
      dataStatus[action.payload.group.id] = "success";
      dataMembersStatus[action.payload.group.id] = "success";

      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        dataMembersStatus,
        addId: action.payload.group.id,
        addStatus: "success",
        addErrors: null,
        groupsIsNewGroup: action.payload.is_new_group,
        // reset groups list
        groups: null,
        groupsLoadingMoreStatus: null,
        groupsStatus: null,
        groupsPage: null,
        groupsLoadedAll: null,
      };
    case "GROUPS/ADD.ERROR":
      return {
        ...state,
        addId: null,
        addStatus: "error",
        addErrors:
          action.payload && typeof action.payload.errors !== "undefined"
            ? action.payload.errors
            : null,
      };
    case "GROUPS/ADD.CLEAR":
      return {
        ...state,
        addId: null,
        addStatus: null,
        addErrors: null,
      };

    case "GROUPS/CLEAR_GROUP_IS_NEW_GROUP": {
      return {
        ...state,
        groupsIsNewGroup: null,
      };
    }

    case "GROUPS/ADD_STATUS.CLEAR":
      return {
        ...state,
        addStatus: null,
        addErrors: null,
      };

    // Ext apps data
    case "GROUPS/EXT_APPS_DATA":
      return {
        ...state,
        extAppsDataStatus: "loading",
      };
    case "GROUPS/EXT_APPS_DATA.SUCCESS":
      return {
        ...state,
        extAppsDataStatus: "success",
        ccbCampuses: action.payload.ccb_campuses,
        ccbGroups: action.payload.ccb_groups,
        pcoLists: action.payload.pco_lists,
        elvantoGroups: action.payload.elvanto_groups,
        mcLists: action.payload.mc_lists,
        breezeTags: action.payload.breeze_tags,
        breezeEvents: action.payload.breeze_events,
      };
    case "GROUPS/EXT_APPS_DATA.ERROR":
      return {
        ...state,
        extAppsDataStatus: "error",
        ccbCampuses: null,
        ccbGroups: null,
        pcoLists: null,
        elvantoGroups: null,
        mcLists: null,
        breezeTags: null,
        breezeEvents: null,
      };

    // Patch members
    case "GROUPS/PATCH_MEMBERS":
      return {
        ...state,
        patchMembersStatus: "loading",
      };
    case "GROUPS/PATCH_MEMBERS.SUCCESS":
      data = state.data;
      dataStatus = state.dataStatus;
      data[action.payload.group.id] = action.payload.group;
      data[action.payload.group.id]["__full"] = true;
      dataStatus[action.payload.group.id] = "success";
      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        patchMembersStatus: "success",
      };
    case "GROUPS/PATCH_MEMBERS.ERROR":
      return {
        ...state,
        patchMembersStatus: "error",
      };

    case "GROUPS/PATCH_MEMBERS.CLEAR": {
      return {
        ...state,
        patchMembersStatus: null,
      };
    }

    // Check keywords
    case "GROUPS/CHECK_KEYWORDS":
      return {
        ...state,
        checkKeywordsStatus: "loading",
        checkKeywordsUid: action.uid,
      };
    case "GROUPS/CHECK_KEYWORDS.SUCCESS":
      return {
        ...state,
        checkKeywordsResults: action.payload.results,
        checkKeywordsStatus: "success",
      };
    case "GROUPS/CHECK_KEYWORDS.ERROR":
      return {
        ...state,
        checkKeywordsResults: null,
        checkKeywordsStatus: "error",
      };
    case "GROUPS/CHECK_KEYWORDS.CLEAR":
      return {
        ...state,
        checkKeywordsResults: null,
        checkKeywordsStatus: null,
        checkKeywordsUid: null,
      };

    case "GROUPS/FILTER":
      return {
        ...state,
        filter: action.filter === "all" ? null : action.filter,
      };

    // Group Automated Messages Campaigns
    case "GROUPS/AM_CAMPAIGNS":
      return {
        ...state,
        amCampaignsStatus: "loading",
        amCampaignsGroupId: action.groupId,
      };
    case "GROUPS/AM_CAMPAIGNS.SUCCESS":
      if (action.page === 1 || !state.amCampaigns) {
        amCampaigns = action.payload.campaigns;
      } else {
        amCampaigns = state.amCampaigns.concat(action.payload.campaigns);
      }
      return {
        ...state,
        amCampaigns: amCampaigns,
        amCampaignsStatus: "success",
        amCampaignsPage: action.page,
        amCampaignsQuery: action.query,
        amCampaignsLoadedAll: action.payload.campaigns.length === 0,
        amCampaignsGroupId: action.groupId,
      };
    case "GROUPS/AM_CAMPAIGNS.ERROR":
      return {
        ...state,
        amCampaigns: null,
        amCampaignsStatus: "error",
        amCampaignsPage: null,
        amCampaignsQuery: "",
        amCampaignsLoadedAll: null,
        amCampaignsGroupId: action.groupId,
      };
    case "GROUPS/AM_CAMPAIGNS.CLEAR":
      return {
        ...state,
        amCampaigns: null,
        amCampaignsStatus: null,
        amCampaignsPage: null,
        amCampaignsQuery: "",
        amCampaignsLoadedAll: null,
        amCampaignsGroupId: null,
      };

    // Edit group Automated Messages Campaigns
    case "GROUPS/PATCH_AM_CAMPAIGNS":
      return {
        ...state,
        patchAmCampaignsStatus: "loading",
      };
    case "GROUPS/PATCH_AM_CAMPAIGNS.SUCCESS":
      data = state.data;
      dataStatus = state.dataStatus;
      data[action.groupId] = action.payload.group;
      data[action.groupId]["__full"] = true;
      dataStatus[action.groupId] = "success";
      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        patchAmCampaignsStatus: "success",
      };
    case "GROUPS/PATCH_AM_CAMPAIGNS.ERROR":
      return {
        ...state,
        patchAmCampaignsStatus: "error",
      };
    case "GROUPS/PATCH_AM_CAMPAIGNS.CLEAR":
      return {
        ...state,
        patchAmCampaignsStatus: null,
      };

    case "CONTACTS/GROUPS.SUCCESS":
      return {
        ...state,
        unclassifiedGroupId: action.payload.unclassifiedGroupId,
        myContactGroupId: action.payload.myContactGroupId,
      };

    case "GROUPS/RHS_GROUP_MEMBERS_ACTION":
      return {
        ...state,
        skipSendingTime: action.time,
        actionGroupMembersData: action.membersData,
        acionGroupData: action.groupData,
        subMembersData: action.subMembersData,
        typeOfMembersAction: action.typeOfAction,
      };

    case "FETCH_THREADS_SUCCESS":
    case "THREADS/VOICE_THREADS.SUCCESS":
    case "THREADS/SPECIAL.SUCCESS": {
      const newData = appendAddNewToDataObject(
        { ...state.data },
        action.payload.groups
      );

      return {
        ...state,
        data: newData,
        dataStatus: {
          ...state.dataStatus,
          ...fromEntries(
            new Map(
              Object.keys(action.payload.groups).map((item, index) => {
                return [index, "success"];
              })
            )
          ),
        },
      };
    }

    case "GROUPS/CREATE_GROUP_LIST":
      return {
        ...state,
        toggleGroupModalFromList: action.value,
        groupModalListItems: {
          ...initialState.groupModalListItems,
          ...action.selectedThreads,
        },
      };

    // Archive Groups
    case "GROUPS/ARCHIVE_GROUPS": {
      const archivingIds = state.archivingIds ? [...state.archivingIds] : [];
      archivingIds.push(...action.archivingIds);
      return {
        ...state,
        archiveGroupsStatus: "loading",
        archivingIds,
        archivedSuccessGroups: [],
        archiveNeedRouting: false,
      };
    }

    case "GROUPS/ARCHIVE_GROUPS.PROGRESS": {
      let archivingIds = state.archivingIds ? [...state.archivingIds] : [];
      const rmArchingIds = action.archivingIds ? action.archivingIds : [];
      archivingIds = archivingIds.filter((el) => !rmArchingIds.includes(el));

      return {
        ...state,
        archiveGroupsStatus: `progress-${action.workflow_id}`,
        // archivedSuccessGroups: [...action.groupIds],
        // primaryAssignees: [...action.primaryAssignees],
        // archivedContactsInGroups: [],
        archivedDuplicatesInGroups: { ...action.duplicates },
        archivedDuplicatesData: { ...action.contactsData },
        // archiveNeedRouting: action.needRouting,
        archivingIds,
      };
    }

    case "GROUPS/ARCHIVE_GROUPS.SUCCESS": {
      let archivingIds = state.archivingIds ? [...state.archivingIds] : [];
      const rmArchingIds = action.archivingIds ? action.archivingIds : [];
      archivingIds = archivingIds.filter((el) => !rmArchingIds.includes(el));

      const { groupIds, filteredGroupIds } = deleteRestoreGroupsBasedOnAction(
        state,
        action?.groupIds || [],
        action.isUndo,
        "archive"
      );
      return {
        ...state,
        archiveGroupsStatus: `success-${action.workflow_id}`,
        archivedSuccessGroups: [...action.groupIds],
        primaryAssignees: [...action.primaryAssignees],
        archivedContactsInGroups: [...action.contactIds],
        archivedDuplicatesInGroups: [],
        archivedDuplicatesData: [],
        archiveNeedRouting: action.needRouting,
        archivingIds,
        groups: groupIds,
        filteredGroups: filteredGroupIds,
      };
    }

    case "GROUPS/ARCHIVE_GROUPS.FAILURE": {
      let archivingIds = state.archivingIds ? [...state.archivingIds] : [];
      const rmArchingIds = action.archivingIds ? action.archivingIds : [];
      archivingIds = archivingIds.filter((el) => !rmArchingIds.includes(el));

      return {
        ...state,
        archiveGroupsStatus: `failure-${action.workflow_id}`,
        archivedSuccessGroups: [],
        archivingIds,
        archivedContactsInGroups: [],
        archivedDuplicatesInGroups: [],
        archivedDuplicatesData: [],
      };
    }

    // UnArchive Groups

    case "GROUPS/UNARCHIVE_GROUPS": {
      const unarchivingIds = state.unarchivingIds
        ? [...state.unarchivingIds]
        : [];
      unarchivingIds.push(...action.unarchivingIds);
      return {
        ...state,
        unarchiveGroupsStatus: "loading",
        unarchivingIds,
        unarchivedSuccessGroups: [],
        archiveNeedRouting: false,
      };
    }

    case "GROUPS/UNARCHIVE_GROUPS.PROGRESS": {
      let unarchivingIds = state.unarchivingIds
        ? [...state.unarchivingIds]
        : [];
      const rmUnarchingIds = action.unarchivingIds ? action.unarchivingIds : [];
      unarchivingIds = unarchivingIds.filter(
        (el) => !rmUnarchingIds.includes(el)
      );

      return {
        ...state,
        unarchiveGroupsStatus: `progress-${action.workflow_id}`,
        // unarchivedSuccessGroups: [...action.groupIds],
        // primaryAssignees: [...action.primaryAssignees],
        // unarchivedContactsInGroups: [],
        unarchivedDuplicatesInGroups: { ...action.duplicates },
        unarchivedDuplicatesData: { ...action.contactsData },
        // archiveNeedRouting: action.needRouting,
        unarchivingIds,
      };
    }

    case "GROUPS/UNARCHIVE_GROUPS.SUCCESS": {
      let unarchivingIds = state.unarchivingIds
        ? [...state.unarchivingIds]
        : [];
      const rmUnarchingIds = action.unarchivingIds ? action.unarchivingIds : [];
      unarchivingIds = unarchivingIds.filter(
        (el) => !rmUnarchingIds.includes(el)
      );
      const { groupIds, filteredGroupIds } = deleteRestoreGroupsBasedOnAction(
        state,
        action?.groupIds || [],
        action.isUndo,
        "archive"
      );
      return {
        ...state,
        unarchiveGroupsStatus: `success-${action.workflow_id}`,
        unarchivedSuccessGroups: [...action.groupIds],
        primaryAssignees: [...action.primaryAssignees],
        unarchivedContactsInGroups: [...action.contactIds],
        unarchivedDuplicatesInGroups: [],
        unarchivedDuplicatesData: [],
        archiveNeedRouting: action.needRouting,
        unarchivingIds,
        groups: groupIds,
        filteredGroups: filteredGroupIds,
      };
    }

    case "GROUPS/UNARCHIVE_GROUPS.FAILURE": {
      let unarchivingIds = state.unarchivingIds
        ? [...state.unarchivingIds]
        : [];
      const rmUnarchingIds = action.unarchivingIds ? action.unarchivingIds : [];
      unarchivingIds = unarchivingIds.filter(
        (el) => !rmUnarchingIds.includes(el)
      );
      return {
        ...state,
        unarchiveGroupsStatus: `failure-${action.workflow_id}`,
        unarchivedSuccessGroups: [],
        unarchivingIds,
        archivedContactsInGroups: [],
        archivedDuplicatesInGroups: [],
        archivedDuplicatesData: [],
      };
    }

    // Delete Groups
    case "GROUPS/DELETE_GROUPS": {
      const deletingIds = state.deletingIds ? [...state.deletingIds] : [];
      deletingIds.push(...action.deletingIds);
      return {
        ...state,
        deleteGroupsStatus: "loading",
        deletingIds,
        deletedSuccessGroups: [],
        deleteNeedRouting: false,
        deletionMembersArchivedPl: [],
        deletionMembersUnarchivedPl: [],
        deletionMembersNonPl: [],
      };
    }

    case "GROUPS/DELETE_GROUPS.FETCH_DATA": {
      return {
        ...state,
        deleteGroupsStatus: `fetching-data-${action.workflow_id}`,
        deletionMembersStats: action.membersStats,
        deletionMembersArchivedPl: [],
        deletionMembersUnarchivedPl: [],
        deletionMembersNonPl: [],
      };
    }

    case "GROUPS/DELETE_GROUPS.FETCH_MEMEBERS_DATA": {
      return {
        ...state,
        deleteGroupsStatus: `fetched-members-${action.workflow_id}`,
        deletionMembersArchivedPl: action.archivedPl
          ? action.archivedPl
          : state.deletionMembersArchivedPl,
        deletionMembersUnarchivedPl: action.unarchivedPl
          ? action.unarchivedPl
          : state.deletionMembersUnarchivedPl,
        deletionMembersNonPl: action.nonPl
          ? action.nonPl
          : state.deletionMembersNonPl,
      };
    }

    case "GROUPS/DELETE_GROUPS.SUCCESS": {
      let deletingIds = state.deletingIds ? [...state.deletingIds] : [];
      const rmDeletingIds = action.deletingIds ? action.deletingIds : [];
      deletingIds = deletingIds.filter((el) => !rmDeletingIds.includes(el));

      return {
        ...state,
        deleteGroupsStatus: `success-${action.workflow_id}`,
        deletedSuccessGroups: action.groupIds,
        deletedSuccessGroupMembers: action.contactIds,
        deleteNeedRouting: action.needRouting,
        deletingIds,
        deletionMembersArchivedPl: [],
        deletionMembersUnarchivedPl: [],
        deletionMembersNonPl: [],
      };
    }

    case "GROUPS/DELETE_GROUPS.FAILURE": {
      let deletingIds = state.deletingIds ? [...state.deletingIds] : [];
      const rmDeletingIds = action.deletingIds ? action.deletingIds : [];
      deletingIds = deletingIds.filter((el) => !rmDeletingIds.includes(el));

      return {
        ...state,
        deleteGroupsStatus: `failure-${action.workflow_id}`,
        deletedSuccessGroups: [],
        deletingIds,
        deletionMembersArchivedPl: [],
        deletionMembersUnarchivedPl: [],
        deletionMembersNonPl: [],
      };
    }

    case "GROUPS/ADD_SNACK_DATA": {
      const { entity, data } = action.payload;
      if (entity && entity === "archive-group") {
        const archivalGroupSnackbarData = {
          ...state.archivalGroupSnackbarData,
        };
        archivalGroupSnackbarData[data.id] = { ...data };
        return { ...state, archivalGroupSnackbarData };
      } else if (entity && entity === "unarchive-group") {
        const unarchivalSnackbarData = { ...state.unarchivalSnackbarData };
        unarchivalSnackbarData[data.id] = { ...data };
        return { ...state, unarchivalSnackbarData };
      } else if (entity && entity === "delete") {
        const deletionSnackbarData = { ...state.deletionSnackbarData };
        deletionSnackbarData[data.id] = { ...data };
        return { ...state, deletionSnackbarData };
      } else if (entity && entity === "groupMarkAsFavorite") {
        const markAsFavoriteSnackbarData = {
          ...state.markAsFavoriteSnackbarData,
        };
        markAsFavoriteSnackbarData[data.id] = { ...data };
        return { ...state, markAsFavoriteSnackbarData };
      } else if (entity && entity === "groupMarkAsUnFavorite") {
        const markAsUnFavoriteSnackbarData = {
          ...state.markAsUnFavoriteSnackbarData,
        };
        markAsUnFavoriteSnackbarData[data.id] = { ...data };
        return { ...state, markAsUnFavoriteSnackbarData };
      } else if (entity && entity === "syncNowGroups") {
        const syncNowGroupsSnackbarData = {
          ...state.syncNowGroupsSnackbarData,
        };
        syncNowGroupsSnackbarData[data.id] = { ...data };
        return { ...state, syncNowGroupsSnackbarData };
      } else if (entity && entity === "copyToGroup") {
        const copyToGroupSnackbarData = {
          ...state.copyToGroupSnackbarData,
        };
        copyToGroupSnackbarData[data.id] = { ...data };
        return { ...state, copyToGroupSnackbarData };
      }
      return { ...state };
    }

    case "GROUPS/REMOVE_SNACK_DATA": {
      const { entity, id } = action.payload;
      if (entity && entity === "archive-group") {
        const archivalGroupSnackbarData = {
          ...state.archivalGroupSnackbarData,
        };
        delete archivalGroupSnackbarData[id];
        return { ...state, archivalGroupSnackbarData };
      } else if (entity && entity === "unarchive-group") {
        const unarchivalSnackbarData = { ...state.unarchivalSnackbarData };
        delete unarchivalSnackbarData[id];
        return { ...state, unarchivalSnackbarData };
      } else if (entity && entity === "delete") {
        const deletionSnackbarData = { ...state.deletionSnackbarData };
        delete deletionSnackbarData[id];
        return { ...state, deletionSnackbarData };
      } else if (entity && entity === "groupMarkAsFavorite") {
        const markAsFavoriteSnackbarData = {
          ...state.markAsFavoriteSnackbarData,
        };
        delete markAsFavoriteSnackbarData[id];
        return { ...state, markAsFavoriteSnackbarData };
      } else if (entity && entity === "groupMarkAsUnFavorite") {
        const markAsUnFavoriteSnackbarData = {
          ...state.markAsUnFavoriteSnackbarData,
        };
        delete markAsUnFavoriteSnackbarData[id];
        return { ...state, markAsUnFavoriteSnackbarData };
      } else if (entity && entity === "syncNowGroups") {
        const syncNowGroupsSnackbarData = {
          ...state.syncNowGroupsSnackbarData,
        };
        delete syncNowGroupsSnackbarData[id];
        return { ...state, syncNowGroupsSnackbarData };
      } else if (entity && entity === "copyToGroup") {
        const copyToGroupSnackbarData = {
          ...state.copyToGroupSnackbarData,
        };
        delete copyToGroupSnackbarData[id];
        return { ...state, copyToGroupSnackbarData };
      }
      return { ...state };
    }

    case "GROUPS/COUNTS":
      return {
        ...state,
        countStatus: null,
      };
    case "GROUPS/COUNTS.SUCCESS": {
      const counts = {
        all: action.payload.all,
        favorites: action.payload.favorites,
        archived: action.payload.archived,
        archivedNoIntegration: action.payload["no-integration-archived"],
        archivedCCB: action.payload["ccb-archived"],
        archivedRock: action.payload["rock-archived"],
        archivedElvanto: action.payload["elvanto-archived"],
        archivedMailchimp: action.payload["mc-archived"],
        archivedBreeze: action.payload["breeze-archived"],
        pastorslineRegular: action.payload["no-integration-regular"],
        ccbRegular: action.payload["ccb-regular"],
        pcoRegular: action.payload["pco-regular"],
        rockRegular: action.payload["rock-regular"],
        elvantoRegular: action.payload["elvanto-regular"],
        mcRegular: action.payload["mc-regular"],
        breezeRegular: action.payload["breeze-regular"],
        plKeyword: action.payload["no-integration-keyword"],
        ccbKeyword: action.payload["ccb-keyword"],
        pcoKeyword: action.payload["pco-keyword"],
        rockKeyword: action.payload["rock-keyword"],
        elvantoKeyword: action.payload["elvanto-keyword"],
        mcKeyword: action.payload["mc-keyword"],
        breezeKeyword: action.payload["breeze-keyword"],
        unnamed: action.payload["unnamed"],
        imported: action.payload["imported"],
        intElvanto: action.payload["elvanto"],
        intElvantoUnsynced: action.payload["elvanto-unsynced"],
        intMailchimp: action.payload["mc"],
        intRock: action.payload["rock"],
        intMailchimpUnsynced: action.payload["mc-unsynced"],
        intRockUnsynced: action.payload["rock-unsynced"],
        intBreeze: action.payload["breeze"],
        intBreezeEvents: action.payload["breeze-events"],
        intBreezeTags: action.payload["breeze-tags"],
        intBreezeUnsynced: action.payload["breeze-unsynced"],
        intCCBProcessQueue: action.payload["ccb-process-queue"],
        intCCBProcessQueueOneWay: action.payload["ccb-pq-1-way-out"],
        intCCBProcessQueueTwoWay: action.payload["ccb-pq-2-way"],
        intCCBSavedSearches: action.payload["ccb-saved-search"],
        intCCBRegularGroups: action.payload["ccb-regular"],
        intCCBUnsynced: action.payload["ccb-unsynced"],
        intPCOLists: action.payload["pco-list"],
        intPCONoList: action.payload["pco-no-list"],
        intPCOUnsynced: action.payload["pco-unsynced"],
        tags: action.payload["tags"],
        named: action.payload["named"],
        unreadHold: action.payload["hold-all-unread"],
      };
      counts.regular =
        parseInt(counts.pastorslineRegular) +
        parseInt(counts.ccbRegular) +
        parseInt(counts.pcoRegular) +
        parseInt(counts.rockRegular) +
        parseInt(counts.elvantoRegular) +
        parseInt(counts.mcRegular) +
        parseInt(counts.breezeRegular);
      counts.keyword =
        parseInt(counts.plKeyword) +
        parseInt(counts.ccbKeyword) +
        parseInt(counts.pcoKeyword) +
        parseInt(counts.rockKeyword) +
        parseInt(counts.elvantoKeyword) +
        parseInt(counts.mcKeyword) +
        parseInt(counts.breezeKeyword);
      counts.type =
        parseInt(counts.regular) +
        parseInt(counts.keyword) +
        parseInt(counts.unnamed) +
        parseInt(counts.imported);
      counts.intCCB =
        parseInt(counts.intCCBProcessQueue) +
        parseInt(counts.intCCBSavedSearches) +
        parseInt(counts.intCCBRegularGroups);
      counts.intPCO =
        parseInt(counts.intPCOLists) + parseInt(counts.intPCONoList);
      counts.int =
        parseInt(counts.pastorslineRegular) +
        parseInt(counts.intElvanto) +
        parseInt(counts.intBreeze) +
        parseInt(counts.intMailchimp) +
        parseInt(counts.intRock) +
        parseInt(counts.intCCB) +
        parseInt(counts.intPCO);
      return {
        ...state,
        counts,
        countStatus: "success",
      };
    }

    case "GROUPS/COUNTS.FAILURE":
      return {
        ...state,
        countStatus: "failure",
      };

    // Clear data after logout and switch company
    case "USER_LOGOUT":
    case "SET_CURRENT_COMPANY":
      resetFetchGroupsRequests();
      return initialState;

    case "GROUPS/BULK_EDIT_FIELD": {
      bulkEditFieldQueue = { ...state.bulkEditFieldQueue };
      bulkEditFieldQueue[action.requestId] = {
        status: "loading",
      };
      return {
        ...state,
        bulkEditFieldQueue,
      };
    }

    case "GROUPS/BULK_EDIT_FIELD.SUCCESS": {
      bulkEditFieldQueue = { ...state.bulkEditFieldQueue };
      bulkEditFieldQueue[action.requestId].status = "success";

      for (let groupId of action.groupIds) {
        const dataGroup = { ...state.data[groupId] };

        toggleMarkAsFavoriteGroup(dataGroup);

        state.data[groupId] = dataGroup;
      }

      return {
        ...state,
        bulkEditFieldQueue,
      };
    }

    case "GROUPS/BULK_EDIT_FIELD.ERROR": {
      bulkEditFieldQueue = { ...state.bulkEditFieldQueue };
      bulkEditFieldQueue[action.requestId].status = "failure";

      return {
        ...state,
        bulkEditFieldQueue,
      };
    }

    case "GROUPS/BULK_EDIT_FIELD.CLEAR": {
      bulkEditFieldQueue = { ...state.bulkEditFieldQueue };
      bulkEditFieldQueue[action.requestId].status = "";

      return {
        ...state,
        bulkEditFieldQueue,
      };
    }

    case "GROUPS/BULK_SYNC": {
      return {
        ...state,
        syncGroupStatus: "loading",
      };
    }

    case "GROUPS/BULK_SYNC.SUCCESS": {
      let newData = { ...state.data };
      (action.syncQueuedGroupIds || []).forEach((syncedGroupId) => {
        newData[syncedGroupId] = {
          ...newData[syncedGroupId],
          sync_in_progress: true,
        };
      });
      return {
        ...state,
        syncGroupStatus: "success",
        data: newData,
      };
    }

    case "GROUPS/BULK_SYNC.ERROR": {
      return {
        ...state,
        syncGroupStatus: "failure",
      };
    }

    case "GROUPS/MEMBERS_FILTER": {
      return {
        ...state,
        membersFilter: action.filter,
      };
    }

    case "GROUPS/MEMBERS_ADVANCE_FILTER": {
      return {
        ...state,
        membersAdvanceFilter: action.filter,
      };
    }

    // Delete group
    case "GROUPS/DELETE":
      return {
        ...state,
        deleteGroupStatus: "loading",
      };

    case "GROUPS/DELETE.SUCCESS": {
      const index = state.filteredGroups.indexOf(action.groupId);
      state.filteredGroups.splice(index, 1);
      return {
        ...state,
        deleteGroupStatus: "success",
      };
    }

    case "GROUPS/DELETE.ERROR": {
      return {
        ...state,
        deleteGroupStatus: "error",
      };
    }

    case "GROUPS/DELETE.CLEAR":
      return {
        ...state,
        deleteGroupStatus: "",
      };

    case "GROUPS/SET_EXPORT_ALL":
      return {
        ...state,
        exportAllMembers: {
          ...state.exportAllMembers,
          [action.id]: {
            runWorkflow: action.runWorkflow,
            toastId: action.toastId,
          },
        },
      };

    case "GROUPS/ADD_TO_EXPORT_ALL":
      return {
        ...state,
        exportAllMembersGroupIds: [
          ...state.exportAllMembersGroupIds,
          action.id,
        ],
      };

    case "GROUPS/DELETE_EXPORT_ALL": {
      const exportAllMembersCopy = { ...state.exportAllMembers };
      delete exportAllMembersCopy[action.id];
      return {
        ...state,
        exportAllMembers: exportAllMembersCopy,
        exportAllMembersGroupIds: state.exportAllMembersGroupIds.filter(
          (id) => id !== action.id
        ),
      };
    }

    case "GROUPS/FETCH_COPY_TO": {
      return {
        ...state,
        copyToGroupsStatus: "loading",
        copyToGroupsLoadingMoreStatus: action.page > 1 ? "loading" : "",
      };
    }

    case "GROUPS/FETCH_COPY_TO.SUCCESS": {
      const copyToGroupsAux =
        action.page === 1
          ? action.groups
          : [...state.copyToGroups, ...action.groups];

      return {
        ...state,
        copyToGroupsStatus: "success",
        copyToGroupsLoadingMoreStatus: action.page > 1 ? "success" : "",
        copyToGroups: copyToGroupsAux,
        copyToGroupsPage: action.page,
        copyToGroupsTotal: action.total,
      };
    }

    case "GROUPS/FETCH_COPY_TO.FAILURE": {
      return {
        ...state,
        copyToGroupsStatus: "error",
        copyToGroupsLoadingMoreStatus: action.page > 1 ? "error" : "",
        copyToGroups: [],
        copyToGroupsPage: null,
        copyToGroupsTotal: null,
      };
    }

    case "TOGGLE_GROUP_FILTERS": {
      return {
        ...state,
        groupThreadFirstTimeClicked: !state.groupThreadFirstTimeClicked && true,
      };
    }

    case "TOGGLE_GROUP_VIEW_CLICKED": {
      // This case is for first time click on any part of HubThreadGroups component
      return {
        ...state,
        groupTableFirstTimeClicked: !state.groupTableFirstTimeClicked && true,
        groupViewExpanded: !state.groupTableFirstTimeClicked
          ? true
          : state.groupViewExpanded,
      };
    }

    case "GROUP/TOGGLE_GROUP_VIEW_EXPAND":
      // This case will toggle expanded state of HubThreadGroups component
      return {
        ...state,
        groupViewExpanded: !state.groupViewExpanded,
      };

    case "RESET_GROUP_VIEW_EXPAND":
      // This case will reset expanded state of HubThreadGroups component,
      // helpful specially at component HubThreadGroups unmount event
      return {
        ...state,
        groupViewExpanded: false,
      };

    case "CONTACTS/DELETE.SUCCESS": {
      if (action.groupId) {
        return {
          ...state,
          members: {
            ...state.members,
            [action.groupId]: state.members[action.groupId].filter(
              (memberId) => memberId !== action.contactId
            ),
          },
        };
      } else return state;
    }

    case "GROUP/ALL_GROUP_MEMBERS.SELECT": {
      return {
        ...state,
        isGroupAllMembersSelected: true,
      };
    }

    case "GROUP/ALL_GROUP_MEMBERS.UNSELECT":
    case "CONTACTS/GROUPS.RHS_SELECT":
    case "THREADS/SELECTING_ITEMS":
    case "CONTACTS/GROUPS.RHS_SELECTION_CLEARED": {
      return {
        ...state,
        isGroupAllMembersSelected: false,
      };
    }

    case "GROUP/UPDATE_MEMBER_COUNT.SET": {
      const dataCopy = getUpdatedMembersCounts(
        state,
        action.propCounter,
        action.isAdding,
        action.count
      );
      return {
        ...state,
        data: dataCopy,
      };
    }

    case "GROUPS/BULK_ADD_TAG": {
      return {
        ...state,
        bulkAddTagStatus: "loading",
      };
    }

    case "GROUPS/BULK_ADD_TAG.SUCCESS": {
      const newTags = {
        ...fromEntries(
          new Map(
            Object.keys(action.payload.added).map((tagId) => {
              return [
                action.payload.added[tagId].group_id,
                {
                  id: action.payload.added[tagId].group_id,
                  tag_id: Number(tagId),
                  tag_name: action.payload.added[tagId].tag_name,
                  members_count: 0,
                },
              ];
            })
          )
        ),
      };
      const newStateData = {
        ...newTags,
        ...state.data,
      };

      let newFilteredGroups = [];
      let newGroupIds = Object.keys(newTags).map((groupId) => Number(groupId));
      if (action.page === 1 || !state.filteredGroups) {
        newFilteredGroups = newGroupIds;
      } else {
        newFilteredGroups = state.filteredGroups;
        newGroupIds.map((id) => {
          newFilteredGroups.unshift(id);
          return id;
        });
      }

      return {
        ...state,
        data: newStateData,
        savedTags: action.payload.added,
        filteredGroups: newFilteredGroups,
        tagsAlreadyExist: action.payload.exists,
        bulkAddTagStatus: "success",
      };
    }

    case "GROUPS/BULK_ADD_TAG.ERROR": {
      return {
        ...state,
        bulkAddTagStatus: "error",
      };
    }
    case "GROUPS/RESET_TAGS_ALREADY_EXIST": {
      return {
        ...state,
        tagsAlreadyExist: null,
      };
    }
    case "GROUPS/RESET_SAVED_TAGS": {
      return {
        ...state,
        savedTags: null,
      };
    }

    case "GROUPS/CHECK_TAGS_EXIST": {
      return {
        ...state,
        checkTagsExistStatus: "loading",
      };
    }

    case "GROUPS/CHECK_TAGS_EXIST.SUCCESS": {
      return {
        ...state,
        tagsAlreadyExist: action.payload.exists,
        checkTagsExistStatus: "success",
      };
    }

    case "GROUPS/CHECK_TAGS_EXIST.ERROR": {
      return {
        ...state,
        checkTagsExistStatus: "error",
      };
    }
    case "GROUPS/FETCH_SHAREABLE_GROUPS": {
      return {
        ...state,
        fetchShareableGroupsStatus: "loading",
      };
    }
    case "GROUPS/FETCH_SHAREABLE_GROUPS.SUCCESS": {
      return {
        ...state,
        shareableGroups: action.payload,
        fetchShareableGroupsStatus: "success",
      };
    }
    case "GROUPS/FETCH_SHAREABLE_GROUPS.ERROR": {
      return {
        ...state,
        shareableGroups: [],
        fetchShareableGroupsStatus: "error",
      };
    }

    case "GROUPS/FETCH_SHAREABLE_GROUPS.CLEAR": {
      return {
        ...state,
        fetchShareableGroupsStatus: "",
      };
    }

    //Search groups
    case "GROUPS/SEARCH":
      return {
        ...state,
        groupSearch: action.payload,
      };

    // Never synced in external apps list
    case "GROUPS/NEVER_SYNCED":
      return {
        ...state,
        neverSyncedLoadingMoreStatus: action.page > 1 ? "loading" : null,
        neverSyncedStatus: "loading",
      };
    case "GROUPS/NEVER_SYNCED.SUCCESS":
      let neverSyncedExternal = [];
      if (action.page === 1) {
        neverSyncedExternal = action.payload?.never_synced_results;
      } else {
        neverSyncedExternal = state.neverSyncedExternal.concat(
          action.payload?.never_synced_results
        );
      }
      return {
        ...state,
        neverSyncedLoadingMoreStatus: action.page > 1 ? "success" : null,
        neverSyncedLoadedAll:
          action.payload?.never_synced_results?.length === 0,
        neverSyncedStatus: "success",
        neverSyncedPage: action.page,
        neverSyncedExternal,
        internalResults:
          action.page === 1 && action.payload?.internal_results
            ? action.payload?.internal_results
            : state.internalResults,
      };
    case "GROUPS/NEVER_SYNCED.ERROR":
      return {
        ...state,
        neverSyncedLoadingMoreStatus: action.page > 1 ? "error" : null,
        neverSyncedLoadedAll: null,
        neverSyncedStatus: "error",
      };

    case "GROUPS/REFRESH_NEVER_SYNCED":
      return {
        ...state,
        refreshNeverSyncedStatus: "loading",
      };

    case "GROUPS/REFRESH_NEVER_SYNCED.ERROR":
      return {
        ...state,
        refreshNeverSyncedStatus: "error",
      };

    case "GROUPS/SET_REFRESH_NEVER_SYNCED_STATUS":
      return {
        ...state,
        refreshNeverSyncedStatus: action.payload,
      };

    case "GROUPS/ARCHIVE_THEN_DELETE":
      return {
        ...state,
        archiveThenDeleteStatus: "loading",
      };

    case "GROUPS/ARCHIVE_THEN_DELETE.SUCCESS":
      return {
        ...state,
        archiveThenDeleteStatus: "success",
      };

    case "GROUPS/ARCHIVE_THEN_DELETE.ERROR":
      return {
        ...state,
        archiveThenDeleteStatus: "error",
      };

    //Detail logs
    case "GROUPS/DETAILS":
      return {
        ...state,
        detailsStatus: "loading",
      };

    case "GROUPS/DETAILS.SUCCESS":
      return {
        ...state,
        details: action.payload.changes_log,
        detailsStatus: "success",
      };
    case "GROUPS/DETAILS.ERROR":
      return {
        ...state,
        details: null,
        detailsStatus: "error",
      };

    case "GROUPS/MARK_AS_READ_GROUP_UNRESOLVED_CONFLICT.SUCCESS":
      return {
        ...state,
        data: {
          ...state.data,
          [action.groupId]: {
            ...state.data[action.groupId],
            sync_hold_contacts_read: 0,
          },
        },
      };

    case "GROUPS/FETCH_CCB_CAMPUSES": {
      return { ...state, fetchCCBCampusesStatus: "loading" };
    }
    case "GROUPS/FETCH_CCB_CAMPUSES.SUCCESS": {
      return {
        ...state,
        ccbCampuses:
          action.page > 1
            ? {
                pagination: action.payload.pagination,
                campuses: [
                  ...state.ccbCampuses.campuses,
                  ...action.payload.campuses,
                ],
              }
            : action.payload,
        fetchCCBCampusesStatus: "success",
      };
    }
    case "GROUPS/FETCH_CCB_CAMPUSES.ERROR": {
      return {
        ...state,
        ccbCampuses: [],
        fetchCCBCampusesStatus: "error",
      };
    }
    case "GROUPS/CLEAR_FETCH_CCB_CAMPUSES_STATUS": {
      return { ...state, fetchCCBCampusesStatus: "" };
    }
    case "GROUPS/FETCH_CCB_GROUPS": {
      return {
        ...state,
        fetchCCBGroupsStatus: "loading",
        fetchCCBGroupsMoreStatus: action.page > 1 ? "loading" : "",
        resyncFetchCCBGroupstatus: action.resync ? "loading" : "",
      };
    }
    case "GROUPS/FETCH_CCB_GROUPS.SUCCESS": {
      return {
        ...state,
        fetchCCBGroupsStatus: "success",
        ccbGroups:
          action.page > 1
            ? {
                pagination: action.payload.pagination,
                groups: [...state.ccbGroups.groups, ...action.payload.groups],
              }
            : action.payload,
        fetchCCBGroupsMoreStatus: action.page > 1 ? "success" : "",
        resyncFetchCCBGroupstatus: action.resync ? "success" : "",
      };
    }
    case "GROUPS/FETCH_CCB_GROUPS.ERROR": {
      return {
        ...state,
        fetchCCBGroupsStatus: "error",
        ccbGroups: [],
        fetchCCBGroupsMoreStatus: action.page > 1 ? "error" : "",
        resyncFetchCCBGroupstatus: action.resync ? "error" : "",
      };
    }
    case "GROUPS/CLEAR_FETCH_CCB_GROUPS_STATUS": {
      return {
        ...state,
        fetchCCBGroupsStatus: "",
        fetchCCBGroupsMoreStatus: "",
        resyncFetchCCBGroupstatus: "",
      };
    }
    case "GROUPS/FETCH_CCB_SAVED_SEARCHES": {
      return {
        ...state,
        fetchCCBSavedSearchesStatus: "loading",
        fetchCCBSavedSearchesMoreStatus: action.page > 1 ? "loading" : "",
        resyncFetchCCBSavedSearchStatus: action.resync ? "loading" : "",
      };
    }
    case "GROUPS/FETCH_CCB_SAVED_SEARCHES.SUCCESS": {
      let result;
      if (state.ccbSavedSearches?.saved_searches && action.page > 1) {
        result = {
          pagination: action.payload.pagination,
          saved_searches: [
            ...state.ccbSavedSearches?.saved_searches,
            ...action.payload.saved_searches,
          ],
        };
      } else {
        result = action.payload;
      }

      return {
        ...state,
        ccbSavedSearches: result,
        fetchCCBSavedSearchesStatus: "success",
        fetchCCBProcessQueuesMoreStatus: action.page > 1 ? "success" : "",
        resyncFetchCCBSavedSearchStatus: action.resync ? "success" : "",
      };
    }
    case "GROUPS/FETCH_CCB_SAVED_SEARCHES.ERROR": {
      return {
        ...state,
        fetchCCBSavedSearchesStatus: "error",
        fetchCCBSavedSearchesMoreStatus: action.page > 1 ? "error" : "",
        resyncFetchCCBSavedSearchStatus: action.resync ? "error" : "",
      };
    }
    case "GROUPS/CLEAR_FETCH_CCB_SAVED_SEARCHES_STATUS": {
      return {
        ...state,
        fetchCCBSavedSearchesStatus: "",
        fetchCCBSavedSearchesMoreStatus: "",
        resyncFetchCCBSavedSearchStatus: "",
      };
    }
    case "GROUPS/FETCH_CCB_PROCESS_QUEUES": {
      return {
        ...state,
        fetchCCBProcessQueuesStatus: "loading",
        resyncFetchCCBProcessQueuesStatus: action.resync ? "loading" : "",
        fetchCCBProcessQueuesMoreStatus: action.page > 1 ? "loading" : "",
      };
    }
    case "GROUPS/FETCH_CCB_PROCESS_QUEUES.SUCCESS": {
      return {
        ...state,
        ccbProcesses:
          action.page > 1
            ? {
                pagination: action.payload.pagination,
                processes: [
                  ...state.ccbProcesses.processes,
                  ...action.payload.processes,
                ],
              }
            : action.payload,
        fetchCCBProcessQueuesStatus: "success",
        resyncFetchCCBProcessQueuesStatus: action.resync ? "success" : "",
        fetchCCBProcessQueuesMoreStatus: action.page > 1 ? "success" : "",
      };
    }
    case "GROUPS/FETCH_CCB_PROCESS_QUEUES.ERROR": {
      return {
        ...state,
        ccbProcesses: [],
        fetchCCBProcessQueuesStatus: "error",
        resyncFetchCCBProcessQueuesStatus: action.resync ? "error" : "",
        fetchCCBProcessQueuesMoreStatus: action.page > 1 ? "error" : "",
      };
    }
    case "GROUPS/CLEAR_FETCH_CCB_PROCESS_QUEUES_STATUS": {
      return {
        ...state,
        fetchCCBProcessQueuesStatus: "",
        fetchCCBProcessQueuesMoreStatus: "",
        resyncFetchCCBProcessQueuesStatus: "",
      };
    }
    case "GROUPS/FETCH_PCO_LISTS": {
      return {
        ...state,
        fetchPcoListsStatus: "loading",
        resyncPcoListsStatus: action.resync ? "loading" : "",
        fetchPcoListsMoreStatus: action.page > 1 ? "loading" : "",
      };
    }
    case "GROUPS/FETCH_PCO_LISTS.SUCCESS": {
      return {
        ...state,
        pcoLists:
          action.page > 1
            ? {
                pagination: action.payload.pagination,
                lists: [...state.pcoLists?.lists, ...action.payload?.lists],
              }
            : action.payload,
        fetchPcoListsStatus: "success",
        resyncPcoListsStatus: action.resync ? "success" : "",
        fetchPcoListsMoreStatus: action.page > 1 ? "success" : "",
      };
    }
    case "GROUPS/FETCH_PCO_LISTS.ERROR": {
      return {
        ...state,
        pcoLists: [],
        fetchPcoListsStatus: "error",
        resyncPcoListsStatus: action.resync ? "error" : "",
        fetchPcoListsMoreStatus: action.page > 1 ? "error" : "",
      };
    }
    case "GROUPS/CLEAR_FETCH_PCO_LISTS_STATUS": {
      return {
        ...state,
        fetchPcoListsStatus: "",
        resyncPcoListsStatus: "",
        fetchPcoListsMoreStatus: "",
      };
    }
    case "GROUPS/FETCH_PCO_WORKFLOWS": {
      return {
        ...state,
        fetchPcoWorkflowsStatus: "loading",
        fetchPcoWorkflowsMoreStatus: action.page > 1 ? "loading" : "",
        resyncPcoWorkflowStatus: action.resync ? "loading" : "",
      };
    }
    case "GROUPS/FETCH_PCO_WORKFLOWS.SUCCESS": {
      return {
        ...state,
        pcoWorkflows:
          action.page > 1
            ? {
                pagination: action.payload.pagination,
                workflows: [
                  ...state.pcoWorkflows?.workflows,
                  ...action.payload?.workflows,
                ],
              }
            : action.payload,
        fetchPcoWorkflowsStatus: "success",
        fetchPcoWorkflowsMoreStatus: action.page > 1 ? "success" : "",
        resyncPcoWorkflowStatus: action.resync ? "success" : "",
      };
    }
    case "GROUPS/FETCH_PCO_WORKFLOWS.ERROR": {
      return {
        ...state,
        pcoWorkflows: [],
        fetchPcoWorkflowsStatus: "error",
        fetchPcoWorkflowsMoreStatus: action.page > 1 ? "error" : "",
        resyncPcoWorkflowStatus: action.resync ? "error" : "",
      };
    }
    case "GROUPS/CLEAR_FETCH_PCO_WORKFLOWS_STATUS": {
      return {
        ...state,
        fetchPcoWorkflowsStatus: "",
        fetchPcoWorkflowsMoreStatus: "",
        resyncPcoWorkflowStatus: "",
      };
    }

    case "GROUPS/FETCH_ELVANTO_GROUPS": {
      return {
        ...state,
        fetchElvantoGroupsStatus: "loading",
        resyncElvantoGroupStatus: action.resync ? "loading" : "",
        fetchElvantoGroupMoreStatus: action.page > 1 ? "loading" : "",
      };
    }
    case "GROUPS/FETCH_ELVANTO_GROUPS.SUCCESS": {
      return {
        ...state,
        elvantoGroups:
          action.page > 1
            ? {
                pagination: action.payload.pagination,
                groups: [
                  ...state.elvantoGroups.groups,
                  ...action.payload.groups,
                ],
              }
            : action.payload,
        fetchElvantoGroupsStatus: "success",
        resyncElvantoGroupStatus: action.resync ? "success" : "",
        fetchElvantoGroupMoreStatus: action.page > 1 ? "success" : "",
      };
    }
    case "GROUPS/FETCH_ELVANTO_GROUPS.error": {
      return {
        ...state,
        elvantoGroups: [],
        fetchElvantoGroupsStatus: "error",
        resyncElvantoGroupStatus: action.resync ? "error" : "",
        fetchElvantoGroupMoreStatus: action.page > 1 ? "error" : "",
      };
    }
    case "GROUPS/CLEAR_FETCH_ELVANTO_GROUPS_STATUS": {
      return {
        ...state,
        fetchElvantoGroupsStatus: "",
        resyncElvantoGroupStatus: "",
        fetchElvantoGroupMoreStatus: "",
      };
    }

    // Rock rms integration group state start
    case "GROUPS/FETCH_ROCKRMS_GROUPS": {
      return {
        ...state,
        fetchRockrmsGroupsStatus: "loading",
        resyncRockrmsGroupStatus: action.resync ? "loading" : "",
        fetchRockrmsGroupMoreStatus: action.page > 1 ? "loading" : "",
      };
    }
    case "GROUPS/FETCH_ROCKRMS_GROUPS.SUCCESS": {
      return {
        ...state,
        rockRmsGroups:
          action.page > 1
            ? {
                pagination: action.payload.pagination,
                groups: [
                  ...state.rockRmsGroups.groups,
                  ...action.payload.groups,
                ],
              }
            : action.payload,
        fetchRockrmsGroupsStatus: "success",
        resyncRockrmsGroupStatus: action.resync ? "success" : "",
        fetchRockrmsGroupMoreStatus: action.page > 1 ? "success" : "",
      };
    }
    case "GROUPS/FETCH_ROCKRMS_GROUPS.ERROR": {
      return {
        ...state,
        rockRmsGroups: [],
        fetchRockrmsGroupsStatus: "error",
        resyncRockrmsGroupStatus: action.resync ? "error" : "",
        fetchRockrmsGroupMoreStatus: action.page > 1 ? "error" : "",
      };
    }
    case "GROUPS/CLEAR_FETCH_ROCKRMS_GROUPS_STATUS": {
      return {
        ...state,
        fetchRockrmsGroupsStatus: "",
        resyncRockrmsGroupStatus: "",
        fetchRockrmsGroupMoreStatus: "",
      };
    }
    // Rock rms integration group state ends

    case "GROUPS/FETCH_MAILCHIMP_LISTS": {
      return {
        ...state,
        fetchMailChimpListsStatus: "loading",
        resyncMailChimpListsStatus: action.resync ? "loading" : "",
        fetchMailChimpListsMoreStatus: action.page > 1 ? "loading" : "",
      };
    }
    case "GROUPS/FETCH_MAILCHIMP_LISTS.SUCCESS": {
      return {
        ...state,
        mcLists:
          action.page > 1
            ? {
                pagination: action.payload.pagination,
                lists: [...state.mcLists.lists, ...action.payload.lists],
              }
            : action.payload,
        fetchMailChimpListsStatus: "success",
        resyncMailChimpListsStatus: action.resync ? "success" : "",
        fetchMailChimpListsMoreStatus: action.page > 1 ? "success" : "",
      };
    }
    case "GROUPS/FETCH_MAILCHIMP_LISTS.ERROR": {
      return {
        ...state,
        mcLists: [],
        fetchMailChimpListsStatus: "error",
        resyncMailChimpListsStatus: action.resync ? "error" : "",
        fetchMailChimpListsMoreStatus: action.page > 1 ? "error" : "",
      };
    }
    case "GROUPS/CLEAR_FETCH_MAILCHIMP_LISTS_STATUS": {
      return {
        ...state,
        fetchMailChimpListsStatus: "",
        resyncMailChimpListsStatus: "",
        fetchMailChimpListsMoreStatus: "",
      };
    }
    case "GROUPS/FETCH_MAILCHIMP_GROUPS": {
      return {
        ...state,
        fetchMailChimpGroupsStatus: "loading",
        resyncMailChimpGroupsStatus: action.resync ? "loading" : "",
        fetchMailChimpGroupsMoreStatus: action.page > 1 ? "loading" : "",
      };
    }
    case "GROUPS/FETCH_MAILCHIMP_GROUPS.SUCCESS": {
      return {
        ...state,
        mcGroups:
          action.page > 1
            ? {
                pagination: action.payload.pagination,
                groups: [...state.mcGroups.groups, ...action.payload.groups],
              }
            : action.payload,
        fetchMailChimpGroupsStatus: "success",
        resyncMailChimpGroupsStatus: action.resync ? "success" : "",
        fetchMailChimpGroupsMoreStatus: action.page > 1 ? "success" : "",
      };
    }
    case "GROUPS/FETCH_MAILCHIMP_GROUPS.ERROR": {
      return {
        ...state,
        fetchMailChimpGroupsStatus: "error",
        resyncMailChimpGroupsStatus: action.resync ? "error" : "",
        fetchMailChimpGroupsMoreStatus: action.page > 1 ? "error" : "",
      };
    }
    case "GROUPS/CLEAR_FETCH_MAILCHIMP_GROUPS_STATUS": {
      return {
        ...state,
        fetchMailChimpGroupsStatus: "",
        resyncMailChimpGroupsStatus: "",
        fetchMailChimpGroupsMoreStatus: "",
      };
    }
    case "GROUPS/FETCH_BREEZE_TAGS": {
      return {
        ...state,
        fetchBreezeTagsStatus: "loading",
        resyncBreezeTagsStatus: action.resync ? "loading" : "",
        fetchBreezeTagsMoreStatus: action.page > 1 ? "loading" : "",
      };
    }
    case "GROUPS/FETCH_BREEZE_TAGS.SUCCESS": {
      return {
        ...state,
        breezeTags:
          action.page > 1
            ? {
                pagination: action.payload.pagination,
                tags: [...state.breezeTags.tags, ...action.payload.tags],
              }
            : action.payload,
        fetchBreezeTagsStatus: "success",
        resyncBreezeTagsStatus: action.resync ? "success" : "",
        fetchBreezeTagsMoreStatus: action.page > 1 ? "success" : "",
      };
    }
    case "GROUPS/FETCH_BREEZE_TAGS.ERROR": {
      return {
        ...state,
        breezeTags: [],
        fetchBreezeTagsStatus: "error",
        resyncBreezeTagsStatus: action.resync ? "error" : "",
        fetchBreezeTagsMoreStatus: action.page > 1 ? "error" : "",
      };
    }
    case "GROUPS/CLEAR_FETCH_BREEZE_TAGS_STATUS": {
      return {
        ...state,
        fetchBreezeTagsStatus: "",
        resyncBreezeTagsStatus: "",
        fetchBreezeTagsMoreStatus: "",
      };
    }
    case "GROUPS/FETCH_BREEZE_EVENTS": {
      return {
        ...state,
        fetchBreezeEventsStatus: "loading",
        resyncBreezeEventStatus: action.resync ? "loading" : "",
        fetchBreezeEventMoreStatus: action.page > 1 ? "loading" : "",
      };
    }
    case "GROUPS/FETCH_BREEZE_EVENTS.SUCCESS": {
      return {
        ...state,
        breezeEvents:
          action.page > 1
            ? {
                pagination: action.payload.pagination,
                events: [
                  ...state.breezeEvents.events,
                  ...action.payload.events,
                ],
              }
            : action.payload,
        fetchBreezeEventsStatus: "success",
        resyncBreezeEventStatus: action.resync ? "success" : "",
        fetchBreezeEventMoreStatus: action.page > 1 ? "success" : "",
      };
    }
    case "GROUPS/FETCH_BREEZE_EVENTS.ERROR": {
      return {
        ...state,
        breezeEvents: [],
        fetchBreezeEventsStatus: "error",
        resyncBreezeEventStatus: action.resync ? "error" : "",
        fetchBreezeEventMoreStatus: action.page > 1 ? "error" : "",
      };
    }
    case "GROUPS/CLEAR_FETCH_BREEZE_EVENTS_STATUS": {
      return {
        ...state,
        fetchBreezeEventsStatus: "",
        resyncBreezeEventStatus: "",
        fetchBreezeEventMoreStatus: "",
      };
    }
    case "GROUPS/FETCH_TAGS_TO_APPLY": {
      return {
        ...state,
        fetchTagsToApplyStatus: "loading",
      };
    }
    case "GROUPS/FETCH_TAGS_TO_APPLY.SUCCESS": {
      const tags = {
        total: action.payload.total,
        tags:
          action.page === 1 || !action.page
            ? action.payload.tags
            : [...(state.tagsToApply?.tags || []), ...action.payload.tags],
      };
      return {
        ...state,
        tagsToApply: tags,
        fetchTagsToApplyStatus: "success",
      };
    }
    case "GROUPS/FETCH_TAGS_TO_APPLY.ERROR": {
      return {
        ...state,
        tagsToApply: [],
        fetchTagsToApplyStatus: "error",
      };
    }
    case "GROUPS/SET_PREFILL_INTEGRATION_GROUP": {
      return {
        ...state,
        prefillIntegrationGroup: action.group,
      };
    }

    case "GROUP/SELECT_ALL_GROUPS": {
      return {
        ...state,
        allGroupsSelected: true,
      };
    }
    case "GROUP/UNSELECT_ALL_GROUPS": {
      return {
        ...state,
        allGroupsSelected: false,
      };
    }
    default:
      return state;
  }
}
