import React, { useCallback, useState, useMemo } from "react";
import {
  isLgOrBelowBreakpoint,
  isMdOrBelowBreakpoint,
} from "../../../utils/breakpoints";
import clsx from "clsx";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import SideBarIconWrapper from "../SideBarIconWrapper";

import { ReactComponent as ContactIcon } from "../../../assets/img/icons-new/main-nav-v2/people/people-purple.svg";
import { ReactComponent as GroupsIcon } from "../../../assets/img/icons-new/main-nav-v2/groups/groups-purple.svg";
import { ReactComponent as CallIcon } from "../../../assets/img/icons-new/main-nav-v2/call/call-purple.svg";
import { ReactComponent as MessageIcon } from "../../../assets/img/icons-new/main-nav-v2/messages/messages-purple.svg";
import { ReactComponent as BroadcastIcon } from "../../../assets/img/icons-new/general/send/send-purple.svg";
import { ReactComponent as PlIcon } from "../../../assets/img/icons-new/pastorsline/pastorsline-beta/pastorsline-beta-medium-purple.svg";
import { ReactComponent as CampaignIcon } from "../../../assets/img/icons-new/main-nav-v2/campaigns/campaigns-purple.svg";
import { ReactComponent as AnalyticsIcon } from "../../../assets/img/icons-new/main-nav-v2/analytics/analytics-purple.svg";
import { ReactComponent as AllTextIcon } from "../../../assets/img/icons-new/all-text/all-text.svg";

import Tooltip from "../../Tooltip";
import { getPermissionParsed } from "../../../utils/settingsHelpers";
import { GROUP } from "../../../utils/constants";
import RenderForRN from "../../hub/HelperComponents/RenderForRN";
import SideBarMoreMenus from "./SideBarMoreMenus";

import { useThreadsStore } from "../../../utils/hooks/store/useThreadsStore";
import useUsersStore from "../../../utils/hooks/ReduxHooks/userStore";
import useGroupsStore from "../../../utils/hooks/ReduxHooks/groupsStore";
import { useMenusStore } from "../../../utils/hooks/store/useMenusStore";
import useCompaniesStore from "../../../utils/hooks/ReduxHooks/companiesStore";
import { useNumbersStore } from "../../../utils/hooks/store/useNumbersStore";
import useMessagesStore from "../../../utils/hooks/ReduxHooks/messagesStore";
import useMainStore from "../../../utils/hooks/ReduxHooks/mainStore";
import useContactsStore from "../../../utils/hooks/ReduxHooks/contactsStore";

import { UserMainMenuLookEnums } from "../../../utils/enums/usersEnums";

import PropTypes from "prop-types";
import HeaderUnreadDropdown from "../../HeaderUnreadDropdown";
import SidebarMenuInfo from "./SidebarMenuInfo";
import { LightTooltip, isReactNativeApp } from "../../../helpers";

const getFilter = (filter) => {
  if (filter === "all") return "";
  return `filter/${filter}`;
};

const SidebarMenus = (props) => {
  const { isSidebarExpanded, breakpoint } = props;

  // Routers
  const { tab, filter } = useParams();
  const { setGroupFilter } = useGroupsStore();
  const { setPeopleFilter } = useContactsStore();
  const { pathname: url } = useLocation();
  const history = useHistory();

  const iconSizeOnMobileView = isMdOrBelowBreakpoint(breakpoint) ? 20 : 17;

  const {
    threads: {
      unreads,
      unreadsTotal,
      undeliveredUnread,
      unreadsStatus,
      unreadTextStatus,
      undeliveredsStatus,
      unreadText,
      voiceCounts: {
        missedUnseen,
        undeliveredUnread: voiceUndeliveredUnread,
        voicemailUnseen,
      },
    },
    fetchUnread,
    showUnread,
    setMessagesTab,
  } = useThreadsStore();

  const {
    users: { settings: userSettings, permissions: userPermissions },
  } = useUsersStore();

  const {
    groups: { groupViewExpanded, counts: groupsCounts },
  } = useGroupsStore();

  const {
    menus: { moreMenuExpanded },
    toggleExpandedView,
  } = useMenusStore();

  const {
    companies: { currentCompany },
  } = useCompaniesStore();

  const {
    numbers: { numbersFilter },
  } = useNumbersStore();

  const { toggleFilters } = useMessagesStore();
  const { toggleGroupView } = useGroupsStore();
  const { toggleSidebar } = useMainStore();
  const { threads } = useThreadsStore();
  const { selectedMessagesTab } = threads;

  const {
    contacts: { counts: contactCounts },
  } = useContactsStore();

  const [activeMenuItems, setActiveMenuItems] = useState({
    dashboard: false,
    hub: false,
    message: false,
    people: false,
    group: false,
    voice: false,
    campaigns: false,
    more: false,
    log: false,
    broadcast: false,
  });

  const [openUnreadPopover, setOpenUnreadPopover] = useState(false);
  const [openTextUnreadPopover, setOpenTextUnreadPopover] = useState(false);

  const onTabIconClicked = useCallback(() => {
    toggleSidebar();
    if (isLgOrBelowBreakpoint(breakpoint)) {
      toggleFilters(false);
    }

    if (groupViewExpanded) {
      toggleGroupView();
    }

    if (moreMenuExpanded) {
      toggleExpandedView();
    }
  }, [
    breakpoint,
    groupViewExpanded,
    moreMenuExpanded,
    toggleExpandedView,
    toggleFilters,
    toggleGroupView,
    toggleSidebar,
  ]);

  const onGroupTabClicked = useCallback(() => {
    if (
      userSettings?.groups_default_page === "all" &&
      !isMdOrBelowBreakpoint(breakpoint)
    ) {
      setGroupFilter(null);
    }
    onTabIconClicked();
  }, [breakpoint, onTabIconClicked, setGroupFilter, userSettings]);

  const onContactTabClicked = useCallback(() => {
    if (userSettings?.people_default_page === "all") {
      setPeopleFilter(null);
    }
    onTabIconClicked();
  }, [onTabIconClicked, setPeopleFilter, userSettings]);

  const loadData = useCallback(() => {
    if (unreadTextStatus !== "loading") {
      fetchUnread(currentCompany?.id, numbersFilter, 20, 1, "all");
    }
  }, [currentCompany, fetchUnread, numbersFilter, unreadTextStatus]);

  const loadUnreadsForTextTab = useCallback(() => {
    if (unreadsStatus !== "loading") {
      fetchUnread(currentCompany?.id, numbersFilter, 20, 1, "all_text");
    }
  }, [currentCompany, fetchUnread, numbersFilter, unreadsStatus]);

  const getGroupDefaultRoute = useMemo(() => {
    if (
      userSettings?.groups_default_page === "all" &&
      !isMdOrBelowBreakpoint(breakpoint)
    ) {
      return "/hub/groups/viewType/view-all";
    }
    return `/hub/groups/${getFilter(userSettings?.groups_default_page)}`;
  }, [breakpoint, userSettings]);

  const getUnReads = useCallback(() => {
    const currentUnReads = [];

    if (unreads) {
      currentUnReads.push(...unreads);
    }

    return currentUnReads.slice(0, 5);
  }, [unreads]);

  const showUnreadMessages = useCallback(() => {
    showUnread();
    toggleSidebar();
    setOpenUnreadPopover(false);
    setOpenTextUnreadPopover(false);
    history.push("/hub/messages/filter/unread");
  }, [history, setOpenUnreadPopover, showUnread, toggleSidebar]);

  const onToggleMenuItems = useCallback(
    (item, bool) => () => {
      if (
        !isMdOrBelowBreakpoint(breakpoint) &&
        userSettings?.main_menu_look !== UserMainMenuLookEnums.ICONS_AND_TEXTS
      ) {
        setActiveMenuItems((prev) => {
          return {
            ...prev,
            [item]: bool,
          };
        });
        // isOpen: !prev.isOpen,
      }
    },
    [breakpoint, userSettings]
  );

  const handleMessages = useCallback(
    (value) => () => {
      if (filter === "unread") return null;
      if (unreadsTotal === 0 && openUnreadPopover) {
        setOpenUnreadPopover(false);
      } else if (unreadsTotal > 0) {
        let closeTooltipTimer;
        if (!value) {
          closeTooltipTimer = setTimeout(
            () => setOpenUnreadPopover(value),
            200
          );
        } else {
          clearTimeout(closeTooltipTimer);
          setOpenUnreadPopover(value);
        }
      } else {
        onToggleMenuItems("message", value)();
      }
    },
    [
      onToggleMenuItems,
      openUnreadPopover,
      setOpenUnreadPopover,
      unreadsTotal,
      filter,
    ]
  );

  const showUnreadMessageForTextTabOnHover = useCallback(
    (value) => () => {
      if (filter === "unread") return null;
      if (unreadText === 0 && openTextUnreadPopover) {
        setOpenTextUnreadPopover(false);
      } else if (unreadText > 0) {
        let closeTooltipTimer;
        if (!value) {
          closeTooltipTimer = setTimeout(
            () => setOpenTextUnreadPopover(value),
            200
          );
        } else {
          clearTimeout(closeTooltipTimer);
          setOpenTextUnreadPopover(value);
        }
      } else {
        onToggleMenuItems("message", value)();
      }
    },
    [onToggleMenuItems, openTextUnreadPopover, unreadText, filter]
  );

  const toolTipWrapper = useCallback(
    (content, label) => {
      if (isSidebarExpanded) return content;
      return (
        <LightTooltip title={label} placement="right">
          {content}
        </LightTooltip>
      );
    },
    [isSidebarExpanded]
  );

  const onTextMessageLinkClick = useCallback(
    (message) => {
      onTabIconClicked();
      setMessagesTab(message);
    },
    [onTabIconClicked, setMessagesTab]
  );

  return (
    <>
      {!isMdOrBelowBreakpoint(breakpoint) && (
        <>
          {toolTipWrapper(
            <li
              className={clsx("sidebar-list-item", {
                minimized: !isSidebarExpanded,
              })}
            >
              <Link
                to="/dashboard"
                className={clsx({
                  active: url === "/dashboard",
                  minimized: !isSidebarExpanded,
                })}
                onMouseEnter={onToggleMenuItems("dashboard", true)}
                onMouseLeave={onToggleMenuItems("dashboard", false)}
                onClick={toggleSidebar}
              >
                <SideBarIconWrapper
                  className={clsx({
                    "sidebar-expanded-list-wrapper": isSidebarExpanded,
                  })}
                  id="pl-sidebar"
                >
                  <SidebarMenuInfo
                    isSidebarExpanded={isSidebarExpanded}
                    label="Dashboard"
                    icon={<PlIcon height={17} width={17} />}
                  />
                </SideBarIconWrapper>
                <Tooltip active={activeMenuItems.dashboard} title="Dashboard" />
              </Link>
            </li>,
            "Dashboard"
          )}
        </>
      )}

      {!isReactNativeApp() && (
        <>
          {toolTipWrapper(
            <li
              className={clsx("sidebar-list-item", {
                minimized: !isSidebarExpanded,
              })}
            >
              <Link
                to={`/broadcast`}
                className={clsx({
                  active: url && url?.includes("/broadcast"),
                  minimized: !isSidebarExpanded,
                })}
                onMouseEnter={onToggleMenuItems("broadcast", true)}
                onMouseLeave={onToggleMenuItems("broadcast", false)}
              >
                <SideBarIconWrapper
                  id="broadcasts-sidebar"
                  className={clsx({
                    "sidebar-expanded-list-wrapper": isSidebarExpanded,
                  })}
                >
                  <SidebarMenuInfo
                    isSidebarExpanded={isSidebarExpanded}
                    label="Broadcast"
                    icon={
                      <BroadcastIcon
                        width={iconSizeOnMobileView}
                        height={iconSizeOnMobileView}
                      />
                    }
                    isBetaFeature={true}
                  />
                </SideBarIconWrapper>
                <Tooltip
                  active={activeMenuItems.broadcast}
                  title="All Broadcasts"
                />
              </Link>
            </li>,
            "Broadcasts"
          )}
        </>
      )}

      {toolTipWrapper(
        <li
          className={clsx("sidebar-list-item", {
            minimized: !isSidebarExpanded,
          })}
        >
          <Link
            to={`/hub/messages/${getFilter(
              userSettings?.messages_default_page
            )}`}
            className={clsx({
              active:
                tab === "messages" &&
                (selectedMessagesTab === "all" || selectedMessagesTab == null),
              minimized: !isSidebarExpanded,
            })}
            onMouseEnter={handleMessages(true)}
            onMouseLeave={handleMessages(false)}
            onClick={() => onTextMessageLinkClick("all")}
          >
            <SideBarIconWrapper
              id="messages-sidebar"
              showStatus={undeliveredUnread > 0}
              showBlueStatus={!undeliveredUnread && unreadText > 0}
              notificationBadgeBlueClassName={clsx({
                "sidebar-expanded-message-icon-bullet": isSidebarExpanded,
              })}
              notificationBadgeRedClassName={clsx({
                "sidebar-expanded-message-icon-bullet": isSidebarExpanded,
              })}
              notificationBadgeWhiteClassName={clsx({
                "sidebar-expanded-message-icon-bullet": isSidebarExpanded,
              })}
              className={clsx({
                "sidebar-expanded-list-wrapper": isSidebarExpanded,
              })}
            >
              <SidebarMenuInfo
                isSidebarExpanded={isSidebarExpanded}
                label="Messages"
                icon={
                  <MessageIcon
                    width={iconSizeOnMobileView}
                    height={iconSizeOnMobileView}
                  />
                }
              />
            </SideBarIconWrapper>

            <Tooltip active={activeMenuItems.message} title="All Messages" />
            <HeaderUnreadDropdown
              open={
                openUnreadPopover &&
                Boolean(userSettings?.unread_preview_on_hover)
              }
              unreads={getUnReads()}
              unreadsTotal={unreadsTotal}
              unreadsStatus={unreadsStatus}
              undeliveredsStatus={undeliveredsStatus}
              load={loadData}
              showUnread={showUnreadMessages}
              target={"messages-sidebar"}
            />
          </Link>
        </li>,
        "Messages"
      )}

      {!isMdOrBelowBreakpoint(breakpoint) &&
        toolTipWrapper(
          <li
            className={clsx("sidebar-list-item", {
              minimized: !isSidebarExpanded,
            })}
          >
            <Link
              to={`/hub/messages/${getFilter(
                userSettings?.messages_default_page
              )}`}
              className={clsx({
                active:
                  tab === "messages" && selectedMessagesTab === "all_text",
                minimized: !isSidebarExpanded,
              })}
              onMouseEnter={showUnreadMessageForTextTabOnHover(true)}
              onMouseLeave={showUnreadMessageForTextTabOnHover(false)}
              onClick={() => onTextMessageLinkClick("all_text")}
            >
              <SideBarIconWrapper
                id="text-sidebar"
                showBlueStatus={unreadText > 0}
                notificationBadgeBlueClassName={clsx({
                  "sidebar-expanded-message-icon-bullet": isSidebarExpanded,
                })}
                notificationBadgeRedClassName={clsx({
                  "sidebar-expanded-message-icon-bullet": isSidebarExpanded,
                })}
                notificationBadgeWhiteClassName={clsx({
                  "sidebar-expanded-message-icon-bullet": isSidebarExpanded,
                })}
                className={clsx({
                  "sidebar-expanded-list-wrapper": isSidebarExpanded,
                })}
              >
                <SidebarMenuInfo
                  isSidebarExpanded={isSidebarExpanded}
                  label="Text"
                  icon={
                    <AllTextIcon
                      width={iconSizeOnMobileView}
                      height={iconSizeOnMobileView}
                    />
                  }
                />
              </SideBarIconWrapper>

              <Tooltip active={activeMenuItems.message} title="Text" />
              <HeaderUnreadDropdown
                open={
                  openTextUnreadPopover &&
                  Boolean(userSettings?.unread_preview_on_hover)
                }
                unreads={getUnReads()}
                unreadsTotal={unreadText}
                unreadsStatus={unreadTextStatus}
                undeliveredsStatus={""}
                load={loadUnreadsForTextTab}
                showUnread={showUnreadMessages}
                target={"text-sidebar"}
              />
            </Link>
          </li>,
          "Text"
        )}

      {userPermissions?.messages_calls &&
        toolTipWrapper(
          <li
            className={clsx("sidebar-list-item", {
              minimized: !isSidebarExpanded,
            })}
          >
            <Link
              to={`/hub/calls/${getFilter(userSettings?.voice_default_page)}`}
              className={clsx({
                active: tab === "calls",
                minimized: !isSidebarExpanded,
              })}
              onMouseEnter={onToggleMenuItems("people", true)}
              onMouseLeave={onToggleMenuItems("voice", false)}
              onClick={onTabIconClicked}
            >
              <SideBarIconWrapper
                showStatus={
                  missedUnseen > 0 ||
                  voiceUndeliveredUnread > 0 ||
                  voicemailUnseen > 0
                }
                showWhiteStatus={voiceUndeliveredUnread < 0}
                notificationBadgeRedClassName={clsx({
                  "sidebar-expanded-message-icon-bullet": isSidebarExpanded,
                })}
                notificationBadgeWhiteClassName={clsx({
                  "sidebar-expanded-message-icon-bullet": isSidebarExpanded,
                })}
                className={clsx({
                  "sidebar-expanded-list-wrapper": isSidebarExpanded,
                })}
              >
                <SidebarMenuInfo
                  isSidebarExpanded={isSidebarExpanded}
                  label="Voice"
                  icon={
                    <CallIcon
                      width={iconSizeOnMobileView}
                      height={iconSizeOnMobileView}
                    />
                  }
                />
              </SideBarIconWrapper>
              <Tooltip active={activeMenuItems.voice} title="Voice" />
            </Link>
          </li>,
          "Voice"
        )}
      {getPermissionParsed(userPermissions?.people, "list") &&
        toolTipWrapper(
          <li
            className={clsx("sidebar-list-item", {
              minimized: !isSidebarExpanded,
            })}
          >
            <Link
              to={`/hub/people/${getFilter(userSettings?.people_default_page)}`}
              className={clsx({
                active: tab === "people",
                minimized: !isSidebarExpanded,
              })}
              onMouseEnter={onToggleMenuItems("people", true)}
              onMouseLeave={onToggleMenuItems("people", false)}
              onClick={onContactTabClicked}
            >
              <SideBarIconWrapper
                id="people-sidebar"
                showBlueStatus={
                  contactCounts.holdAllUnread > 0 &&
                  getPermissionParsed(userPermissions.smart_syncing, "view")
                }
                notificationBadgeBlueClassName={clsx({
                  "sidebar-expanded-message-icon-bullet": isSidebarExpanded,
                })}
                className={clsx({
                  "sidebar-expanded-list-wrapper": isSidebarExpanded,
                })}
              >
                <SidebarMenuInfo
                  isSidebarExpanded={isSidebarExpanded}
                  label="People"
                  icon={
                    <ContactIcon
                      width={iconSizeOnMobileView}
                      height={iconSizeOnMobileView}
                    />
                  }
                />
              </SideBarIconWrapper>
              <Tooltip active={activeMenuItems.people} title="People" />
            </Link>
          </li>,
          "People"
        )}
      {userPermissions?.groups &&
        toolTipWrapper(
          <li
            className={clsx("sidebar-list-item", {
              minimized: !isSidebarExpanded,
            })}
          >
            <Link
              to={getGroupDefaultRoute}
              className={clsx({
                active: tab === "groups",
                minimized: !isSidebarExpanded,
              })}
              onMouseEnter={onToggleMenuItems(GROUP, true)}
              onMouseLeave={onToggleMenuItems(GROUP, false)}
              onClick={onGroupTabClicked}
            >
              <SideBarIconWrapper
                id="people-sidebar"
                showBlueStatus={groupsCounts.unreadHold > 0}
                notificationBadgeBlueClassName={clsx({
                  "sidebar-expanded-message-icon-bullet": isSidebarExpanded,
                })}
                className={clsx({
                  "sidebar-expanded-list-wrapper": isSidebarExpanded,
                })}
              >
                <SidebarMenuInfo
                  isSidebarExpanded={isSidebarExpanded}
                  label="Groups"
                  icon={
                    <GroupsIcon
                      width={iconSizeOnMobileView}
                      height={iconSizeOnMobileView}
                    />
                  }
                />
              </SideBarIconWrapper>
              <Tooltip active={activeMenuItems.group} title="Groups" />
            </Link>
          </li>,
          "Groups"
        )}
      <RenderForRN show={false}>
        {toolTipWrapper(
          <li
            className={clsx("sidebar-list-item", {
              minimized: !isSidebarExpanded,
            })}
          >
            <Link
              to="/hub/menus/filter/campaigns"
              className={clsx({
                active: url === "/hub/menus/filter/campaigns",
                minimized: !isSidebarExpanded,
              })}
              onMouseEnter={onToggleMenuItems("campaigns", true)}
              onMouseLeave={onToggleMenuItems("campaigns", false)}
              onClick={toggleSidebar}
            >
              <SideBarIconWrapper
                className={clsx({
                  "sidebar-expanded-list-wrapper": isSidebarExpanded,
                })}
                id="campaign-sidebar"
              >
                <SidebarMenuInfo
                  isSidebarExpanded={isSidebarExpanded}
                  label="Campaigns"
                  icon={
                    <CampaignIcon
                      width={iconSizeOnMobileView}
                      height={iconSizeOnMobileView}
                    />
                  }
                />
              </SideBarIconWrapper>
              <Tooltip active={activeMenuItems.campaigns} title="Campaigns" />
            </Link>
          </li>,
          "Campaigns"
        )}
      </RenderForRN>
      {!isMdOrBelowBreakpoint(breakpoint) &&
        toolTipWrapper(
          <li
            className={clsx("sidebar-list-item", {
              minimized: !isSidebarExpanded,
            })}
          >
            <Link
              to="/hub/menus/filter/analytics"
              className={clsx({
                active: url === "/hub/menus/filter/analytics",
                minimized: !isSidebarExpanded,
              })}
              onMouseEnter={onToggleMenuItems("analytics", true)}
              onMouseLeave={onToggleMenuItems("analytics", false)}
              onClick={toggleSidebar}
            >
              <SideBarIconWrapper
                className={clsx({
                  "sidebar-expanded-list-wrapper": isSidebarExpanded,
                })}
                id="campaign-sidebar"
              >
                <SidebarMenuInfo
                  isSidebarExpanded={isSidebarExpanded}
                  label="Analytics"
                  icon={<AnalyticsIcon width={17} height={17} />}
                />
              </SideBarIconWrapper>
              <Tooltip active={activeMenuItems.analytics} title="Analytics" />
            </Link>
          </li>,
          "Analytics"
        )}
      <SideBarMoreMenus
        isSidebarExpanded={isSidebarExpanded}
        breakpoint={breakpoint}
      />
    </>
  );
};

SidebarMenus.propTypes = {
  isSidebarExpanded: PropTypes.bool.isRequired,
  breakpoint: PropTypes.string.isRequired,
};
export default SidebarMenus;
