import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  formatPhoneNumber,
  getSignatureToInsert,
  mapSortTypeToApiType,
} from "../../helpers";
// import SpanWithTooltip from "../SpanWithTooltip";
import {
  setSenderNumber,
  setShortCodeAsDefaultSender,
  setSignatureToInsert,
  setUseSecondaryNumber,
} from "../../actions/numbers";

// import InfoTooltip from "../elements/InfoTooltip";
import withResponsive from "../../withResponsive";
//new icon
import { ReactComponent as GlobeSvg } from "../../assets/img/icons-new/settings/globe/globe-gray.svg";

import ModalWrapper from "./Helpers/ModalWrapper";
import SearchBox from "../hub/HelperComponents/SearchBox";
import TableInfinityScroll from "../hub/HubThreadViewComponents/TableInfinityScroll";
import TableHeader from "../hub/HubThreadViewComponents/TableHeader";
import CustomRadio from "../hub/HelperComponents/CustomRadio";
import NothingFoundComponent from "../elements/NothingFoundComponent";
import ButtonWithSpinner from "../hub/HelperComponents/ButtonWithSpinner";
import SpanWithTooltip from "../SpanWithTooltip";
import NumberDesignationIcons from "../Menus/shared/NumberSettingsTableBody/NumberDesignationIcons";
import PlusMinusCheckbox from "../hub/HelperComponents/PlusMinusCheckbox";
import { addInfoSnackBar } from "../../actions/main";
import { withRouter } from "react-router-dom";
import NumberSettingsStatus from "../Menus/shared/NumberSettingsTableBody/NumberSettingsStatus";

function mapStateToProps(store) {
  return {
    currentCompany: store.companies.currentCompany,
    currentCompanyId: store.companies.currentCompany.id,
    numbers: store.numbers.numbers,
    signatures: store.numbers.signatures,
    senderNumber: store.numbers.senderNumber,
    useSecondaryNumber: store.numbers.useSecondaryNumber,
    loggedUser: store.users.loggedUser,
    users: store.companies.users,
    companySettings: store.adminSettings.companySettings,
    defaultSenderSettings: store.numbers.defaultSenderSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSenderNumber: (number) => dispatch(setSenderNumber(number)),
    setUseSecondaryNumber: (state) => dispatch(setUseSecondaryNumber(state)),
    setSignatureToInsert: (selectedSignature) =>
      dispatch(setSignatureToInsert(selectedSignature)),
    addInfoSnackBar: (data) => dispatch(addInfoSnackBar(data)),
    setShortCodeAsDefaultSender: (data) =>
      dispatch(setShortCodeAsDefaultSender(data)),
  };
}

const cells = [
  {
    text: "Number",
    stateProp: true,
    sortName: "number",
  },
  {
    text: "Type",
    stateProp: true,
    sortName: "type",
  },
];

class ChooseSenderNumberModal extends React.Component {
  constructor(props) {
    super(props);
    this.choose = this.choose.bind(this);
    this.toggleUseSecondary = this.toggleUseSecondary.bind(this);
    this.handleSignatureToInsert = this.handleSignatureToInsert.bind(this);
    this.onSort = this.onSort.bind(this);

    this.state = {
      choosedNumber: this.props.senderNumber
        ? this.props.senderNumber.number
        : null,
      searchString: "",
      mappedNumbers: this.props.numbers,
      numbers: this.props.numbers,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.numbersFilter !== prevProps.numbersFilter) {
      this.setState({
        choosedNumber: this.props.senderNumber
          ? this.props.senderNumber.number
          : null,
      });
    }

    // on show
    if (this.props.show && !prevProps.show) {
      this.setState({
        choosedNumber: this.props.senderNumber
          ? this.props.senderNumber.number
          : null,
      });
    }

    if (
      this.props.senderNumber !== prevProps.senderNumber &&
      (this.props.senderNumber ? this.props.senderNumber.number : null) ===
        this.state.choosedNumber
    ) {
      this.props.closeModal();
    }
  }

  handleSignatureToInsert(index) {
    const { match } = this.props;
    if (
      this.props.numbers[index].src === 5 ||
      this.props.numbers[index].src === 6 ||
      this.props.numbers[index].is_high_throughput_number ||
      this.props.numbers[index].type === "local"
    ) {
      const dataToInsert = getSignatureToInsert({
        signatures: this.props.signatures,
        chosenNumber: this.state.choosedNumber,
        loggedUser: this.props.loggedUser,
        users: this.props.users,
        numbers: this.props.numbers,
        currentCompany: this.props.currentCompany,
        threadType: match?.params?.threadType || "",
      });

      this.props.setSignatureToInsert({
        ...dataToInsert,
        selectedNumber: index,
      });
    } else {
      this.props.setSignatureToInsert({
        // the part below is for signature modal
        selectedNumber: index,
        signatureUserNumber: this.props.numbers[index].number,
      });
    }
  }

  choose(event, index, selectedNumber) {
    event.stopPropagation();
    event.preventDefault();
    const {
      buttonAction,
      setUseSecondaryNumber,
      useSecondaryNumber,
      closeModal,
      addInfoSnackBar,
      setShortCodeAsDefaultSender,
      currentCompanyId,
      defaultSenderSettings,
      companySettings,
    } = this.props;
    const {
      currentTarget: { dataset },
    } = event;
    const { numbers } = this.state;
    this.setState({ choosedNumber: dataset.number }, () => {
      if (!buttonAction) {
        this.props.setSenderNumber(this.state.choosedNumber);
        this.handleSignatureToInsert(index);
        if (
          Number(defaultSenderSettings?.is_allow_short_code) === 1 &&
          Number(companySettings?.is_short_code_two_way) === 1
        ) {
          setShortCodeAsDefaultSender({
            company_id: currentCompanyId,
            reset: selectedNumber?.type === "shortcode" ? 0 : 1,
          });
        }
      }
      if (!numbers[index].has_secondaries && useSecondaryNumber) {
        setUseSecondaryNumber(false);
      }
      if (!selectedNumber.has_secondaries) {
        closeModal();
      }
      addInfoSnackBar({
        msg: `Sender number switched to ${
          selectedNumber.number_formatted || selectedNumber.number
        }.`,
      });
    });
  }

  toggleUseSecondary() {
    const { useSecondaryNumber, setUseSecondaryNumber } = this.props;
    setUseSecondaryNumber(!useSecondaryNumber);
  }

  searchNumbers = () => {
    const { searchString } = this.state;
    const { numbers } = this.props;
    const filterNumbers = numbers.filter(
      (item) =>
        item.number?.includes(searchString) ||
        item.nickname?.toLowerCase()?.includes(searchString)
    );
    this.setState({ numbers: filterNumbers });
  };

  handleQueryChange = (event) => {
    const query = event.target.value;
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(this.searchNumbers, 500);
    this.setState({ searchString: query });
  };

  onSort = (columnToSort, sortType) => {
    const orderType = mapSortTypeToApiType(sortType);
    const { mappedNumbers } = this.state;

    this.setState({
      numbers: [...mappedNumbers].keySort({
        [columnToSort]: orderType,
      }),
    });
  };

  render() {
    const {
      show,
      shakeModal,
      closeModal,
      title,
      modalButtonText,
      buttonAction,
      loadingStatus,
    } = this.props;
    const { numbers, choosedNumber } = this.state;
    if (!this.props.numbers) {
      return null;
    }
    return (
      <>
        <ModalWrapper
          id="defaultSignature"
          show={show}
          shakeModal={shakeModal}
          closeModal={closeModal}
          className="choose-sender-number-container-modal"
        >
          <div className="choose-sender-number-container">
            <h1>{title}</h1>
            <div className="choose-sender-number-search-wrapper">
              <SearchBox
                value={this.state.searchString}
                onChange={this.handleQueryChange}
                placeholder="Search number"
              />
            </div>
            <TableInfinityScroll
              loadMore={() => {}}
              page={1}
              searchValue={this.state.searchValue}
              companyId={2}
              fetchFunction={(page) => {}}
              className="choose-sender-number-scroll-table"
            >
              <table className="table mt-3">
                <TableHeader
                  className="choose-sender-number-table-header"
                  onSort={this.onSort}
                  cells={cells}
                />
                <tbody className="choose-sender-number-table-body">
                  {Boolean(numbers.length) &&
                    numbers.map((number, idx) => {
                      if (!number.sms) {
                        return null;
                      }

                      return (
                        <tr key={idx}>
                          <td>
                            <CustomRadio
                              checked={choosedNumber === number.number}
                              data-number={number.number}
                              data-index={idx}
                              onClick={(event) =>
                                this.choose(event, idx, number)
                              }
                            />
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              {formatPhoneNumber(number.number)}
                              {(number.src === 5 || number.src === 6) && (
                                <SpanWithTooltip title="Shared with all PastorsLine Clients">
                                  <GlobeSvg
                                    width={13}
                                    height={13}
                                    className="ml-2 mb-1"
                                  />
                                </SpanWithTooltip>
                              )}
                            </div>
                            {choosedNumber === number.number &&
                              number.has_secondaries && (
                                <div className="d-flex right-options col-auto px-0 ml-auto">
                                  <PlusMinusCheckbox
                                    size="small"
                                    checked={this.props.useSecondaryNumber}
                                    onChange={this.toggleUseSecondary}
                                    className="mr-1"
                                  />
                                  <span className="use-secondary-num-text">
                                    Use Secondary Number
                                  </span>
                                </div>
                              )}
                          </td>
                          <td>
                            <NumberDesignationIcons
                              number={number}
                              isChild={false}
                            />
                            <NumberSettingsStatus
                              is_toll_free={number.is_toll_free}
                              is_toll_free_registered={
                                number.is_toll_free_registered
                              }
                              isUnverifiedTollFreeText="(Unverified)"
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </TableInfinityScroll>
            {modalButtonText && Boolean(numbers?.length) && (
              <div className="modal-footer mt-3">
                <ButtonWithSpinner
                  onClick={() => buttonAction(choosedNumber)}
                  loading={loadingStatus === "loading"}
                >
                  {modalButtonText}
                </ButtonWithSpinner>
              </div>
            )}
            {!Boolean(numbers?.length) && (
              <NothingFoundComponent className={""} />
            )}
          </div>
        </ModalWrapper>
      </>
    );
  }
}

ChooseSenderNumberModal.defaultProps = {
  modalButtonText: "",
  loadingStatus: "",
};

ChooseSenderNumberModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  loadingStatus: PropTypes.string,
  modalButtonText: PropTypes.string,
  buttonAction: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withResponsive(ChooseSenderNumberModal)));
