import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { mainMenus } from "../../Menus/MenusFilter/mainMenus";
import { userHasPermissionForMenuItem } from "../../../helpers";
import useUsersStore from "../../../utils/hooks/ReduxHooks/userStore";
// import { useMenusStore } from "../../utils/hooks/store/useMenusStore";
import clsx from "clsx";
import {
  isLgOrBelowBreakpoint,
  // isLgOrBelowBreakpoint,
  isMdOrBelowBreakpoint,
} from "../../../utils/breakpoints";
import SideBarIconWrapper from "../SideBarIconWrapper";

import { ReactComponent as MoreIcon } from "../../../assets/img/icons-new/main-nav-v2/more/more-purple.svg";
import { ReactComponent as ExpandDownArrow } from "../../../assets/img/icons-new/general/expand-new/expand-dark-gray.svg";
import { ReactComponent as ExpandUpArrow } from "../../../assets/img/icons-new/general/expand-new/expand-up-purple.svg";

import { useParams, useLocation, Link } from "react-router-dom";

import PropTypes from "prop-types";
import { PopoverBody, PopoverHeader, UncontrolledPopover } from "reactstrap";
import {
  ANALYTICS_ROUTE,
  CAMPAIGNS_ROUTE,
  SETTINGS_ROUTE,
  WEB_WIDGETS_ROUTE,
} from "../../Menus/routes";
import { useMenusStore } from "../../../utils/hooks/store/useMenusStore";
import useMessagesStore from "../../../utils/hooks/ReduxHooks/messagesStore";
import { useThreadsStore } from "../../../utils/hooks/store/useThreadsStore";

const excludedMenuRoutes = [ANALYTICS_ROUTE, SETTINGS_ROUTE, CAMPAIGNS_ROUTE];

const SideBarMoreMenus = (props) => {
  const { isSidebarExpanded, breakpoint } = props;
  const {
    users: { permissions: userPermissions },
  } = useUsersStore();

  const { setRedirectUrl } = useMenusStore();
  const { toggleFilters } = useMessagesStore();
  const { threads } = useThreadsStore();
  const { selectedMessagesTab } = threads;

  const { tab, filter } = useParams();

  const { pathname: url } = useLocation();

  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);

  const moreMenuCollapseRef = useRef(null);

  const isTabMenuRoute = useMemo(() => {
    return tab === "menus" && !excludedMenuRoutes.includes(filter);
  }, [filter, tab]);

  const handleCollapse = useCallback((ariaExpandedValue) => {
    if (!moreMenuCollapseRef?.current) return;
    const ariaExpanded =
      moreMenuCollapseRef.current.getAttribute("aria-expanded");
    if (ariaExpanded === `${ariaExpandedValue}`) {
      moreMenuCollapseRef.current.click();
      setIsMoreMenuOpen(!ariaExpandedValue);
    }
  }, []);

  // Collapse closes on route change or hard refresh.
  // This ensures the collapse is always open.
  useEffect(() => {
    if (isTabMenuRoute) {
      handleCollapse(false);
    } else {
      handleCollapse(true);
    }
  }, [handleCollapse, isTabMenuRoute]);

  const handleMenuIconClicked = useCallback(() => {
    if (isSidebarExpanded) {
      setIsMoreMenuOpen((prev) => !prev);
      if (tab !== "menus") {
        setRedirectUrl(url);
      }
      if (isLgOrBelowBreakpoint(breakpoint)) {
        toggleFilters(true);
      }
    }
  }, [breakpoint, setRedirectUrl, tab, toggleFilters, url, isSidebarExpanded]);

  return (
    <>
      <li className="sidebar-list-item" id="more-popover">
        <div
          id="collapseHeaderMoreMenu"
          className="collapsible-header"
          data-toggle="collapse"
          data-target="#collapseMoreMenu"
          aria-expanded="false"
          aria-controls="collapseMoreMenu"
          ref={moreMenuCollapseRef}
        >
          <Link
            to={isMdOrBelowBreakpoint(breakpoint) ? "/hub/menus" : "#"}
            className={clsx("", {
              active:
                isTabMenuRoute ||
                (isMdOrBelowBreakpoint(breakpoint) && url === "/dashboard") ||
                isMoreMenuOpen ||
                (isMdOrBelowBreakpoint(breakpoint) &&
                  selectedMessagesTab === "all_text"),
              minimized: !isSidebarExpanded,
            })}
            onClick={handleMenuIconClicked}
          >
            <SideBarIconWrapper
              className={clsx("more-icon", {
                "sidebar-expanded-list-wrapper": isSidebarExpanded,
              })}
              id="more-sidebar"
            >
              <div
                className={clsx({
                  "sidebar-expanded-list": isSidebarExpanded,
                })}
              >
                <div className="sidebar-expanded-list-icon-wrapper">
                  <MoreIcon />
                </div>
                {isSidebarExpanded && (
                  <label>
                    {isMoreMenuOpen ? "Less" : "More Menu"}
                    <div className="sidebar-list-item-more-arrow">
                      {isMoreMenuOpen ? (
                        <ExpandDownArrow height={17} width={17} />
                      ) : (
                        <ExpandUpArrow height={17} width={17} />
                      )}
                    </div>
                  </label>
                )}
              </div>
            </SideBarIconWrapper>
          </Link>
        </div>
      </li>
      {isSidebarExpanded && (
        <div
          id="collapseMoreMenu"
          className="collapse collapsible-body w-100"
          aria-labelledby="more-popover"
        >
          {mainMenus.map((menu) => {
            const currentUrl = `/hub/menus/filter/${menu.key}`;
            return (
              userHasPermissionForMenuItem(menu, userPermissions) && (
                <li className="sidebar-list-item">
                  <Link
                    key={menu.key}
                    className={clsx({
                      active: url === currentUrl,
                    })}
                    to={currentUrl}
                  >
                    <SideBarIconWrapper
                      className={clsx({
                        "sidebar-expanded-list-wrapper": isSidebarExpanded,
                      })}
                      id="more-sidebar"
                    >
                      <div
                        className={clsx({
                          "sidebar-expanded-list": isSidebarExpanded,
                        })}
                      >
                        <div className="sidebar-expanded-list-icon-wrapper">
                          {
                            <menu.icon
                              height={17}
                              width={17}
                              className={clsx({
                                "stroke-icon": menu.key === WEB_WIDGETS_ROUTE,
                              })}
                            />
                          }
                        </div>
                        {isSidebarExpanded && <label>{menu.label}</label>}
                      </div>
                    </SideBarIconWrapper>
                  </Link>
                </li>
              )
            );
          })}
        </div>
      )}
      {!isSidebarExpanded && !isMdOrBelowBreakpoint(breakpoint) && (
        <UncontrolledPopover
          trigger="hover"
          placement="left"
          target="more-popover"
          className="sidebar-more-menu-popover side-menu-popover"
        >
          <PopoverHeader>More Menus</PopoverHeader>
          <PopoverBody>
            {mainMenus.map((menu) => {
              return (
                userHasPermissionForMenuItem(menu, userPermissions) && (
                  <Link
                    key={menu.key}
                    to={`/hub/menus/filter/${menu.key}`}
                    // onClick={() => resetExpandedDetailView()}
                  >
                    {menu.label}
                  </Link>
                )
              );
            })}
          </PopoverBody>
        </UncontrolledPopover>
      )}
    </>
  );
};

SideBarMoreMenus.propTypes = {
  isSidebarExpanded: PropTypes.bool.isRequired,
  breakpoint: PropTypes.string.isRequired,
};

export default SideBarMoreMenus;
