import { fetchCredits } from "../../actions/users";

export const onReloadCredits = () => {
  const callback = (config, dispatch, data) => {
    dispatch(fetchCredits(config.companyId));
  };

  return {
    event: "reload-credit",
    callback,
    timeout: 750,
  };
};
