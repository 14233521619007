import { isMdOrBelowBreakpoint } from "../../../utils/breakpoints";
import {
  isAccountClosed,
  isAccountNeedRenew,
  isReactNativeApp,
} from "../../../helpers";
import clsx from "clsx";
import { ReactComponent as AddIconMobile } from "../../../assets/img/icons-new/general/add-icon/add-icon-white.svg";
import useCompaniesStore from "../../../utils/hooks/ReduxHooks/companiesStore";
import PropTypes from "prop-types";
import SidebarMenus from "./SidebarMenus";
import SideBarSettingsNotifications from "./SideBarSettingsNotifications";
import SidebarAddNewPopover from "../SidebarAddNewPopover";

const SidebarTop = (props) => {
  const {
    isSidebarExpanded,
    breakpoint,
    openAddNewModal,
    toggleAddNewPopover,
    isAddNewPopoverOpen,
  } = props;

  const {
    companies: { currentCompany },
  } = useCompaniesStore();

  return (
    <>
      <ul
        className={`nav nav-tabs sidebar-navs ${
          isMdOrBelowBreakpoint(breakpoint) && !isReactNativeApp()
            ? "dashboard-bottom-tab"
            : ""
        }`}
      >
        <SidebarMenus
          isSidebarExpanded={isSidebarExpanded}
          breakpoint={breakpoint}
        />
      </ul>
      {currentCompany && (
        <div className="middle-container middle-container-absolute">
          {(isAccountClosed(currentCompany) ||
            isAccountNeedRenew(currentCompany)) && (
            <div className="middle-overlay" />
          )}
          {isMdOrBelowBreakpoint(breakpoint) && (
            <button
              onClick={
                isReactNativeApp() ? toggleAddNewPopover : openAddNewModal
              }
              className={clsx("add-icon", {
                "ml-2 sidebar-expanded-add-icon": isSidebarExpanded,
              })}
              id="add-menu-popover"
            >
              <AddIconMobile width={21.9} />
            </button>
          )}

          <SidebarAddNewPopover
            isAddNewPopoverOpen={isAddNewPopoverOpen}
            toggleAddNewPopover={toggleAddNewPopover}
          />
        </div>
      )}
      <div className="extra-menus-container extra-menus-container-space">
        {!isMdOrBelowBreakpoint(breakpoint) && (
          <>
            <SideBarSettingsNotifications
              isSidebarExpanded={isSidebarExpanded}
            />
          </>
        )}
      </div>
    </>
  );
};

SidebarTop.propTypes = {
  isSidebarExpanded: PropTypes.bool.isRequired,
  toggleAddNewPopover: PropTypes.func.isRequired,
  isAddNewPopoverOpen: PropTypes.bool.isRequired,
  breakpoint: PropTypes.string.isRequired,
};

export default SidebarTop;
