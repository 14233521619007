import React, { lazy } from "react";
import ModalsManager from "./ModalsManager";
import CompanyAutoSwitchedModal from "./modals/CompanyAutoSwitchedModal";
import FilterNumbersModal from "./modals/FilterNumbersModal";
import { getPermissionParsed } from "../utils/settingsHelpers";
import componentLoader from "../utils/componentLoader";

const NewCallDialpadModal = lazy(() =>
  componentLoader(() => import("./modals/NewCallDialpadModal"))
);

const NewContactModal = lazy(() =>
  componentLoader(() => import("./modals/NewContactModal/NewContactModal"))
);
const ManageContactGroupsModal = lazy(() =>
  componentLoader(() => import("./modals/ManageContactGroupsModal"))
);
const ManageContactTagsModal = lazy(() =>
  componentLoader(() => import("./modals/ManageContactTagsModal"))
);
const ManageContactAmCampaignsModal = lazy(() =>
  componentLoader(() => import("./modals/ManageContactAmCampaignsModal"))
);
const NumberLookupModal = lazy(() =>
  componentLoader(() => import("./modals/NumberLookupModal"))
);
const NewMessageReceiverModal = lazy(() =>
  componentLoader(() => import("./modals/NewMessageReceiverModal"))
);
const HubMessagesFiltersModal = lazy(() =>
  componentLoader(() => import("./modals/HubMessagesFiltersModal"))
);
const ChooseOrganizationModal = lazy(() =>
  componentLoader(() => import("./modals/ChooseOrganizationModal"))
);
const AddNewModal = lazy(() =>
  componentLoader(() => import("./modals/AddNewModal/AddNewModal"))
);
const AddBroadcastModal = lazy(() =>
  componentLoader(() => import("./modals/Broadcast/AddBroadcastModal"))
);
const AddNewGroupModal = lazy(() =>
  componentLoader(() => import("./modals/AddNewGroupModal/AddNewGroupModal"))
);
const ManageGroupAmCampaignsModal = lazy(() =>
  componentLoader(() => import("./modals/ManageGroupAmCampaignsModal"))
);
const ManageGroupExtAppsModal = lazy(() =>
  componentLoader(() => import("./modals/ManageGroupExtAppsModal"))
);
const AccountIssueModal = lazy(() =>
  componentLoader(() => import("./modals/AccountIssueModal/AccountIssueModal"))
);
const AddPeopleToGroupModal = lazy(() =>
  componentLoader(() => import("./modals/AddPeopleToGroupModal"))
);
const DeleteContactModal = lazy(() =>
  componentLoader(() => import("./modals/DeleteContactModal"))
);
const DeleteDuplicateContactModal = lazy(() =>
  componentLoader(() => import("./modals/DeleteDuplicateContactModal"))
);
const BlockDuplicateContactModal = lazy(() =>
  componentLoader(() => import("./modals/BlockDuplicateContactModal"))
);
const UnblockDuplicateContactModal = lazy(() =>
  componentLoader(() => import("./modals/UnblockDuplicateContactModal"))
);
const UnsubscribeDuplicateContactModal = lazy(() =>
  componentLoader(() => import("./modals/UnsubscribeDuplicateContactModal"))
);
const DoNotCallDuplicateContactModal = lazy(() =>
  componentLoader(() => import("./modals/DoNotCallDuplicateContactModal"))
);
const AllowCallDuplicateContactModal = lazy(() =>
  componentLoader(() => import("./modals/AllowCallDuplicateContactModal"))
);
const SubscribeDuplicateContactModal = lazy(() =>
  componentLoader(() => import("./modals/SubscribeDuplicateContactModal"))
);
const LookupQueuedModal = lazy(() =>
  componentLoader(() => import("./modals/LookupQueuedModal"))
);
const LookupResultModal = lazy(() =>
  componentLoader(() => import("./modals/LookupResultModal"))
);
const MessageSentModal = lazy(() =>
  componentLoader(() => import("./modals/MessageSentModal"))
);
const HostLandlineModal = lazy(() =>
  componentLoader(() => import("./modals/HostLandlineModal"))
);
const ShortLinksModal = lazy(() =>
  componentLoader(() => import("./modals/ShortLinksModal"))
);
const NewShortLinkModal = lazy(() =>
  componentLoader(() => import("./modals/NewShortLinkModal"))
);
const VoiceBroadcastModal = lazy(() =>
  componentLoader(() => import("./modals/VoiceBroadcastModal"))
);
const MessageTemplatesModal = lazy(() =>
  componentLoader(() => import("./modals/MessageTemplatesModal"))
);
const MessageTemplateModal = lazy(() =>
  componentLoader(() => import("./modals/MessageTemplateModal"))
);
const NewCallModal = lazy(() =>
  componentLoader(() => import("./modals/NewCallModal"))
);
const SignatureModal = lazy(() =>
  componentLoader(() => import("./modals/SignatureModal"))
);
const SkipSendingModal = lazy(() =>
  componentLoader(() => import("./modals/SkipSendingModals/SkipSendingModal"))
);
const SkipSendingMultipleModal = lazy(() =>
  componentLoader(() =>
    import("./modals/SkipSendingModals/SkipSendingMultipleContactModal")
  )
);
const SkipSendingMultipleDetails = lazy(() =>
  componentLoader(() =>
    import("./modals/SkipSendingModals/SkipSendingMultipleDetails")
  )
);
const RemovePersonModal = lazy(() =>
  componentLoader(() => import("./modals/RemovePersonModal"))
);
const MakeNewModal = lazy(() =>
  componentLoader(() => import("./modals/MakeNewModal"))
);
const FetchResourceFailedModal = lazy(() =>
  componentLoader(() => import("./modals/FetchResourceFailedModal"))
);
const TimeoutResourceFailedModal = lazy(() =>
  componentLoader(() => import("./modals/TimeoutResourceFailedModal"))
);
const CopyToGroupModal = lazy(() =>
  componentLoader(() => import("./modals/CopyToGroupModal"))
);
const DoNotCallModal = lazy(() =>
  componentLoader(() => import("./modals/DoNotCallModal"))
);
const AllowCallsModal = lazy(() =>
  componentLoader(() => import("./modals/AllowCallsModal"))
);
const BlockContactModal = lazy(() =>
  componentLoader(() => import("./modals/BlockContactModal"))
);
const UnsubscribeContactModal = lazy(() =>
  componentLoader(() => import("./modals/UnsubscribeContactModal"))
);
const SubscribeContactModal = lazy(() =>
  componentLoader(() => import("./modals/SubscribeContactModal"))
);
const UnblockContactModal = lazy(() =>
  componentLoader(() => import("./modals/UnblockContactModal"))
);
const SyncGroupModal = lazy(() =>
  componentLoader(() => import("./modals/SyncGroupModal"))
);
const EditCampaignConfirmationModal = lazy(() =>
  componentLoader(() => import("./modals/EditCampaignConfirmationModal"))
);
const NewCbbGroup = lazy(() =>
  componentLoader(() => import("./modals/NewCbbGroup"))
);

const DeleteTagModal = lazy(() =>
  componentLoader(() => import("./modals/DeleteTagModal"))
);
const DeleteTagLHSModal = lazy(() =>
  componentLoader(() => import("./modals/DeleteTagLHSModal"))
);
const ChooseOutgoingSignature = lazy(() =>
  componentLoader(() => import("./modals/ChooseOutgoingSignature"))
);
const NewTagModal = lazy(() =>
  componentLoader(() => import("./modals/NewTagModal"))
);
const ViewOnCCB = lazy(() =>
  componentLoader(() => import("./modals/ViewOnCCB"))
);
// Number Settings user/admin
const NumberRhsSelectNewGreeting = lazy(() =>
  componentLoader(() =>
    import("./modals/UserSettings/SelectNewGreeting/NumberRhsSelectNewGreeting")
  )
);
const DeleteGreeting = lazy(() =>
  componentLoader(() => import("./modals/UserSettings/DeleteGreeting"))
);
const UnpublishGreetingAndVoicemail = lazy(() =>
  componentLoader(() =>
    import("./modals/UserSettings/UnpublishGreetingAndVoicemail")
  )
);
const SettingsUsersAssigned = lazy(() =>
  componentLoader(() => import("./modals/UserSettings/SettingsUsersAssigned"))
);
const EditDoNotCallMessage = lazy(() =>
  componentLoader(() => import("./modals/UserSettings/EditDoNotCallMessage"))
);
const AutoResponder = lazy(() =>
  componentLoader(() =>
    import("./modals/UserSettings/AutoResponder/AutoResponder")
  )
);
const ReleasedNumbers = lazy(() =>
  componentLoader(() => import("./modals/UserSettings/ReleasedNumbers"))
);
const SetUpCallerId = lazy(() =>
  componentLoader(() =>
    import("./modals/UserSettings/SetUpCallerId/SetUpCallerId")
  )
);
const SettingsNumbersAssigned = lazy(() =>
  componentLoader(() => import("./modals/UserSettings/SettingsNumbersAssigned"))
);
const CreateNewGreeting = lazy(() =>
  componentLoader(() =>
    import("./modals/UserSettings/CreateNewGreeting/CreateNewGreeting")
  )
);
const ReplaceCurrentGreeting = lazy(() =>
  componentLoader(() => import("./modals/UserSettings/ReplaceCurrentGreeting"))
);
const ReRecord = lazy(() =>
  componentLoader(() => import("./modals/UserSettings/ReRecord/ReRecord"))
);
const ReRecordVoiceTemplate = lazy(() =>
  componentLoader(() =>
    import("./modals/Templates/ReRecordVoiceTemplate/ReRecordVoiceTemplate")
  )
);
const AddEditSignature = lazy(() =>
  componentLoader(() =>
    import("./modals/UserSettings/AddEditSignature/AddEditSignature")
  )
);
const ViewOrganizationProfileSettings = lazy(() =>
  componentLoader(() =>
    import("./modals/UserSettings/ViewOrganizationProfileSettings")
  )
);
const ExitedOrganizations = lazy(() =>
  componentLoader(() => import("./modals/UserSettings/ExitedOrganizations"))
);
const SwitchOrganization = lazy(() =>
  componentLoader(() => import("./modals/UserSettings/SwitchOrganization"))
);
const LeaveOrganization = lazy(() =>
  componentLoader(() => import("./modals/UserSettings/LeaveOrganization"))
);
const DeleteOrganization = lazy(() =>
  componentLoader(() =>
    import("./modals/UserSettings/DeleteOrganization/DeleteOrganization")
  )
);
const TransferOwnership = lazy(() =>
  componentLoader(() =>
    import("./modals/UserSettings/TransferOwnership/TransferOwnership")
  )
);
const PendingOwnershipTransfer = lazy(() =>
  componentLoader(() =>
    import("./modals/UserSettings/PendingOwnershipTransfer")
  )
);
const AddNewOrganization = lazy(() =>
  componentLoader(() => import("./modals/UserSettings/AddNewOrganization"))
);
const PendingEmailVerification = lazy(() =>
  componentLoader(() =>
    import("./modals/UserSettings/PendingEmailVerification")
  )
);
const VerifyYourNumber = lazy(() =>
  componentLoader(() =>
    import("./modals/UserSettings/VerifyYourNumber/VerifyYourNumber")
  )
);
const AddNewNumber = lazy(() =>
  componentLoader(() =>
    import("./modals/AdminSettings/AddNewNumber/AddNewNumber")
  )
);
const CheckNumberStatus = lazy(() =>
  componentLoader(() =>
    import("./modals/AdminSettings/CheckNumberStatus/CheckNumberStatus")
  )
);
const PreviewSignature = lazy(() =>
  componentLoader(() => import("./modals/AdminSettings/PreviewSignature"))
);
const AddNewUser = lazy(() =>
  componentLoader(() => import("./modals/AdminSettings/AddNewUser/AddNewUser"))
);
const EditUserCredits = lazy(() =>
  componentLoader(() => import("./modals/AdminSettings/EditUserCredits"))
);
const UserPendingInvitation = lazy(() =>
  componentLoader(() => import("./modals/AdminSettings/UserPendingInvitation"))
);
const Integration = lazy(() =>
  componentLoader(() =>
    import("./modals/AdminSettings/Integration/Integration")
  )
);
const CheckoutModal = lazy(() =>
  componentLoader(() =>
    import("./modals/AdminSettings/CheckoutModal/CheckoutModal")
  )
);
const DeleteUser = lazy(() =>
  componentLoader(() => import("./modals/AdminSettings/DeleteUser"))
);
const DeletedUsers = lazy(() =>
  componentLoader(() => import("./modals/AdminSettings/DeletedUsers"))
);
const DeletePaymentMethod = lazy(() =>
  componentLoader(() => import("./modals/AdminSettings/DeletePaymentMethod"))
);
const PaymentInformation = lazy(() =>
  componentLoader(() => import("./modals/AdminSettings/PaymentInformation"))
);
const EnableAutoCharge = lazy(() =>
  componentLoader(() => import("./modals/AdminSettings/EnableAutoCharge"))
);
const CancelPlan = lazy(() =>
  componentLoader(() => import("./modals/AdminSettings/CancelPlan"))
);
const TransactionHistoryFilters = lazy(() =>
  componentLoader(() =>
    import("./modals/AdminSettings/TransactionHistoryFilters")
  )
);
const BillingInfo = lazy(() =>
  componentLoader(() => import("./modals/AdminSettings/BillingInfo"))
);
const ArchiveModal = lazy(() =>
  componentLoader(() => import("./modals/ArchiveModal"))
);
const UnArchiveModal = lazy(() =>
  componentLoader(() => import("./modals/UnArchiveModal"))
);
const ConfirmMyProfileEdit = lazy(() =>
  componentLoader(() =>
    import("./modals/UserSettings/Notifications/ConfirmMyProfileEdit")
  )
);
const RestoreDefaultNotifications = lazy(() =>
  componentLoader(() =>
    import("./modals/UserSettings/Notifications/RestoreDefaultNotifications")
  )
);
const SelectShareableGroups = lazy(() =>
  componentLoader(() =>
    import("./modals/AdminSettings/AddNewUser/SelectShareableGroups")
  )
);

const ConfirmLeavingPageModal = lazy(() =>
  componentLoader(() => import("./modals/ConfirmLeavingPageModal"))
);
// Dashboard
const CustomizeWidgets = lazy(() =>
  componentLoader(() => import("./modals/Dashboard/CustomizeWidgets"))
);
const NotificationsNumbersToFollow = lazy(() =>
  componentLoader(() =>
    import("./modals/UserSettings/NotificationsNumbersToFollow")
  )
);
// Templates
const AddNewSmsTemplate = lazy(() =>
  componentLoader(() => import("./modals/Templates/AddNewSmsTemplate"))
);

const AddNewVoiceTemplates = lazy(() =>
  componentLoader(() =>
    import("./modals/Templates/AddNewVoiceTemplates/AddNewVoiceTemplates")
  )
);
// Campaigns
const CampaignSetupModal = lazy(() =>
  componentLoader(() => import("./modals/Campaigns/CampaignSetupModal"))
);
const ContestSetup = lazy(() =>
  componentLoader(() => import("./modals/Campaigns/ContestSetup/ContestSetup"))
);
const PollSetup = lazy(() =>
  componentLoader(() => import("./modals/Campaigns/PollSetup/PollSetup"))
);
const SmsBirthdaySetup = lazy(() =>
  componentLoader(() =>
    import("./modals/Campaigns/SmsBirthdaySetup/SmsBirthdaySetup")
  )
);
const DataCaptureSetup = lazy(() =>
  componentLoader(() =>
    import("./modals/Campaigns/DataCaptureSetup/DataCaptureSetup")
  )
);
const AutoReplySetup = lazy(() =>
  componentLoader(() =>
    import("./modals/Campaigns/AutoReplySetup/AutoReplySetup")
  )
);
const SeriesCampaignSetup = lazy(() =>
  componentLoader(() =>
    import("./modals/Campaigns/SeriesCampaignSetup/SeriesCampaignSetup")
  )
);
const ContestHistory = lazy(() =>
  componentLoader(() => import("./modals/Campaigns/ContestHistory"))
);
const PollResultsCampaign = lazy(() =>
  componentLoader(() => import("./modals/Campaigns/PollResultsCampaign"))
);

const ChangePersonalNumberModal = lazy(() =>
  componentLoader(() =>
    import("./modals/ChangePersonalNumberModal/ChangePersonalNumberModal")
  )
);

const ViewAllConflictsProfilesModal = lazy(() =>
  componentLoader(() =>
    import("./modals/SmartSync/ViewAllConflictsProfilesModal")
  )
);
const ClaimCredits = lazy(() =>
  import("./modals/UserSettings/RewardsCenter/ClaimCredits")
);
const SmartMergeGroupLink = lazy(() =>
  componentLoader(() => import("./modals/SmartSync/SmartMergeGroupLink"))
);

const VideoViewer = lazy(() =>
  componentLoader(() => import("../components/modals/Video/VideoViewer"))
);

const VideoTrimmer = lazy(() =>
  componentLoader(() => import("../components/modals/Video/VideoTrimmer"))
);

const ChooseThemeModal = lazy(() =>
  componentLoader(() => import("./modals/ChooseThemeModal"))
);

const ApplyTagsModal = lazy(() =>
  componentLoader(() => import("./modals/ApplyTagsModal"))
);

const LegacyGiftClaimingModal = lazy(() =>
  componentLoader(() => import("./modals/Gifts/LegacyGiftClaimingModal"))
);

const ScheduleMessageModal = lazy(() =>
  componentLoader(() => import("./modals/ScheduleMessageModal"))
);

const ConfirmRestrictedPhraseModal = lazy(() =>
  componentLoader(() => import("./modals/ConfirmRestrictedPhraseModal"))
);

const ConfirmLinkImageWarningModal = lazy(() =>
  componentLoader(() => import("./modals/ConfirmLinkImageWarningModal"))
);

const ConfirmAlreadyScheduledModal = lazy(() =>
  componentLoader(() => import("./modals/ConfirmAlreadyScheduledModal"))
);

const DisableShortCodeTwoWayTextMsgsModal = lazy(() =>
  componentLoader(() =>
    import(
      "./modals/AdminSettings/DisableShortCodeTwoWayTextMsgs/DisableShortCodeTwoWayTextMsgs"
    )
  )
);

const DisableTollFreeTwoWayTextMsgsModal = lazy(() =>
  componentLoader(() =>
    import(
      "./modals/AdminSettings/DisableTollFreeTwoWayTextMsgs/DisableTollFreeTwoWayTextMsgs"
    )
  )
);

const DisableAutoReplyKeywordCampaignsModal = lazy(() =>
  componentLoader(() =>
    import(
      "./modals/AdminSettings/DisableShortCodeAutoReplyKeywordCampaigns/DisableShortCodeAutoReplyKeywordCampaigns"
    )
  )
);

const ReleaseShortCodeModal = lazy(() =>
  componentLoader(() =>
    import("./modals/AdminSettings/ReleaseShortCode/ReleaseShortCode")
  )
);

const ShortcodeWarningModal = lazy(() =>
  componentLoader(() =>
    import("./modals/ShortcodeWarningModal/ShortcodeWarningModal")
  )
);

const ReleaseTollFreeModal = lazy(() =>
  componentLoader(() =>
    import("./modals/AdminSettings/ReleaseTollFree/ReleaseTollFree")
  )
);

const NewVersionAvailableModal = lazy(() =>
  componentLoader(() => import("./modals/Dashboard/NewVersionAvailableModal"))
);

const SendNewMessagePreviewModal = lazy(() =>
  componentLoader(() =>
    import("./modals/SendNewMessagePreviewModal/SendNewMessagePreviewModal")
  )
);

const ConfirmDifferentSenderModal = lazy(() =>
  componentLoader(() => import("./modals/ConfirmDifferentSenderModal"))
);

const AddVcard = lazy(() =>
  componentLoader(() => import("./modals/AddVcard/AddVcard"))
);

const ConfirmSendMessageModal = lazy(() =>
  componentLoader(() =>
    import("./modals/ConfirmSendMessageModal/ConfirmSendMessageModal")
  )
);

const BroadcastGroupWarningModal = lazy(() =>
  componentLoader(() => import("./modals/BroadcastGroupWarningModal"))
);

const RenameBroadcastGroupModal = lazy(() =>
  componentLoader(() => import("./modals/RenameBroadcastGroupModal"))
);

const ResendMessagesNumberInformationModal = lazy(() =>
  componentLoader(() =>
    import("./modals/Messages/ResendMessagesNumberInformationModal")
  )
);

const DuplicateProfileUpdatedInfoModal = lazy(() =>
  componentLoader(() =>
    import("./modals/Messages/DuplicateProfileUpdatedInfoModal")
  )
);

const DisplayPreferenceModal = lazy(() =>
  componentLoader(() =>
    import("./modals/DisplayPreference/DisplayPreferenceModal")
  )
);

export default class LayoutModals extends React.PureComponent {
  deleteModalsByPermissions = (modals) => {
    const { userPermissions } = this.props;
    if (!getPermissionParsed(userPermissions?.messages_calls, "send_msg")) {
      delete modals["new-message-receiver"];
    }
    if (!getPermissionParsed(userPermissions?.people, "add")) {
      delete modals["add-person"];
    }
    if (!getPermissionParsed(userPermissions?.groups, "add")) {
      delete modals["new-group"];
    }
    if (
      !getPermissionParsed(userPermissions?.messages_calls, "send_broadcast") &&
      !getPermissionParsed(userPermissions?.messages_calls, "call")
    ) {
      delete modals["new-call"];
    }
    if (
      !getPermissionParsed(userPermissions?.messages_calls, "send_broadcast")
    ) {
      delete modals["new-voice-broadcast"];
    }
    if (!getPermissionParsed(userPermissions?.campaigns, "edit")) {
      delete modals["edit-campaign-confirmation"];
    }
  };

  render() {
    const {
      isCompanyLoaded,
      isCurrentCompanyAutoSwitched,
      company,
      onCloseCurrentCompanyAutoSwitched,
    } = this.props;
    let modals = {
      "choose-organization": ChooseOrganizationModal,
      "message-sent": MessageSentModal,
      "host-landline": HostLandlineModal,
      "choose-theme": ChooseThemeModal,
    };
    if (isCompanyLoaded) {
      modals = {
        ...modals,
        "add-person": NewContactModal,
        "manage-contact-groups": ManageContactGroupsModal,
        "manage-contact-tags": ManageContactTagsModal,
        "manage-contact-am-campaigns": ManageContactAmCampaignsModal,
        "number-lookup": NumberLookupModal,
        "new-message-receiver": NewMessageReceiverModal,
        "hub-messages-filters": HubMessagesFiltersModal,
        "add-new": AddNewModal,
        "add-broadcast": AddBroadcastModal,
        "new-group": AddNewGroupModal,
        "copy-to-group": CopyToGroupModal,
        "do-not-call-contact": DoNotCallModal,
        "allow-call-contact": AllowCallsModal,
        "manage-group-am-campaigns": ManageGroupAmCampaignsModal,
        "manage-group-ext-apps": ManageGroupExtAppsModal,
        "account-issue": AccountIssueModal,
        "add-people-to-group": AddPeopleToGroupModal,
        "delete-contact": DeleteContactModal,
        "delete-duplicate-contact": DeleteDuplicateContactModal,
        "block-duplicate-contact": BlockDuplicateContactModal,
        "unblock-duplicate-contact": UnblockDuplicateContactModal,
        "unsubscribe-duplicate-contact": UnsubscribeDuplicateContactModal,
        "do-not-call-duplicate-contact": DoNotCallDuplicateContactModal,
        "allow-call-duplicate-contact": AllowCallDuplicateContactModal,
        "subscribe-duplicate-contact": SubscribeDuplicateContactModal,
        "lookup-queued": LookupQueuedModal,
        "lookup-result": LookupResultModal,
        "new-voice-broadcast": VoiceBroadcastModal,
        "edit-voice-broadcast": VoiceBroadcastModal,
        "short-links": ShortLinksModal,
        "new-short-link": NewShortLinkModal,
        "message-templates": MessageTemplatesModal,
        "new-message-template": MessageTemplateModal,
        "edit-message-template": MessageTemplateModal,
        "new-call": NewCallModal,
        signature: SignatureModal,
        "skip-sending": SkipSendingModal,
        "skip-sending-multiple-contact": SkipSendingMultipleModal,
        "remove-person": RemovePersonModal,
        "skip-sending-details": SkipSendingMultipleDetails,
        "make-new": MakeNewModal,
        "fetch-resource-failed": FetchResourceFailedModal,
        "timeout-resource-failed": TimeoutResourceFailedModal,
        "block-contact": BlockContactModal,
        "unsubscribe-contact": UnsubscribeContactModal,
        "subscribe-contact": SubscribeContactModal,
        "unblock-contact": UnblockContactModal,
        "sync-group": SyncGroupModal,
        "delete-tag": DeleteTagModal,
        "delete-tag-lhs": DeleteTagLHSModal,
        "edit-campaign-confirmation": EditCampaignConfirmationModal,
        "new-cbb-group": NewCbbGroup,
        "select-new-greeting": NumberRhsSelectNewGreeting,
        "delete-greeting": DeleteGreeting,
        unpublish: UnpublishGreetingAndVoicemail,
        "settings-users-assigned": SettingsUsersAssigned,
        "edit-do-not-call-message": EditDoNotCallMessage,
        "auto-responder": AutoResponder,
        "released-numbers": ReleasedNumbers,
        "set-up-caller-id": SetUpCallerId,
        "add-new-number": AddNewNumber,
        "check-number-status": CheckNumberStatus,
        "preview-signature": PreviewSignature,
        "settings-numbers-assigned": SettingsNumbersAssigned,
        "create-new-greeting": CreateNewGreeting,
        "replace-current-greeting": ReplaceCurrentGreeting,
        "re-record": ReRecord,
        "re-record-voicemail": ReRecordVoiceTemplate,
        "choose-outgoing-signature": ChooseOutgoingSignature,
        archive: ArchiveModal,
        unarchive: UnArchiveModal,
        "add-edit-signature": AddEditSignature,
        "view-organization-profile-settings": ViewOrganizationProfileSettings,
        "exited-organizations": ExitedOrganizations,
        "switch-organization": SwitchOrganization,
        "leave-organization": LeaveOrganization,
        "delete-organization": DeleteOrganization,
        "transfer-ownership": TransferOwnership,
        "pending-ownership-transfer": PendingOwnershipTransfer,
        "add-new-organization": AddNewOrganization,
        "pending-email-verification": PendingEmailVerification,
        "verify-your-number": VerifyYourNumber,
        "confirm-profile-edit": ConfirmMyProfileEdit,
        "restore-default-notifications": RestoreDefaultNotifications,
        "new-tag": NewTagModal,
        "user-pending-invitation": UserPendingInvitation,
        "edit-user-credits": EditUserCredits,
        "add-new-user": AddNewUser,
        "delete-user": DeleteUser,
        "deleted-users": DeletedUsers,
        "modal-select-shareable-groups": SelectShareableGroups,
        integration: Integration,
        checkout: CheckoutModal,
        "view-on-ccb": ViewOnCCB,
        "customize-widgets": CustomizeWidgets,
        "notifications-numbers-to-follow": NotificationsNumbersToFollow,
        "confirm-leaving-page": ConfirmLeavingPageModal,
        "payment-information": PaymentInformation,
        "delete-payment-method": DeletePaymentMethod,
        "enable-auto-charge": EnableAutoCharge,
        "cancel-plan": CancelPlan,
        "transaction-history-filters": TransactionHistoryFilters,
        "billing-info": BillingInfo,
        "add-new-sms-templates": AddNewSmsTemplate,
        "add-new-voice-templates": AddNewVoiceTemplates,
        "filter-numbers": FilterNumbersModal,
        "campaign-setup": CampaignSetupModal,
        "contest-setup": ContestSetup,
        "poll-setup": PollSetup,
        "sms-birthday-setup": SmsBirthdaySetup,
        "data-capture-setup": DataCaptureSetup,
        "auto-reply-setup": AutoReplySetup,
        "series-campaign-setup": SeriesCampaignSetup,
        "content-history": ContestHistory,
        "poll-results": PollResultsCampaign,
        "new-call-dialpad": NewCallDialpadModal,
        "claim-credits": ClaimCredits,
        "change-personal-number": ChangePersonalNumberModal,
        "view-all-conflicts-profiles": ViewAllConflictsProfilesModal,
        "smart-merge-group": SmartMergeGroupLink,
        "video-viewer": VideoViewer,
        "video-trimmer": VideoTrimmer,
        "choose-theme": ChooseThemeModal,
        "apply-tags": ApplyTagsModal,
        "gift-claim": LegacyGiftClaimingModal,
        "schedule-message": ScheduleMessageModal,
        "confirm-restricted-phrase": ConfirmRestrictedPhraseModal,
        "confirm-link-image-warning": ConfirmLinkImageWarningModal,
        "confirm-already-scheduled": ConfirmAlreadyScheduledModal,
        "disable-short-code-two-way-text-msgs":
          DisableShortCodeTwoWayTextMsgsModal,
        "disable-short-code-auto-reply-and-keyword-campaigns":
          DisableAutoReplyKeywordCampaignsModal,
        "disable-toll-free-two-way-text-msgs":
          DisableTollFreeTwoWayTextMsgsModal,
        "release-short-code": ReleaseShortCodeModal,
        "short-code-warning": ShortcodeWarningModal,
        "release-toll-free": ReleaseTollFreeModal,
        "new-version-available": NewVersionAvailableModal,
        "preview-message": SendNewMessagePreviewModal,
        "confirm-different-sender": ConfirmDifferentSenderModal,
        "add-vcard": AddVcard,
        "confirm-send-message": ConfirmSendMessageModal,
        "broadcast-group-warning": BroadcastGroupWarningModal,
        "rename-broadcast-group": RenameBroadcastGroupModal,
        "resend-messages-number-info": ResendMessagesNumberInformationModal,
        "duplicate-profile-updated-info": DuplicateProfileUpdatedInfoModal,
        "display-preferences": DisplayPreferenceModal,
      };
      this.deleteModalsByPermissions(modals);
    }

    return (
      <>
        <ModalsManager {...this.props} modals={modals} />
        {/* Simple modals not based on URL */}
        {isCurrentCompanyAutoSwitched && (
          <CompanyAutoSwitchedModal
            company={company}
            closeModal={onCloseCurrentCompanyAutoSwitched}
          />
        )}
      </>
    );
  }
}
