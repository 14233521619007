import axios from "axios";
import AxiosConfig from "../../AxiosConfig";

export function setNumberSettingsData(data) {
  return function (dispatch) {
    dispatch({
      type: "MENUS_SETTINGS/SET_NUMBER_SETTINGS_DATA",
      data,
    });
  };
}

export function clearNumberSettingsData(data) {
  return function (dispatch) {
    dispatch({
      type: "MENUS_SETTINGS/SET_NUMBER_SETTINGS_DATA.CLEAR",
    });
  };
}

export function setSelectedNumberSignature(selectedNumberSignature) {
  return function (dispatch) {
    dispatch({
      type: "MENUS_SETTINGS/SET_SELECTED_NUMBER_SIGNATURE",
      selectedNumberSignature,
    });
  };
}

export function updateNumberSettingsData(params) {
  return function (dispatch) {
    dispatch({
      type: "MENUS_SETTINGS/UPDATE_NUMBER_SETTINGS",
      payload: { field: params.field, data: params.data },
    });
  };
}

export function clearUpdateNumberSettingsStatus() {
  return function (dispatch) {
    dispatch({
      type: "MENUS_SETTINGS/CLEAR_UPDATE_NUMBER_SETTINGS_STATUS",
    });
  };
}

export function clearUpdateAutoResponderStatus() {
  return function (dispatch) {
    dispatch({
      type: "MENUS_SETTINGS/UPDATE_AUTO_RESPONDER.CLEAR",
    });
  };
}

export function setNumberToAutoSelect(numberToAutoSelect) {
  return function (dispatch) {
    dispatch({
      type: "MENUS_SETTINGS/SET_NUMBER_TO_AUTO_SELECT",
      numberToAutoSelect,
    });
  };
}

export function updateAutoResponder(
  company_id,
  number,
  type,
  is_enabled,
  message,
  frequency,
  allParams = {}
) {
  return function (dispatch) {
    dispatch({ type: "MENUS_SETTINGS/UPDATE_AUTO_RESPONDER" });
    const data = {
      company_id,
      number,
      type,
      is_enabled,
      message,
      frequency,
      ...allParams,
    };
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "numbers/autoresponders.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "MENUS_SETTINGS/UPDATE_AUTO_RESPONDER.SUCCESS",
          isEnabled: is_enabled,
          autoType: type,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "MENUS_SETTINGS/UPDATE_AUTO_RESPONDER.ERROR",
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}
