import { LinearProgress } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";

// import PropTypes from "prop-types";
import { ReactComponent as ExpandArrow } from "../../../assets/img/icons-new/general/expand/expand-black.svg";
import { ReactComponent as CollapseArrow } from "../../../assets/img/icons-new/general/expand/expand-up-black.svg";
// import { ReactComponent as OutlinedPhone } from "../../../assets/img/verification/outlined-phone.svg";
// import { ReactComponent as OutlinedNewNumberIcon } from "../../../assets/img/verification/outlined-new-number-icon.svg";
// import { ReactComponent as OutlinedLandlineIcon } from "../../../assets/img/verification/outlined-landline-icon.svg";
import { ReactComponent as GreenCheckedIcon } from "../../../assets/img/icons-new/message-tab/closed/closed-green.svg";
// import NumberSetupModal from "./NumberSetupModal";
import SetupItem from "./SetupItem";
// import ButtonIconTextCard from "../../elements/ButtonIconTextCard";
// import AddUsers from "./AddUsers/AddUsers";
// import AddNewNumber from "./AddNewNumber/AddNewNumber";
import { connect } from "react-redux";
import { hasNumberAssigned } from "../../../helpers";
import { addDataForModal } from "../../../actions/main";
import {
  fetchCompanies,
  fetchUsers,
  skipSetupProfileProd,
  clearSkipSendTestStatus,
} from "../../../actions/companies";
import { ASSIGNED_NUMBER_ALERT_LINK } from "../../../utils/constants";
import { history } from "../../../store";
import {
  ADMIN_NUMBERS_TEXT_SMS,
  USER_SIGNATURES_ROUTE,
  USER_SETTINGS_ROUTE,
  SETTINGS_ROUTE,
} from "../../Menus/routes";
import { getPermissionParsed } from "../../../utils/settingsHelpers";
import ButtonWithTooltip from "../../ButtonWithTooltip";
import ButtonWithSpinner from "../../hub/HelperComponents/ButtonWithSpinner";
// import SendDemoTextModal from "./SendDemoTextModal";

// const addNewNumberOptions = (
//   <div className="d-flex justify-content-center align-items-center flex-column mt-4">
//     <ButtonIconTextCard
//       text={
//         <div className="text-option">
//           <span className="option-title">New Number</span>
//           <br />
//           <span className="option-description">
//             Pick a Brand new Virtual Number
//           </span>
//         </div>
//       }
//       icon={<OutlinedNewNumberIcon className="add-number-icon pt-2" />}
//       iconHover={<OutlinedNewNumberIcon className="add-number-icon pt-2" />}
//       className="d-flex flex-column justify-content-center add-number-option"
//       onClick={() => {}}
//     />
//     <ButtonIconTextCard
//       text={
//         <div className="text-option">
//           <span className="option-title">New + Existing Number</span>
//           <br />
//           <span className="option-description">
//             Both New Virtual Number and existing Organization Landline
//             (Recommended)
//           </span>
//         </div>
//       }
//       icon={
//         <div className="d-flex align-items-center justify-content-center">
//           <OutlinedNewNumberIcon className="add-number-icon pt-2" />
//           <span className="plus-icon">+</span>
//           <OutlinedLandlineIcon className="add-number-icon pt-2" />
//         </div>
//       }
//       iconHover={
//         <div className="d-flex align-items-center justify-content-center">
//           <OutlinedNewNumberIcon className="add-number-icon pt-2" />
//           <span className="plus-icon">+</span>
//           <OutlinedLandlineIcon className="add-number-icon pt-2" />
//         </div>
//       }
//       className="d-flex flex-column justify-content-center add-number-option mt-4 mb-4"
//       onClick={() => {}}
//     />
//     <ButtonIconTextCard
//       text={
//         <div className="text-option">
//           <span className="option-title">Existing Number</span>
//           <br />
//           <span className="option-description">
//             Use already existing Organization Landline Number
//           </span>
//         </div>
//       }
//       icon={<OutlinedLandlineIcon className="add-number-icon pt-2" />}
//       iconHover={<OutlinedLandlineIcon className="add-number-icon pt-2" />}
//       className="d-flex flex-column justify-content-center add-number-option"
//       onClick={() => {}}
//     />
//   </div>
// );

function mapStateToProps(store, ownProps) {
  return {
    companyId: store.companies.currentCompany.id,
    currentCompany: store.companies.currentCompany,
    numbers: store.numbers.numbers,
    users: store.companies.users,
    userPermissions: store.users?.permissions,
    skipSetupProfileStatus: store.companies.skipSetupProfileStatus,
    signatures: store.numbers.signatures,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUsers: (params) => dispatch(fetchUsers(params)),
    fetchCompanies: (isResetCurrentCompany) =>
      dispatch(fetchCompanies(isResetCurrentCompany)),
    skipSetupProfileProd: (companyId) =>
      dispatch(skipSetupProfileProd(companyId)),
    clearSkipSendTestStatus: () => dispatch(clearSkipSendTestStatus()),
    addDataForModal: (dataForModal) => dispatch(addDataForModal(dataForModal)),
  };
}

const DashboardSetup = (props) => {
  const {
    // Redux props
    numbers,
    currentCompany,
    companyId,
    users,
    userPermissions,
    skipSetupProfileStatus,
    clearSkipSendTestStatus,
    signatures,
    // Redux func
    fetchUsers,
    fetchCompanies,
    skipSetupProfileProd,
    addDataForModal,
  } = props;

  const [isExpanded, setIsExpanded] = useState(true);

  // const [chooseNumberModalIsOpen, setChooseNumberModalIsOpen] = useState(false);
  // const [landlineSetupIsOpen, setLandlineSetupIsOpen] = useState(false);
  // const [chooseTypeOfNumberIsOpen, setChooseTypeOfNumberIsOpen] = useState(
  //   false
  // );
  // const [openTransitionToLandline, setOpenTransitionToLandline] = useState(
  //   false
  // );
  // const [addUsersIsOpen, setAddUsersIsOpen] = useState(false);
  // const [addNewNumberIsOpen, setAddNewNumberIsOpen] = useState(false);
  // const [sendTemoTextIsOpen, setSendTemoTextIsOpen] = useState(false);
  // const [
  //   sendDemoTextConfirmationIsOpen,
  //   setSendDemoTextConfirmationIsOpen,
  // ] = useState(false);

  const isNumberSetUp = false;
  const areUsersSetUp = false;

  const hasUsers = users?.length > 1;
  const showUserWarning = users?.length === 1;
  const hasSentTestSms = currentCompany?.has_sent_test_sms === 1;

  const isSignaturesSet = useMemo(() => {
    let isSignature = false;
    if (signatures.length === 1) {
      if (signatures[0].signatures.length !== 0) {
        isSignature = true;
      }
    } else if (signatures.length > 1) {
      isSignature = true;
    }
    return isSignature;
  }, [signatures]);

  useEffect(() => {
    // fetch companies again to make the setup component dissappear
    fetchCompanies(true);
    fetchUsers({ companyId, status: "all" });
  }, [companyId, fetchCompanies, fetchUsers, skipSetupProfileStatus]);

  useEffect(() => {
    if (
      skipSetupProfileStatus === "success" ||
      skipSetupProfileStatus === "error"
    ) {
      clearSkipSendTestStatus();
    }
  }, [skipSetupProfileStatus, clearSkipSendTestStatus]);

  const toggleDropdown = useCallback(() => {
    setIsExpanded((prevState) => !prevState);
  }, []);

  const withAssignedNumber = useMemo(
    () => hasNumberAssigned(numbers),
    [numbers]
  );

  const progressPercentage = useMemo(() => {
    const progress = [
      !!withAssignedNumber,
      !!hasUsers,
      !!hasSentTestSms,
      !!isSignaturesSet,
    ];
    const progressCount = progress.filter((item) => item);
    return progressCount.length * 25;
  }, [hasSentTestSms, hasUsers, withAssignedNumber, isSignaturesSet]);

  const onDismiss = useCallback(() => {
    skipSetupProfileProd(companyId);
  }, [skipSetupProfileProd, companyId]);

  const onRedirect = useCallback(
    (url) => () => {
      history.push(url);
    },
    []
  );

  const hasSentTestSmsPermission = getPermissionParsed(
    userPermissions?.dashboard,
    "send_test_message"
  );

  const handleClickSetSignature = useCallback(() => {
    addDataForModal({
      isOnAddSignature: true,
    });
    history.push(
      `hub/menus/filter/${SETTINGS_ROUTE}/${USER_SETTINGS_ROUTE}/submenu/${USER_SIGNATURES_ROUTE}#modal-add-edit-signature`
    );
  }, [addDataForModal]);

  return (
    <div className="dashboard-setup-container">
      <div className="setup-items-container">
        <SetupItem
          title="Set Up Your Profile!"
          description={
            <>
              <div className="pt-2 pb-3 setup-level-container">
                <LinearProgress
                  variant="determinate"
                  value={progressPercentage}
                  className="setup-level mr-2"
                />
                <span> Profile {progressPercentage}% Complete</span>
              </div>
              <div className="d-flex flex-column">
                <span>
                  Please complete the following tasks so that you can utilize
                  our services completely!
                </span>

                <ButtonWithSpinner
                  className="btn-sm setup-dismiss"
                  onClick={onDismiss}
                  loading={skipSetupProfileStatus === "loading"}
                >
                  Dismiss
                </ButtonWithSpinner>
              </div>
            </>
          }
          rightButton={
            <div onClick={toggleDropdown} className="cursor-pointer">
              {isExpanded ? (
                <CollapseArrow className="collapse-expand-icon" />
              ) : (
                <ExpandArrow className="collapse-expand-icon" />
              )}
            </div>
          }
        />
        {isExpanded && (
          <div>
            <hr />
            {isNumberSetUp ? (
              <SetupItem
                title="Finishing setting up your Landline"
                rightButton={
                  <div className="flexer-row gap-2">
                    {withAssignedNumber && (
                      <GreenCheckedIcon height={32} width={32} />
                    )}
                    {isNumberSetUp && areUsersSetUp && (
                      <button
                        className="btn btn-outline-primary no-border"
                        onClick={() => {}}
                      >
                        Dismiss for Now
                      </button>
                    )}
                    <button
                      className="btn btn-primary setup-button"
                      onClick={() => {
                        // setLandlineSetupIsOpen(true);
                      }}
                    >
                      Choose Number
                    </button>
                  </div>
                }
                className="sub-option"
              />
            ) : (
              <SetupItem
                title="Choose Your First Number"
                rightButton={
                  <div className="flexer-row gap-2">
                    {withAssignedNumber && (
                      <GreenCheckedIcon height={32} width={32} className="" />
                    )}
                    <button
                      className="btn btn-primary setup-button"
                      disabled={withAssignedNumber}
                      onClick={onRedirect(ASSIGNED_NUMBER_ALERT_LINK)}
                    >
                      Choose Number
                    </button>
                  </div>
                }
                className="sub-option"
                showWarning={!withAssignedNumber}
              />
            )}
            <hr />
            <SetupItem
              title="Invite Users"
              rightButton={
                <div className="flexer-row gap-2">
                  {hasUsers && (
                    <GreenCheckedIcon height={32} width={32} className="" />
                  )}
                  <button
                    className="btn btn-primary setup-button"
                    onClick={onRedirect(
                      "/hub/menus/filter/settings/admin-settings/submenu/admin-users/active-users#modal-add-new-user"
                    )}
                    disabled={hasUsers}
                  >
                    Invite Users
                  </button>
                </div>
              }
              className="sub-option"
              showWarning={showUserWarning}
            />
            <hr />
            <SetupItem
              title="Send a Demo Text"
              rightButton={
                <div className="flexer-row gap-2">
                  {hasSentTestSms && (
                    <GreenCheckedIcon height={32} width={32} className="" />
                  )}
                  <ButtonWithTooltip
                    bootstrapButtonClassName="btn btn-primary setup-button"
                    className="setup-button"
                    title={
                      !hasSentTestSmsPermission
                        ? "You do not have permission to send a test sms"
                        : ""
                    }
                    onClick={onRedirect(
                      `/hub/menus/filter/settings/admin-settings/submenu/${ADMIN_NUMBERS_TEXT_SMS}`
                    )}
                    disabled={hasSentTestSms || !hasSentTestSmsPermission}
                  >
                    Send Demo Text
                  </ButtonWithTooltip>
                </div>
              }
              className="sub-option"
              showWarning={currentCompany?.has_sent_test_sms === 0}
            />
            <hr />
            <SetupItem
              title="Set up personalized signature"
              rightButton={
                <div className="flexer-row gap-2">
                  {isSignaturesSet && (
                    <GreenCheckedIcon height={32} width={32} className="" />
                  )}
                  <ButtonWithTooltip
                    bootstrapButtonClassName="btn btn-primary setup-button"
                    className="setup-button"
                    title={""}
                    onClick={handleClickSetSignature}
                    disabled={isSignaturesSet}
                  >
                    Set signature
                  </ButtonWithTooltip>
                </div>
              }
              className="sub-option"
              showWarning={!isSignaturesSet}
            />
          </div>
        )}
      </div>
      {/* <NumberSetupModal
        show={chooseNumberModalIsOpen}
        closeModal={() => setChooseNumberModalIsOpen(false)}
        title="Choose A Number"
        icon={<OutlinedPhone className="phone-icon" />}
        description="Please Set Up A Number So That You Can Utilize Pastorsline Services
            Fully!"
        footerButtons={
          <>
            <button className="btn btn-outline-primary no-border">
              Do this later
            </button>
            <button className="btn btn-primary">Set Up Now</button>
          </>
        }
      />
      <NumberSetupModal
        show={landlineSetupIsOpen}
        closeModal={() => setLandlineSetupIsOpen(false)}
        title="Set up Your Existing Landline"
        icon={<OutlinedLandlineIcon className="phone-icon" />}
        description="Hi Utsah! You Still Have To Set Up Your Landline Number (Hosted Sms)"
        footerButtons={
          <>
            <button className="btn btn-outline-primary no-border">
              Do this later
            </button>
            <button className="btn btn-primary">Set Up Now</button>
          </>
        }
      />
      <NumberSetupModal
        show={chooseTypeOfNumberIsOpen}
        closeModal={() => setChooseTypeOfNumberIsOpen(false)}
        title={<div style={{ textAlign: "center" }}>Add New Number</div>}
        description={addNewNumberOptions}
        className="modal-content-large themed-modal"
        footerButtons={
          <button
            className="btn btn-primary"
            onClick={() => {
              setChooseTypeOfNumberIsOpen(false);
              setAddNewNumberIsOpen(true);
            }}
          >
            Next Step
          </button>
        }
        singleFooterButton
      />
      <AddNewNumber
        show={addNewNumberIsOpen}
        closeModal={() => {
          setAddNewNumberIsOpen(false);
          setOpenTransitionToLandline(true);
        }}
      />
      <NumberSetupModal
        show={openTransitionToLandline}
        closeModal={() => setOpenTransitionToLandline(false)}
        title="Set up Hosted SMS"
        description={
          <>
            <div className="added-number-text">
              New Number Added <GreenCheckedIcon />
            </div>
            <div>
              Congratulations Utsah!
              <br />
              You Just Added A New Number!
              <br />
              Now Let's Set Up Your <b>Existing Landline</b> (Hosted Sms)
            </div>
          </>
        }
        footerButtons={
          <>
            <button className="btn btn-outline-primary no-border">
              Do this later
            </button>
            <button className="btn btn-primary">Set Up Now</button>
          </>
        }
      />
      <NumberSetupModal
        show={sendDemoTextConfirmationIsOpen}
        closeModal={() => setSendDemoTextConfirmationIsOpen(false)}
        title="Send a demo text"
        description={
          <div className="send-demo-confirmation-text">
            <div>
              A demo text was sent to <b>+1 (425)-305-0588</b>
            </div>
            <div>From +1 (425)-305-0588</div>
            <div className="added-number-text">
              Text Sent! <GreenCheckedIcon />
            </div>
            <div>
              Didn't get a text? <a href="#void">Resend Text</a>
            </div>
          </div>
        }
        footerButtons={
          <>
            <button className="btn btn-outline-primary no-border">Back</button>
            <button className="btn btn-primary">See my Text!</button>
          </>
        }
      /> */}
      {/* <AddUsers
        show={addUsersIsOpen}
        closeModal={() => setAddUsersIsOpen(false)}
      /> */}
      {/* <SendDemoTextModal
        show={sendTemoTextIsOpen}
        closeModal={() => setSendTemoTextIsOpen(false)}
        setSendDemoTextConfirmationIsOpen={setSendDemoTextConfirmationIsOpen}
      /> */}
    </div>
  );
};

DashboardSetup.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSetup);
