import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as usersActions from "../../../actions/users";

export function useUsersStore() {
  const dispatch = useDispatch();
  const _users = useSelector((store) => store.users);

  const updateUserSettings = useCallback(
    async (params, requestId) =>
      await dispatch(usersActions.updateUserSettings(params, requestId)),
    [dispatch]
  );

  const clearUpdateUserSettingsStatus = useCallback(
    async () => await dispatch(usersActions.clearUpdateUserSettingsStatus()),
    [dispatch]
  );

  const fetchUserData = useCallback(
    async () => await dispatch(usersActions.fetchUserData()),
    [dispatch]
  );

  const fetchCredits = useCallback(
    async (companyId) => await dispatch(usersActions.fetchCredits(companyId)),
    [dispatch]
  );

  const toggleFullView = useCallback(
    async (viewValue) =>
      await dispatch({ type: "rhs/TOGGLE_FILTERS", viewValue }),
    [dispatch]
  );

  const logoutAction = useCallback(
    async () => await dispatch(usersActions.logoutAction()),
    [dispatch]
  );

  const logout = useCallback(
    async () => await dispatch(usersActions.logout()),
    [dispatch]
  );
  const registerUser = useCallback(
    (params) => dispatch(usersActions.registerUser(params)),
    [dispatch]
  );
  const clearRegisterUserStatusAndError = useCallback(
    () => dispatch(usersActions.clearRegisterUserStatusAndError()),
    [dispatch]
  );

  const clearRegisteredUserEmail = useCallback(
    () => dispatch(usersActions.clearRegisteredUserEmail()),
    [dispatch]
  );
  const updateMainMenuLook = useCallback(
    (mainMenuLook) => dispatch(usersActions.updateMainMenuLook(mainMenuLook)),
    [dispatch]
  );

  const v3LoginMutation = useCallback(
    async (params) => await dispatch(usersActions.v3Login(params)),
    [dispatch]
  );

  const clearV3LoginUserStatusAndError = useCallback(
    async () => await dispatch(usersActions.clearV3LoginUserStatusAndError()),
    [dispatch]
  );

  const forgotPassword = useCallback(
    async (params) => await dispatch(usersActions.forgotPassword(params)),
    [dispatch]
  );

  const clearForgoPasswordStatusAndError = useCallback(
    async () => await dispatch(usersActions.clearForgoPasswordStatusAndError()),
    [dispatch]
  );

  const startLegacySession = useCallback(
    async (show) => await dispatch(usersActions.startLegacySession(show)),
    [dispatch]
  );

  const updateMainMenuLookOnMessages = useCallback(
    async (mainMenuLookOnMessages) =>
      await dispatch(
        usersActions.updateMainMenuLookOnMessages(mainMenuLookOnMessages)
      ),
    [dispatch]
  );

  const setShowCfChallengeOverlay = useCallback(
    async (show) =>
      await dispatch(usersActions.setShowCfChallengeOverlay(show)),
    [dispatch]
  );

  return {
    users: _users,
    updateUserSettings,
    clearUpdateUserSettingsStatus,
    fetchUserData,
    fetchCredits,
    toggleFullView,
    registerUser,
    clearRegisterUserStatusAndError,
    clearRegisteredUserEmail,
    logoutAction,
    logout,
    updateMainMenuLook,
    v3LoginMutation,
    clearV3LoginUserStatusAndError,
    clearForgoPasswordStatusAndError,
    forgotPassword,
    startLegacySession,
    updateMainMenuLookOnMessages,
    setShowCfChallengeOverlay,
  };
}

export default useUsersStore;
