const initialState = {
  selectedMainMenu: null,
  redirectUrl: null,
  moreMenuFirstTimeClicked: false,
  moreMenuExpanded: false,

  //State for search in menu
  searchFilter: null,
  query: null,
  searchResults: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "MENUS/MAIN_MENU_FILTER":
      return {
        ...state,
        selectedMainMenu: action.filter,
      };
    case "MENUS/REDIRECT_URL":
      return {
        ...state,
        redirectUrl: action.path,
      };

    case "TOGGLE_MORE_MENU_CLICKED": {
      // This case is for first time click on any part of MenusMainView component
      return {
        ...state,
        moreMenuFirstTimeClicked: !state.moreMenuFirstTimeClicked && true,
        moreMenuExpanded: !state.moreMenuFirstTimeClicked
          ? true
          : state.moreMenuExpanded,
      };
    }

    case "MENUS/TOGGLE_MORE_MENU_EXPAND":
      // This case will toggle expanded state of MenusMainView component
      return {
        ...state,
        moreMenuExpanded: !state.moreMenuExpanded,
      };

    case "MENUS/RESET_MORE_MENU_EXPAND":
      // This case will reset expanded state of MenusMainView component,
      // helpful specially at component MenusMainView unmount event
      return {
        ...state,
        moreMenuExpanded: false,
      };

    case "MENUS/SET_CURRENT_FILTER":
      return {
        ...state,
        searchFilter: action.currentFilter,
      };
    case "MENUS/SET_SEARCH_RESULTS":
      return {
        ...state,
        searchResults: action.searchResults,
        query: action.query,
      };
    default:
      return state;
  }
}
