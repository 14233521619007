import React from "react";
import { connect } from "react-redux";
import TwilioConfig from "../TwilioConfig";
import { contactsName, formatDuration, formatPhoneNumber } from "../helpers";
import { fetchContactData } from "../actions/contacts";

//missing icons
// import { ReactComponent as UnminimizeSvg } from "../assets/img/icons/unminimize_icon.svg";
import { ReactComponent as UnminimizeSvg } from "../assets/img/icons-new/general/dot/dot-red.svg";

function mapStateToProps(store, ownProps) {
  const contactId = store.twilio.callContactId;
  let contactData = null;
  let contactStatus = null;

  if (contactId) {
    let contact =
      typeof store.contacts.data[contactId] === "undefined"
        ? null
        : store.contacts.data[contactId];
    if (contact) {
      contactData = [];
      for (let i = 0; i < contact.contacts_ids.length; i++) {
        if (
          typeof store.contacts.data[contact.contacts_ids[i]] !== "undefined"
        ) {
          contactData.push(store.contacts.data[contact.contacts_ids[i]]);
        }
      }
    }
    contactStatus =
      typeof store.contacts.dataStatus[contactId] === "undefined"
        ? null
        : store.contacts.dataStatus[contactId];
  }

  return {
    companyId: store.companies.currentCompany.id,
    contactId: contactId,
    contactData: contactData,
    contactStatus: contactStatus,
    callStatus: store.twilio.callStatus,
    callStartedAt: store.twilio.callStartedAt,
    callMinutePrice: store.twilio.callMinutePrice,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchContactData: (companyId, contactId) =>
      dispatch(fetchContactData(companyId, contactId)),
  };
}

class CallMinimizeBar extends React.Component {
  constructor(props) {
    super(props);
    this.timeInterval = null;
    this.updateData = this.updateData.bind(this);
    this.decline = this.decline.bind(this);
    this.state = {
      time: 0,
      spent: 0,
    };
  }

  componentDidMount() {
    if (this.props.contactStatus === null) {
      this.props.fetchContactData(this.props.companyId, this.props.contactId);
    }
    this.timeInterval = setInterval(this.updateData, 1000);
    document.body.classList.add("has-minimize-bar");
  }

  componentWillUnmount() {
    clearInterval(this.timeInterval);
    document.body.classList.remove("has-minimize-bar");
  }

  updateData() {
    const time = this.props.callStartedAt
      ? Math.ceil((new Date().getTime() - this.props.callStartedAt) / 1000)
      : 0;
    const spent = this.props.callStartedAt
      ? Math.ceil(time / 60) * this.props.callMinutePrice
      : 0;
    this.setState({
      time: time,
      spent: spent,
    });
  }

  decline(e) {
    e && e.preventDefault();
    e && e.stopPropagation();
    TwilioConfig.disconnect();
  }

  render() {
    const name = this.props.contactData
      ? contactsName(this.props.contactData, false, 4)
      : "";
    const phonecode = this.props.contactData[0].country
      ? "" + this.props.contactData[0].country.phonecode
      : "";
    const number = this.props.contactData
      ? formatPhoneNumber(
          "" + phonecode + this.props.contactData[0].phone_number
        )
      : "";
    return (
      <div className="call-minimize-bar top-banner-wrapper">
        <div className="detail">
          <span className="calling-details">
            Calling to <strong>{name}</strong> {number}
          </span>
          <span className="duratiion-details">
            {formatDuration(this.state.time)}
          </span>
          <span>You spent {this.state.spent} credits</span>
        </div>
        <div className="right-action">
          <a href={"#modal-call-contact/" + this.props.contactId}>
            <UnminimizeSvg width={18.458} />
          </a>
          <a href="#void" className="decline" onClick={this.decline}>
            <i className="material-icons">call_end</i>
          </a>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CallMinimizeBar);
