import { getKeyForSignatureMergeFields } from "../utils/settingsHelpers";

const initialState = {
  numbers: [],
  numbersStatus: null,
  numbersFilter: null,
  numbersFilterValue: null,
  numbersFiltered: null,
  numberFilterStatus: null,

  senderNumber: null,
  useSecondaryNumber: null,

  shortCodeUserId: null,
  shortCodeUserNumber: null,
  shortCodeSignaturePreview: null,

  signatures: [],
  signaturesStatus: "",

  signatureToInsert: { signature: "", isEditable: false },
  signatureMergeFields: {},
  signatureMergeFieldsStatus: "",

  assignedNumbers: [],

  categorizedNumbers: {
    assigned: [],
    unassigned: [],
    secondary: [],
  },
  categorizedNumbersStatus: "",

  // Notification numbers followable
  notificationsNumbersFollowableStatus: "",
  notificationsNumbersFollowable: [],

  shortCodeAsDefaultSenderStatus: "",

  releaseNumberStatus: "",
  removeAsSecondaryStatus: "",

  // sHORT code and Toll free checkout Embed url
  shortCodeTollFreeCheckoutEmbedUrl: "",

  // Short code and toll free release info
  shortCodeOrTollFreeNumberInfoStatus: "",
  tollFreeNumberInfo: {
    schedule_messages_count: 0,
    linked_campaigns: [],
    linked_users: [],
  },
  shortCodeNumberInfo: {
    linked_campaigns: [],
    linked_users: [],
    schedule_messages_count: 0,
    shortcode_keywords: [],
    prorated_refund: {},
  },

  //  Edit number status
  editNumberStatus: "",
  isVoicemailEmailUpdated: false,
  isCallForwardingUpdated: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // Clear data after logout and switch company
    case "USER_LOGOUT":
    case "SET_CURRENT_COMPANY":
      return initialState;

    case "NUMBERS/NUMBERS":
      return {
        ...state,
        numbers: [],
        numbersStatus: "loading",
      };
    case "NUMBERS/NUMBERS.SUCCESS":
      return {
        ...state,
        numbers: action.payload.numbers,
        numbersStatus: "success",
      };
    case "NUMBERS/NUMBERS.ERROR":
      return {
        ...state,
        numbers: [],
        numbersStatus: "error",
      };

    case "NUMBERS/FILTER":
      return {
        ...state,
        numbersFilter: action.numbers,
        numbersFilterValue: action.value,
        numbersFiltered: !action.all,
        numberFilterStatus: "loading",
      };

    case "NUMBERS/FILTER.SUCCESS":
      return {
        ...state,
        numberFilterStatus: "success",
      };

    case "NUMBERS/FILTER.ERROR":
      return {
        ...state,
        numberFilterStatus: "error",
      };

    case "NUMBERS/FILTER.CLEAR":
      return {
        ...state,
        numberFilterStatus: null,
      };

    case "NUMBERS/SENDER":
      let numberData = null;
      if (state.numbers) {
        for (let i = 0; i < state.numbers.length; i++) {
          if (state.numbers[i].number === action.number) {
            numberData = state.numbers[i];
          }
        }
      }
      return {
        ...state,
        senderNumber: numberData,
      };

    case "NUMBERS/SET_USE_SECONDARY_NUMBER":
      return {
        ...state,
        useSecondaryNumber: action.useSecondaryNumber,
      };

    case "NUMBERS/SHORT_CODE_SIGNATURE":
      return {
        ...state,
        shortCodeUserId: action.userId,
        shortCodeUserNumber: action.userNumber,
        shortCodeSignaturePreview: action.signaturePreview,
      };

    case "NUMBERS/FETCH_SIGNATURES": {
      return {
        ...state,
        signaturesStatus: "loading",
      };
    }

    case "NUMBERS/FETCH_SIGNATURES.SUCCESS": {
      return {
        ...state,
        signatures: action.payload.data,
        signaturesStatus: "success",
      };
    }
    case "NUMBERS/FETCH_SIGNATURES.ERROR": {
      return {
        ...state,
        signatures: [],
        signaturesStatus: "error",
      };
    }
    case "NUMBERS/CLEAR_FETCH_SIGNATURES_STATUS": {
      return {
        ...state,
        signaturesStatus: "",
      };
    }

    case "NUMBERS/SET_SIGNATURE_TO_INSERT": {
      return {
        ...state,
        signatureToInsert: action.signatureToInsert,
      };
    }
    case "NUMBERS/FETCH_CATEGORIZED_NUMBERS": {
      return {
        ...state,
        categorizedNumbersStatus: "loading",
      };
    }
    case "NUMBERS/FETCH_CATEGORIZED_NUMBERS.SUCCESS": {
      return {
        ...state,
        categorizedNumbersStatus: "success",
        categorizedNumbers: {
          assigned: action.payload.data.assigned,
          unassigned: action.payload.data.unassigned,
          secondary: action.payload.data.secondary,
        },
      };
    }
    case "NUMBERS/FETCH_CATEGORIZED_NUMBERS.ERROR": {
      return {
        ...state,
        categorizedNumbersStatus: "error",
      };
    }

    case "NUMBERS/FETCH_SIGNATURE_MERGE_FIELDS": {
      return {
        ...state,
        signatureMergeFieldsStatus: "loading",
      };
    }

    case "NUMBERS/FETCH_SIGNATURE_MERGE_FIELDS.SUCCESS": {
      return {
        ...state,
        signatureMergeFields: {
          ...state.signatureMergeFields,
          [getKeyForSignatureMergeFields(
            action.userId,
            action.signatureUserNumber
          )]: action.payload.data,
        },
        signatureMergeFieldsStatus: "success",
      };
    }

    case "NUMBERS/FETCH_SIGNATURE_MERGE_FIELDS.error": {
      return {
        ...state,
        signatureMergeFieldsStatus: "error",
      };
    }

    case "NUMBERS/FETCH_SIGNATURE_MERGE_FIELDS.CLEAR": {
      return {
        ...state,
        signatureMergeFieldsStatus: null,
      };
    }

    case "USER_SETTINGS/FETCH_NOTIFICATION_NUMBERS_FOLLOWABLE": {
      return {
        ...state,
        notificationsNumbersFollowableStatus: "loading",
      };
    }

    case "USER_SETTINGS/FETCH_NOTIFICATION_NUMBERS_FOLLOWABLE.SUCCESS": {
      return {
        ...state,
        notificationsNumbersFollowable: action.payload.data,
        notificationsNumbersFollowableStatus: "success",
      };
    }

    case "USER_SETTINGS/FETCH_NOTIFICATION_NUMBERS_FOLLOWABLE.ERROR": {
      return {
        ...state,
        notificationsNumbersFollowable: [],
        notificationsNumbersFollowableStatus: "error",
      };
    }

    case "USER_SETTINGS/FETCH_NUMBERS_EXTENDED": {
      return {
        ...state,
        fetchNumbersExtendedStatus: "loading",
      };
    }
    case "USER_SETTINGS/FETCH_NUMBERS_EXTENDED.SUCCESS": {
      return {
        ...state,
        numbersExtended: action.payload.numbers,
        fetchNumbersExtendedStatus: "success",
      };
    }
    case "USER_SETTINGS/FETCH_NUMBERS_EXTENDED.ERROR": {
      return {
        ...state,
        numbersExtended: [],
        fetchNumbersExtendedStatus: "error",
      };
    }
    case "USER_SETTINGS/FETCH_DEFAULT_SENDER_SETTINGS": {
      return {
        ...state,
        defaultSenderSettingsStatus: "loading",
      };
    }
    case "USER_SETTINGS/FETCH_DEFAULT_SENDER_SETTINGS.SUCCESS": {
      return {
        ...state,
        defaultSenderSettings: action.payload.data,
        defaultSenderSettingsStatus: "success",
      };
    }
    case "USER_SETTINGS/FETCH_DEFAULT_SENDER_SETTINGS.ERROR": {
      return {
        ...state,
        defaultSenderSettings: null,
        defaultSenderSettingsStatus: "error",
      };
    }
    case "USER_SETTINGS/FETCH_CALLER_ID_STATUS": {
      return {
        ...state,
        fetchCallerIdStatus: "loading",
      };
    }
    case "USER_SETTINGS/FETCH_CALLER_ID_STATUS.SUCCESS": {
      return {
        ...state,
        callerIdStatus: action.payload.data,
        fetchCallerIdStatus: "success",
      };
    }
    case "USER_SETTINGS/FETCH_CALLER_ID_STATUS.ERROR": {
      return {
        ...state,
        callerIdStatus: null,
        fetchCallerIdStatus: "error",
      };
    }

    case "USER_SETTINGS/FETCH_CALLER_ID_STATUS.CLEAR": {
      return {
        ...state,
        callerIdStatus: null,
        fetchCallerIdStatus: "",
      };
    }

    case "USER_SETTINGS/FETCH_RELEASED_NUMBERS": {
      return {
        ...state,
        fetchReleasedNumbersStatus: "loading",
      };
    }
    case "USER_SETTINGS/FETCH_RELEASED_NUMBERS.SUCCESS": {
      return {
        ...state,
        releasedNumbers: action.payload.data,
        fetchReleasedNumbersStatus: "success",
      };
    }
    case "USER_SETTINGS/FETCH_RELEASED_NUMBERS.ERROR": {
      return {
        ...state,
        releasedNumbers: [],
        fetchReleasedNumbersStatus: "error",
      };
    }
    case "USER_SETTINGS/VERIFY_CALLER_ID": {
      return {
        ...state,
        verifyCallerIdStatus: "loading",
      };
    }
    case "USER_SETTINGS/VERIFY_CALLER_ID.SUCCESS": {
      return {
        ...state,
        verificationCode: action.payload.data,
        verifyCallerIdStatus: "success",
      };
    }
    case "USER_SETTINGS/VERIFY_CALLER_ID.ERROR": {
      return {
        ...state,
        verificationCode: null,
        verifyCallerIdStatus: "error",
      };
    }
    case "NUMBERS/SET_SHORT_CODE_AS_DEFAULT_SENDER": {
      return {
        ...state,
        shortCodeAsDefaultSenderStatus: "loading",
      };
    }

    case "NUMBERS/SET_SHORT_CODE_AS_DEFAULT_SENDER.SUCCESS": {
      return {
        ...state,
        defaultSenderSettings: {
          ...state.defaultSenderSettings,
          ...action.payload.data,
        },
        shortCodeAsDefaultSenderStatus: "success",
      };
    }

    case "NUMBERS/SET_SHORT_CODE_AS_DEFAULT_SENDER.ERROR": {
      return {
        ...state,
        shortCodeAsDefaultSenderStatus: "error",
      };
    }

    case "NUMBERS/SET_SHORT_CODE_AS_DEFAULT_SENDER.CLEAR": {
      return {
        ...state,
        shortCodeAsDefaultSenderStatus: "",
      };
    }

    case "ADMIN_SETTINGS/RELEASE_NUMBER": {
      return {
        ...state,
        releaseNumberStatus: "loading",
      };
    }
    case "ADMIN_SETTINGS/RELEASE_NUMBER.SUCCESS": {
      return {
        ...state,
        numbersExtended: state.numbersExtended.filter(
          (numberAux) => numberAux.number !== action.number
        ),
        releaseNumberStatus: "success",
      };
    }
    case "ADMIN_SETTINGS/RELEASE_NUMBER.ERROR": {
      return {
        ...state,
        releaseNumberStatus: "error",
      };
    }

    case "ADMIN_SETTINGS/RELEASE_NUMBER.CLEAR": {
      return {
        ...state,
        releaseNumberStatus: "",
      };
    }

    case "ADMIN_SETTINGS/ASSIGN_COMPANY_NUMBER": {
      return {
        ...state,
        assignCompanyNumberStatus: "loading",
      };
    }
    case "ADMIN_SETTINGS/ASSIGN_COMPANY_NUMBER.SUCCESS": {
      return {
        ...state,
        numbersExtended: action.payload.numbers,
        assignCompanyNumberStatus: "success",
      };
    }
    case "ADMIN_SETTINGS/ASSIGN_COMPANY_NUMBER.ERROR": {
      return {
        ...state,
        assignCompanyNumberStatus: "error",
      };
    }
    case "ADMIN_SETTINGS/CLEAR_ASSIGN_COMPANY_NUMBER": {
      return {
        ...state,
        assignCompanyNumberStatus: null,
      };
    }
    case "NUMBERS/EDIT_NUMBER": {
      return {
        ...state,
        editNumberStatus: "loading",
      };
    }
    case "NUMBERS/EDIT_NUMBER.SUCCESS": {
      const newNumbersExtended = [...state.numbersExtended];
      newNumbersExtended[action.index] = {
        ...newNumbersExtended[action.index],
        ...action.payload.data,
      };

      return {
        ...state,
        editNumberStatus: "success",
        numbersExtended: newNumbersExtended,
        isVoicemailEmailUpdated:
          action?.params?.hasOwnProperty("voicemail_email"),
        isCallForwardingUpdated:
          action?.params?.hasOwnProperty("call_forwarding_number") ||
          action?.params?.hasOwnProperty("call_forwarding_country_id"),
      };
    }
    case "NUMBERS/EDIT_NUMBER.ERROR": {
      return {
        ...state,
        editNumberStatus: "error",
        isVoicemailEmailUpdated: false,
        isCallForwardingUpdated: false,
      };
    }
    case "NUMBERS/CLEAR_EDIT_NUMBER_STATUS": {
      return {
        ...state,
        editNumberStatus: "",
        isVoicemailEmailUpdated: false,
        isCallForwardingUpdated: false,
      };
    }
    case "NUMBERS/REMOVE_AS_SECONDARY_NUMBER": {
      return {
        ...state,
        removeAsSecondaryStatus: "loading",
      };
    }
    case "NUMBERS/REMOVE_AS_SECONDARY_NUMBER.SUCCESS": {
      return {
        ...state,
        removeAsSecondaryStatus: "success",
      };
    }
    case "NUMBERS/REMOVE_AS_SECONDARY_NUMBER.ERROR": {
      return {
        ...state,
        removeAsSecondaryStatus: "error",
      };
    }
    case "NUMBERS/REMOVE_AS_SECONDARY_NUMBER.CLEAR": {
      return {
        ...state,
        removeAsSecondaryStatus: "",
      };
    }

    case "NUMBERS/SET_SHORTCODE_TOLL_FREE_CHECKOUT_EMBED_URL": {
      return {
        ...state,
        shortCodeTollFreeCheckoutEmbedUrl: action.embedURL,
      };
    }

    case "NUMBERS/FETCH_SHORT_CODE_OR_TOLL_FREE_NUMBER_INFO": {
      return {
        ...state,
        shortCodeOrTollFreeNumberInfoStatus: "loading",
      };
    }

    case "NUMBERS/FETCH_SHORT_CODE_OR_TOLL_FREE_NUMBER_INFO.SUCCESS": {
      const { params } = action;
      return {
        ...state,
        shortCodeOrTollFreeNumberInfoStatus: "success",
        tollFreeNumberInfo: Boolean(params?.is_toll_free)
          ? { ...state.tollFreeNumberInfo, ...action.payload }
          : state.tollFreeNumberInfo,
        shortCodeNumberInfo: Boolean(params?.is_short_code)
          ? { ...state.shortCodeNumberInfo, ...action.payload }
          : state.shortCodeNumberInfo,
      };
    }

    case "NUMBERS/FETCH_SHORT_CODE_OR_TOLL_FREE_NUMBER_INFO.ERROR": {
      return {
        ...state,
        shortCodeOrTollFreeNumberInfoStatus: "error",
      };
    }

    case "NUMBERS/FETCH_SHORT_CODE_OR_TOLL_FREE_NUMBER_INFO.CLEAR": {
      return {
        ...state,
        shortCodeOrTollFreeNumberInfoStatus: "",
      };
    }

    default:
      return state;
  }
}
