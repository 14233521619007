import React from "react";
import PropTypes from "prop-types";
import { timeAgo } from "../../../helpers";

const ThreadItemTitle = (props) => {
  const {
    name,
    unread_count,
    purpose,
    boldText,
    checkBoxActive,
    createdOrSendOnUtc,
    scheduleMessage,
    userTimeZoneOffset,
    showCheckBox,
    withThreadDate,
  } = props;

  let unread = unread_count !== undefined && unread_count > 0;

  return (
    <h3 className="threadItemHeading">
      <span
        className={`name thread-item-title-text ${
          unread && purpose !== "call-modal" && "unread"
        } ${boldText ? "font-weight-500" : ""}
        `}
      >
        {name}
      </span>
      {withThreadDate &&
        !showCheckBox &&
        !checkBoxActive &&
        createdOrSendOnUtc &&
        createdOrSendOnUtc !== "0000-00-00 00:00:00" && (
          <span className="date" style={{ paddingLeft: "6px" }}>
            {scheduleMessage && <i className={"fa fa-clock-o"} />}
            {timeAgo(createdOrSendOnUtc, userTimeZoneOffset)}
          </span>
        )}
    </h3>
  );
};

ThreadItemTitle.propTypes = {
  name: PropTypes.string.isRequired,
  purpose: PropTypes.string,
  boldText: PropTypes.bool,
  checkBoxActive: PropTypes.bool,
  createdOrSendOnUtc: PropTypes.string,
  scheduleMessage: PropTypes.bool,
  userTimeZoneOffset: PropTypes.number,
  showCheckBox: PropTypes.bool,
  withThreadDate: PropTypes.bool,
};

ThreadItemTitle.defaultProps = {
  name: "",
  purpose: "",
  boldText: false,
  checkBoxActive: false,
  createdOrSendOnUtc: "",
  scheduleMessage: false,
  userTimeZoneOffset: 0,
  showCheckBox: false,
  withThreadDate: true,
};

export default ThreadItemTitle;
