import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Responsive, WidthProvider } from "react-grid-layout";
import Scrollbars from "react-custom-scrollbars";
import DashboardCard from "./DashboardCard";
import MessagesCallsWidget from "./MessagesCallsWidget";
import CreditsUsedWidget from "./CreditsUsedWidget";
import GiftStaticWidget from "./GiftStaticWidget";
import { useMemo } from "react";
import { useDragAndDrop } from "../../../utils/hooks/useDragAndDrop";
import { getResponsiveLayouts } from "../../../utils/dashboardHelpers";
import BasicsWidget from "./BasicsWidget";
import MyOrganizationSummaryWidget from "./MyOrganizationSummaryWidget";
import DashboardSetup from "../DashboardSetup/DashboardSetup";
import NotificationBanner from "../../Menus/shared/NotificationBanner";
import { connect } from "react-redux";
import { postDashboard } from "../../../actions/dashboard";
import ReactPlayer from "react-player";
import {
  getUserFullname,
  hasNumberAssigned,
  isReactNativeApp,
} from "../../../helpers";
import { useIsAdminOrOwner } from "../../../utils/hooks/useIsAdminOrOwner";
import { history } from "../../../store";
import { ASSIGNED_NUMBER_ALERT_LINK } from "../../../utils/constants";
import RemoveWidget from "../../modals/Dashboard/RemoveWidget";
import SitemapWidget from "./SitemapWidget";
import AddNewWidget from "./AddNewWidget";
import useBreakpoint from "../../../utils/hooks/useBreakpoints";
import { isMdOrBelowBreakpoint } from "../../../utils/breakpoints";
import CreditsWidget from "./CreditsWidget";
import TimezoneWidget from "./TimezoneWidget";
import CreditsBreakdownWidget from "./CreditsBreakdownWidget";
import { useIsOwner } from "../../../utils/hooks/useIsOwner";
import { fetchCompanies } from "../../../actions/companies";
import { hasSrcOneOrTwoNumbers } from "../../../utils/numbersHelpers";

function mapStateToProps(store, ownProps) {
  const currentCompany = store.companies.currentCompany;
  const isAdmin =
    currentCompany &&
    (currentCompany.is_main_owner ||
      currentCompany.is_admin_plus ||
      currentCompany.is_admin_user);

  return {
    credits: store.users.credits,
    timezone: store.users.loggedUser.timezone,
    currentCompany: store.companies.currentCompany,
    numbers: store.numbers.numbers,
    loggedUser: store.users.loggedUser,
    users: store.companies.users,
    userPermissions: store.users.permissions,
    creditsSummary: store.users.creditsSummary,
    skipSetupProfile: store.companies.skipSetupProfile,
    showCreditSummary:
      isAdmin && store.users.creditsSummary?.is_new_billing_flow_applied,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    postDashboard: (body) => dispatch(postDashboard(body)),
    fetchCompanies: (isResetCurrentCompany) =>
      dispatch(fetchCompanies(isResetCurrentCompany)),
  };
}

const ResponsiveGridLayout = WidthProvider(Responsive);

const ROW_HEIGHT = 105;
const GRID_MARGINS = [30, 30];
const GRID_MARGINS_FOR_MOBILE = [20, 30];

const DashboardBody = (props) => {
  const {
    initialLayout,

    // Redux props
    credits,
    timezone,
    currentCompany,
    numbers,
    loggedUser,
    users,
    userPermissions,
    creditsSummary,
    showCreditSummary,
    skipSetupProfile,

    // Redux func
    postDashboard,
    fetchCompanies,
  } = props;
  const breakpoint = useBreakpoint();
  const isOwner = useIsOwner();

  const [draggingItem, setDraggingItem] = useState({});
  const [notificationBannerIsOpen, setNotificationBannerIsOpen] =
    useState(true);
  const [removeWidgetId, setRemoveWidgetId] = useState("");

  const onDragStartCallback = useCallback((_, oldItem) => {
    setDraggingItem(oldItem);
  }, []);

  const onDragEndCallback = useCallback(
    (layout) => {
      postDashboard({
        dashboard_data: JSON.stringify(layout),
      });
      setDraggingItem({});
    },
    [postDashboard]
  );

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  const {
    layout,
    isDragging,
    onToggleDrag,
    onLayoutChange,
    onDragStart,
    onDragStop,
    onSizeChange,
  } = useDragAndDrop(
    initialLayout ?? [],
    ROW_HEIGHT,
    onDragStartCallback,
    onDragEndCallback
  );

  const commonDragProps = useMemo(
    () => ({
      onBeforeDrag: onToggleDrag(false),
      onMouseLeaveDrag: onToggleDrag(true),
      isDragging: isDragging,
    }),
    [isDragging, onToggleDrag]
  );

  const getDraggingStyle = useCallback(
    (item) => {
      return draggingItem.i === item.i
        ? { zIndex: 1000, cursor: "grabbing" }
        : {};
    },
    [draggingItem]
  );

  const onClickedTrashIcon = useCallback(
    (id) => () => {
      setRemoveWidgetId(id);
    },
    []
  );
  const renderedLayout = useMemo(() => {
    if (layout && Array.isArray(layout) && Boolean(layout?.length)) {
      return layout.map((item, index) => {
        if (item.i === "credit-balance" && !showCreditSummary) {
          return (
            <div key={item.i} data-grid={item} style={getDraggingStyle(item)}>
              <CreditsWidget
                credits={credits}
                isActive={draggingItem.i === item.i}
                onClickTrash={onClickedTrashIcon("credit-balance")}
                userPermissions={userPermissions}
                index={index}
                {...commonDragProps}
              />
            </div>
          );
        }
        if (item.i === "credit-summary" && showCreditSummary) {
          return (
            <div key={item.i} data-grid={item} style={getDraggingStyle(item)}>
              <CreditsBreakdownWidget
                creditsSummary={creditsSummary}
                isActive={draggingItem.i === item.i}
                onClickTrash={onClickedTrashIcon("credit-summary")}
                userPermissions={userPermissions}
                index={index}
                {...commonDragProps}
              />
            </div>
          );
        }
        if (item.i === "time-zone") {
          return (
            <div key={item.i} data-grid={item} style={getDraggingStyle(item)}>
              <TimezoneWidget
                timezone={timezone}
                isActive={draggingItem.i === item.i}
                onClickTrash={onClickedTrashIcon("time-zone")}
                index={index}
                {...commonDragProps}
              />
            </div>
          );
        }
        if (item.i === "message-calls") {
          return (
            <div key={item.i} data-grid={item} style={getDraggingStyle(item)}>
              <MessagesCallsWidget
                isActive={draggingItem.i === item.i}
                onClickTrash={onClickedTrashIcon("message-calls")}
                {...commonDragProps}
                index={index}
              />
            </div>
          );
        }
        if (item.i === "credits-used") {
          return (
            <div key={item.i} data-grid={item} style={getDraggingStyle(item)}>
              <CreditsUsedWidget
                isActive={draggingItem.i === item.i}
                onClickTrash={onClickedTrashIcon("credits-used")}
                {...commonDragProps}
                index={index}
              />
            </div>
          );
        }
        if (item.i === "get-started" && !isReactNativeApp()) {
          return (
            <div key={item.i} data-grid={item} sty le={getDraggingStyle(item)}>
              <DashboardCard
                headerContent={
                  <span>Get Started with our quick Tutorial Video!</span>
                }
                isActive={draggingItem.i === item.i}
                onClickTrash={onClickedTrashIcon("get-started")}
                externalLink={
                  "https://help.pastorsline.com/en/articles/5837047-v3-take-a-tour-of-pastorsline"
                }
                cardLinkText={"View the Get Started Guides"}
                {...commonDragProps}
              >
                <ReactPlayer
                  width="100%"
                  height="100%"
                  controls
                  url="https://vimeo.com/767098465"
                />
              </DashboardCard>
            </div>
          );
        }
        if (item.i === "get-started-learn" && !isReactNativeApp()) {
          return (
            <div key={item.i} data-grid={item} style={getDraggingStyle(item)}>
              <BasicsWidget
                onClickTrash={onClickedTrashIcon("get-started-learn")}
                {...commonDragProps}
                isActive={draggingItem.i === item.i}
                index={index}
                onSizeChange={onSizeChange}
              />
            </div>
          );
        }
        if (item.i === "my-organization") {
          return (
            <div key={item.i} data-grid={item} style={getDraggingStyle(item)}>
              <MyOrganizationSummaryWidget
                {...commonDragProps}
                onClickTrash={onClickedTrashIcon("my-organization")}
                isActive={draggingItem.i === item.i}
                index={index}
                userCount={currentCompany.user_count}
                contactsCount={currentCompany.contacts_count}
                ownerName={currentCompany.owner_name}
                userPermissions={userPermissions}
                companyName={currentCompany.company_name || "N/A"}
              />
            </div>
          );
        }

        if (item.i === "gift-claim" && !isReactNativeApp()) {
          return (
            <div key={item.i} data-grid={item} style={getDraggingStyle(item)}>
              <GiftStaticWidget />
            </div>
          );
        }

        if (item.i === "sitemap" && !isReactNativeApp()) {
          return (
            <div key={item.i} data-grid={item} style={getDraggingStyle(item)}>
              <SitemapWidget
                {...commonDragProps}
                isActive={draggingItem.i === item.i}
                index={index}
                onClickTrash={onClickedTrashIcon("sitemap")}
              />
            </div>
          );
        }
        return <div />;
      });
    }
    return null;
  }, [
    layout,
    getDraggingStyle,
    credits,
    draggingItem.i,
    onClickedTrashIcon,
    userPermissions,
    commonDragProps,
    timezone,
    onSizeChange,
    currentCompany,
    creditsSummary,
    showCreditSummary,
  ]);

  const withAssignedNumber = useMemo(
    () => hasNumberAssigned(numbers),
    [numbers]
  );
  const isSrcOneOrTwoNumberAvailable = useMemo(
    () => hasSrcOneOrTwoNumbers(numbers),
    [numbers]
  );

  const isAdminOrOwner = useIsAdminOrOwner(currentCompany);
  const userName = getUserFullname(loggedUser);

  const renderBannerBtnText = useMemo(() => {
    if (isAdminOrOwner) {
      if (isSrcOneOrTwoNumberAvailable) {
        return "Go To Admin Settings";
      }
      return "Set Up a Number";
    }
    return "";
  }, [isAdminOrOwner, isSrcOneOrTwoNumberAvailable]);

  const onClickedBannerBtn = useCallback(() => {
    if (isAdminOrOwner) {
      if (isSrcOneOrTwoNumberAvailable) {
        history.push(
          "/hub/menus/filter/settings/admin-settings/submenu/admin-numbers"
        );
      } else {
        history.push(ASSIGNED_NUMBER_ALERT_LINK);
      }
    }
  }, [isAdminOrOwner, isSrcOneOrTwoNumberAvailable]);

  return (
    <>
      <Scrollbars autoHeight autoHeightMax={"calc(100vh - 59px)"} autoHide>
        <div className="dashboard-wrapper">
          {notificationBannerIsOpen && !withAssignedNumber && (
            <NotificationBanner
              text={`Hey ${userName}, please set up your number so that you can start using Pastorsline fully!`}
              buttonText={renderBannerBtnText}
              color="yellow"
              onClickButton={onClickedBannerBtn}
              onClickClose={() => setNotificationBannerIsOpen(false)}
            />
          )}
          <div className="dashboard-title">
            Welcome, {userName}!
            <Link
              className="view-profile"
              to="/hub/menus/filter/settings/user-settings/submenu/user-profile"
            >
              View profile
            </Link>
          </div>
          {isOwner &&
            !skipSetupProfile &&
            (!withAssignedNumber ||
              users?.length === 1 ||
              currentCompany.has_sent_test_sms === 0) && <DashboardSetup />}
          <AddNewWidget />
          <ResponsiveGridLayout
            className="layout"
            rowHeight={ROW_HEIGHT}
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 4, md: 3, sm: 2, xs: 1, xxs: 1 }}
            margin={
              isMdOrBelowBreakpoint(breakpoint)
                ? GRID_MARGINS_FOR_MOBILE
                : GRID_MARGINS
            }
            layouts={
              Array.isArray(layout)
                ? getResponsiveLayouts(layout.map((item) => ({ ...item })))
                : {}
            }
            onDragStart={onDragStart}
            onDragStop={onDragStop}
            onLayoutChange={onLayoutChange}
          >
            {renderedLayout}
          </ResponsiveGridLayout>
        </div>
      </Scrollbars>
      {removeWidgetId && (
        <RemoveWidget
          show
          widgetId={removeWidgetId}
          closeModal={() => setRemoveWidgetId("")}
        />
      )}
    </>
  );
};

DashboardBody.propTypes = {
  initialLayout: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardBody);
