export function setMainMenuFilter(filter) {
  return function (dispatch) {
    dispatch({
      type: "MENUS/MAIN_MENU_FILTER",
      filter,
    });
  };
}

export function setRedirectUrl(path) {
  return function (dispatch) {
    dispatch({
      type: "MENUS/REDIRECT_URL",
      path,
    });
  };
}

export function setSearchFilter(currentFilter) {
  return function (dispatch) {
    dispatch({
      type: "MENUS/SET_CURRENT_FILTER",
      currentFilter,
    });
  };
}

export function setSearchResults(searchResults, query) {
  return function (dispatch) {
    dispatch({
      type: "MENUS/SET_SEARCH_RESULTS",
      searchResults,
      query,
    });
  };
}

export function resetMoreMenuExpand() {
  return function (dispatch) {
    dispatch({
      type: "MENUS/RESET_MORE_MENU_EXPAND",
    });
  };
}

export function toggleExpandedView() {
  return function (dispatch) {
    dispatch({
      type: "MENUS/TOGGLE_MORE_MENU_EXPAND",
    });
  };
}
