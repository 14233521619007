import { useCallback, useRef } from "react";
import { PopoverBody, UncontrolledPopover } from "reactstrap";
import { Link } from "react-router-dom";
import IntegrationIcon from "../../Menus/AdminSettingsView/Integrations/IntegrationIcon";
import { GROUPS_FILTER_ALL_INTEGRATIONS } from "../../../utils/constants";

const SideBarIntegrationsPopover = () => {
  const popRef = useRef(null);

  const closePopoverOnClick = useCallback(() => {
    popRef.current.state.isOpen = false;
  }, [popRef]);

  return (
    <UncontrolledPopover
      ref={popRef}
      trigger="hover"
      placement="left"
      target="sidebar-integrations-popover"
      className="side-menu-popover sidebar-integrations-popover"
      delay={{
        show: 0,
        hide: 1000,
      }}
      rootClose={true}
    >
      <PopoverBody>
        <Link
          className="d-flex align-items-center justify-content-between"
          to="/hub/groups/viewType/view-all#modal-new-group"
        >
          Import New Group (Sync)
          <div className="d-flex align-items-center ml-2">
            {integrations.map((type) => (
              <IntegrationIcon type={type} size="17px" className="ml-2" />
            ))}
          </div>
        </Link>
        <Link to="/hub/menus/filter/settings/admin-settings/submenu/admin-integrations/active-apps">
          Manage My Integrations
        </Link>
        <Link
          to={`/hub/groups/filter/${GROUPS_FILTER_ALL_INTEGRATIONS}`}
          onClick={closePopoverOnClick}
        >
          View My Integrated Groups
        </Link>
      </PopoverBody>
    </UncontrolledPopover>
  );
};

const integrations = ["breeze", "planning-center", "ccb"];

export default SideBarIntegrationsPopover;
